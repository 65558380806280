import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import box_check from "../assets/images/box_check.png";
import box_nocheck from "../assets/images/box_nocheck.png";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";

interface DecisionTreeProps {
  text: string;
  action: () => void;
  selected: boolean;
}

export function DecisionTreeOptionMultiple(props: DecisionTreeProps) {
  const selected = props.selected;

  return (
    <View style={{ width: "100%", marginBottom: 20 }}>
      <TouchableWithoutFeedback
        style={{ width: "100%" }}
        onPress={props.action}
      >
        <View
          style={[
            styles.container,
            {
              borderColor: selected ? Colors.gold : Colors.white,
              backgroundColor: selected ? "#FFF8DA" : Colors.white,
            },
          ]}
        >
          <Text style={styles.content_text}>{props.text}</Text>
          <Image
            source={selected ? box_check : box_nocheck}
            style={{
              width: 20,
              height: 20,
              resizeMode: "contain",
            }}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    borderWidth: 1,
    padding: 20,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.12,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ...Platform.select({ web: { cursor: "pointer" } }),
  },
  content_text: {
    color: "#000",
    opacity: 0.5,
    fontSize: Sizes.decisionText,
    lineHeight: Sizes.decisionTextLineHeight,
    fontWeight: "700",
    width: "90%",
  },
});
