import { CommonActions, useNavigation } from "@react-navigation/native";
import AnimatedGradient from "../Components/AnimatedLinearGradient/index";
import { useEffect, useState } from "react";
import {
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { ActivityIndicator, DefaultTheme } from "react-native-paper";
import logo_white_background from "../assets/images/logo_white_background.png";
import present from "../assets/images/present.png";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";
import { showErrorMessage } from "../Handlers/ErrorHandler/ErrorHandler";
import NavigationHandler from "../Handlers/NavigationHandler";
import { PaymentHandler } from "../Handlers/PaymentHandler";
import { navigationRef } from "../Handlers/RootNavigation";
import UserHandler from "../Handlers/UserHandler";
import { PromocodeModal } from "./PromocodeModal";

const advanteges = [
  "Peer Reviewed Behandlungsmethoden in Sekunden",
  "Durch Shortcuts noch mehr Zeit sparen",
  "Individuelle Notizen hinzufügen, um alles an einem Ort zu speichern",
  "Ob auf dem Smartphone, Tablet oder Computer - MEDICEO® ist überall abrufbar",
  "Treten Sie mit Ärzten in der Kommentarsektion in Kontakt und teilen Sie Ihre Erfahrungen",
  "Verpassen Sie über die News Section keine fachbereichsspezifischen Nachrichten",
];

export function PaymentScreen() {
  const [modalVisible, setModalVisible] = useState(false);
  const navigation = useNavigation();
  const [hasPromo, setHasPromo] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const getPromo = async () => {
    const response = await fetch("https://server.medi.ceo/has_pw");
    let data = await response.json();

    if (data == true) {
      setHasPromo(true);
    } else {
      setHasPromo(false);
    }
  };

  useEffect(() => {
    getPromo();
    AnalyticsHandler.getInstance().logScreenEvent(
      "Payment Screen",
      "opened",
      ""
    );
    if (Platform.OS === "web") {
      navigation.navigate("PaymentScreenWeb");
    }
    PaymentHandler.getInstance();
  }, []);

  const isStudent =
    UserHandler.getInstance().getCurrentUser()!.role === "student"; // TODO CHANGE

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        paddingTop: 20,
        backgroundColor: "#fff",
        opacity: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      {modalVisible ? (
        <View
          style={{
            position: "absolute",
            width: "150%",
            height: "150%",
            backgroundColor: "#000",
            opacity: 0.75,
            zIndex: 10,
          }}
        />
      ) : null}
      <PromocodeModal
        isVisible={modalVisible}
        setIsVisible={setModalVisible}
        successAction={() => {
          const resetAction = CommonActions.reset({
            index: 1,
            routes: [{ name: "SelectMedicalCategoryScreen", params: {} }],
          });
          NavigationHandler.getInstance().firstRoute = false;
          navigationRef.dispatch(resetAction);
        }}
      />

      <View
        style={{
          flex: 1,
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        {/* <Header
           isProfile={false}
           isDark={false}
            issuer={this}/>*/}
        <View
          style={{
            flex: 1,
            width: "90%",
            marginTop: 0,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <View
            style={{ width: "100%", alignItems: "center", marginBottom: 24 }}
          >
            <Image
              source={logo_white_background}
              style={{
                width: "90%",
                height: 120,
                zIndex: 5,
                resizeMode: "contain",
              }}
            />
            <View
              style={{
                height: 2,
                width: "120%",
                backgroundColor: Colors.gold,
                position: "absolute",
                top: 60,
              }}
            />
          </View>
          {/* <View style={{flexDirection:"row", alignItems: "center", justifyContent: "center", marginTop: 20, marginBottom: 25}}> 
                <Image
                source={check}
                style={{width: 24, height: 24, marginRight: 12}}
                />
                <Text style={styles.welcomeText}>DAS BEKOMMEN SIE:</Text>
             </View> */}
          <View
            style={{
              width: "100%",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: "space-between",
                paddingBottom: 8,
                maxHeight: 400,
              }}
            >
              {advanteges.map((a) => {
                return (
                  <View
                    key={Math.random()}
                    style={{
                      flexDirection: "row",
                      width: "90%",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <View
                      style={{
                        width: 20,
                        height: 20,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 10,
                        backgroundColor: Colors.gold,
                        marginRight: 12,
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 12,

                          fontWeight: "800",
                        }}
                      >
                        {advanteges.indexOf(a) + 1}
                      </Text>
                    </View>
                    <Text
                      style={{
                        color: Colors.black_opacity,
                        fontSize: 12,
                        ...Platform.select({ web: { fontSize: 14 } }),
                        fontWeight: "700",
                      }}
                    >
                      {a}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
        <View
          style={{
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
          }}
        >
          <View
            style={{
              justifyContent: "center",
              width: "85%",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <FullPlanButton
              backgroundColor={Colors.black}
              foregroundColor={Colors.gold}
              text="PREMIUM MODELL"
              action={async () => {
                navigationRef.navigate("FullPlanPaymentScreen");
              }}
              borderColor={Colors.gold}
            />
            {!isStudent ? (
              <CustomPlanButton
                backgroundColor="#fff"
                foregroundColor={Colors.gold}
                text="CUSTOM MODELL"
                action={async () => {
                  navigationRef.navigate("CustomPlanPaymentScreen");
                }}
                borderColor={Colors.gold}
              />
            ) : null}
          </View>
          <View
            style={{
              width: "85%",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            {!hasPromo ? null : (
              <TouchableOpacity
                style={{ width: "100%", maxWidth: Sizes.containerWidth }}
                onPress={() => setModalVisible(true)}
              >
                <View style={styles.container}>
                  <View
                    style={{
                      marginLeft: 20,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Image
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 8,
                        resizeMode: "contain",
                        opacity: 0.5,
                      }}
                      source={present}
                    />
                    <Text style={styles.box_title}>Promocode einlösen</Text>
                  </View>
                </View>
              </TouchableOpacity>
            )}
          </View>
          {isWaiting ? (
            <View
              style={{
                height: 30,
                marginTop: 8,
                marginBottom: 8,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator
                theme={{
                  colors: {
                    ...DefaultTheme.colors,
                    primary: Colors.gold,
                  },
                }}
                size="small"
              />
            </View>
          ) : (
            <TouchableOpacity
              onPress={() => {
                setIsWaiting(true);
                PaymentHandler.getInstance().restorePurchases(function finished(
                  success
                ) {
                  if (success) {
                    navigationRef.navigate("SelectMedicalCategoryScreen");
                  } else {
                    setIsWaiting(false);
                    showErrorMessage({
                      title: "Keine Käufe gefunden",
                      text: "Wir konnten keine Käufe wiederherstellen. Falls ein Fehler vorliegt kontaktieren Sie bitte support@medi.ceo.",
                    });
                  }
                });
              }}
            >
              <Text
                style={{
                  textDecorationLine: "underline",
                  fontSize: 12,
                  color: Colors.black_opacity,
                  opacity: 0.75,
                  height: 30,
                  marginVertical: 8,
                }}
              >
                Einkäufe wiederherstellen
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </SafeAreaView>
  );
}

interface ButtonProps {
  text: string;
  backgroundColor: string;
  foregroundColor: string;
  borderColor: string;
  action: () => void;
  enabled?: boolean;
}

function FullPlanButton(props: ButtonProps) {
  return (
    <TouchableOpacity
      style={[
        styles.loginScreenButton,
        {
          backgroundColor: "#111",
          width: "100%",
          height: 90,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          maxWidth: Sizes.containerWidth,
          overflow: "hidden",
        },
      ]}
      disabled={props.enabled ?? false}
      onPress={props.action}
    >
      <AnimatedGradient
        customColors={["#000", "#282828", "#555555", "#000000"]}
        speed={1000}
        style={{ width: "100%", height: "100%", position: "absolute" }}
      />
      <Text
        style={[
          styles.loginText,
          {
            color: props.foregroundColor,
            zIndex: 2,
          },
        ]}
      >
        {props.text}
      </Text>
      <Text
        style={{
          color: "white",
          marginTop: 8,
          fontSize: 13,
          letterSpacing: 1.2,
          zIndex: 2,
          textAlign: "center",
        }}
      >
        Für immer alle Fachbereiche.
      </Text>
    </TouchableOpacity>
  );
}

function CustomPlanButton(props: ButtonProps) {
  return (
    <TouchableOpacity
      style={[
        styles.loginScreenButtonCustom,
        {
          backgroundColor: props.backgroundColor,
          borderColor: props.borderColor,
          width: "100%",
          maxWidth: Sizes.containerWidth,
        },
      ]}
      disabled={props.enabled ?? false}
      onPress={props.action}
    >
      <Text
        style={[
          styles.loginTextCustom,
          {
            color: props.foregroundColor,
          },
        ]}
      >
        {props.text}
      </Text>
      <Text
        style={{
          color: Colors.black_opacity,
          marginTop: 8,
          fontSize: 13,
          letterSpacing: 0,
          textAlign: "center",
        }}
      >
        Einzelne Fachbereiche freischalten
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  loginScreenButton: {
    marginRight: 40,
    marginLeft: 40,
    marginTop: 10,
    marginBottom: 10,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 50,
    borderWidth: 0,
  },
  loginText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 19,
    letterSpacing: 0.5,
    paddingLeft: 16,
    paddingRight: 16,
  },
  loginScreenButtonCustom: {
    marginRight: 40,
    marginLeft: 40,
    marginTop: 10,
    marginBottom: 10,
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 50,
    borderWidth: 2,
  },
  loginTextCustom: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    paddingLeft: 16,
    paddingRight: 16,
  },
  container: {
    marginTop: 30,
    marginBottom: 30,
    borderRadius: 50,
    padding: 12,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    backgroundColor: Colors.background,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 12,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    width: "80%",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  box_title: {
    fontSize: 14,
    fontWeight: "700",
    opacity: 0.5,
    marginLeft: 12,
  },
  welcomeText: {
    fontSize: 16,
    fontWeight: "700",
  },
  greetingText: {
    marginTop: 3,
    fontSize: 12,
    lineHeight: 18,
    paddingLeft: 12,
    paddingRight: 12,
    textAlign: "left",
    fontWeight: "400",
    color: "#8B8B8B",
    marginBottom: 42,
  },
  textField: {
    backgroundColor: "#fafafa",
    shadowColor: "#000",
    shadowOpacity: 0.12,
    borderRadius: 80,
    width: "85%",
    height: 50,
    padding: 12,
    elevation: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    marginBottom: 12,
  },
  box: {
    backgroundColor: "#fff",
    borderRadius: 5,
    width: 126,
    height: 43,
    borderColor: "#c4c4c433",
    borderWidth: 1,
  },
  actionText: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 12,
    fontWeight: "700",
    opacity: 0.5,
    marginBottom: 20,
    marginTop: 12,
  },
});
