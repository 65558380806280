import { useSafeAreaInsets } from "react-native-safe-area-context";
import React, { Component } from "react";
import {
  Image,
  LayoutAnimation,
  Platform,
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import serenge_image from "../assets/images/child.png";
import { Colors } from "../Constants/Colors";

import arrow_left from "../assets/images/arrow_left.png";
import diabetic_image from "../assets/images/diabetic.png";
import ekg from "../assets/images/ekg.png";
import general from "../assets/images/general.png";
import glasses from "../assets/images/glass.png";
import liver_image from "../assets/images/heart_liver.png";
import old_image from "../assets/images/old.png";
import { Header } from "../Components/Header";
import { NavBar } from "../Components/NavBar";
import NavigationHandler from "../Handlers/NavigationHandler";
import { DecisionTreeComponent } from "../MainScreenComponents/DecisionTreeComponent";
import { ResultComponent } from "../MainScreenComponents/ResultComponent";
import { SelectCategoryComponent } from "../MainScreenComponents/SelectCategoryComponent";
import { SelectSubCategoryComponent } from "../MainScreenComponents/SelectSubCategoryComponent";
import { BackHandler } from "react-native";
import { Sizes } from "../Constants/Sizes";
import { ContentHandler } from "../Handlers/ContentHandler";
import { navigationRef } from "../Handlers/RootNavigation";
import LocalBackHandler from "../Handlers/BackHandler";

// global event bus

export interface MainScreenComponentProps {
  issuer: HomeScreen;
}

export class HomeScreen extends Component {
  scrollRef: React.RefObject<ScrollView> = React.createRef();

  state = {
    currentLevel: 0,
    currentID: 0,
    resID: 0,
    previousNodes: [],
    previousResults: [],
    currentPath: [],
    isFromSearch: false,
    isTutorial: false,
  };

  constructor(props: any) {
    super(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
  }

  componentWillMount() {
    BackHandler.addEventListener(
      "hardwareBackPress",
      this.handleBackButtonClick
    );
  }

  componentWillUnmount() {
    BackHandler.removeEventListener(
      "hardwareBackPress",
      this.handleBackButtonClick
    );
  }

  handleBackButtonClick() {
    if (this.state.currentLevel === 0) {
      navigationRef.goBack(),
        (NavigationHandler.getInstance().currentSubject = "");
    } else
      LocalBackHandler.getInstance().multipleBack(
        false,
        false,
        this.state.currentLevel
      );
    return true;
  }

  render() {
    NavigationHandler.getInstance().homeRef = this;

    if (this.scrollRef.current != null) {
      this.scrollRef.current.scrollTo({
        y: 0,
        animated: false,
      });
    }
    return (
      <SafeAreaView
        style={{
          flex: 1,
          ...Platform.select({ web: { height: "100vh", maxHeight: "100vh" } }),
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        }}
      >
        {!this.state.isTutorial ? null : (
          <View
            style={{
              width: "100%",
              height: "150%",
              zIndex: 10,
              position: "absolute",
              bottom: 0,
              right: 0,
            }}
          >
            <View
              style={{
                height: "100%",
                zIndex: 50,
                width: "100%",
                bottom: NavigationHandler.getInstance().bottomInset,
              }}
            >
              <NavBar
                isNews={false}
                isHints={false}
                level={this.state.currentLevel}
                issuer={this}
                isTutorial={this.state.isTutorial}
              />
            </View>
            <View
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                opacity: 0.5,
              }}
            ></View>
          </View>
        )}

        <View
          style={{
            flex: 1,
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "center",
            backgroundColor: Colors.background,
            overflow: "hidden",
          }}
        >
          <Header isProfile={false} isDark={false} issuer={this} />
          <NavBar
            isNews={false}
            isHints={false}
            level={this.state.currentLevel}
            issuer={this}
          />
          <View
            style={{
              maxWidth: Sizes.containerWidth,
              marginTop: 75,
              width: "100%",
              alignItems: "center",
              flex: 1,
              marginBottom: 60,
              ...Platform.select({
                ios: { overflow: "visible" },
                web: { overflow: "visible" },
              }),
              justifyContent: "center",
              zIndex: 1,
            }}
          >
            <View style={{ marginBottom: -50, paddingBottom: 20, zIndex: 10 }}>
              <TouchableOpacity
                onPress={() => navigationRef.goBack()}
                style={{ overflow: "visible" }}
              >
                <View
                  style={{
                    backgroundColor: Colors.gray,
                    padding: 8,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderBottomEndRadius: 10,
                    borderBottomLeftRadius: 10,
                    zIndex: 10,
                    overflow: "visible",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      color: "white",
                      fontWeight: "700",
                      fontSize: 11,
                      zIndex: 10,
                    }}
                  >
                    {NavigationHandler.getInstance().currentSubject}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            {(this.state.currentLevel >= 1 &&
              !NavigationHandler.getInstance().shouldShowResult()) ||
            (NavigationHandler.getInstance().shouldShowResult() &&
              !ContentHandler.getInstance().getIsResult()) ? (
              <Image
                source={category_to_image(
                  NavigationHandler.getInstance().getCurrentTarget()
                )}
                style={{
                  opacity: 0.05,
                  position: "absolute",
                  width: "140%",
                  height: "140%",
                  resizeMode: "contain",
                  right: 45,
                  top: -20,
                }}
              />
            ) : null}
            <ScrollView
              style={{ width: "100%", flex: 1, zIndex: 1 }}
              contentContainerStyle={{
                flexGrow: 1,
                alignItems: "center",
                ...Platform.select({ web: { flex: 1 } }),
                zIndex: 1,
                ...Platform.select({
                  ios: { overflow: "visible" },
                  web: { overflow: "visible" },
                }),
              }}
              horizontal={false}
              ref={this.scrollRef}
            >
              <View
                style={{
                  width: "100%",
                  alignItems: "center",
                  flex: 1,
                  marginTop: 30,
                  maxWidth: Sizes.containerWidth,
                  zIndex: 1,
                  overflow: "visible",
                }}
              >
                {getViewToCurrentLevel(this.state.currentLevel, this)}
              </View>
            </ScrollView>
            {/* {this.state.previousNodes.length > 0 &&
            !NavigationHandler.getInstance().isCustomResult &&
            this.state.currentLevel > 2 ? (
              <View
                style={{
                  overflow: "visible",
                  position: "absolute",
                  bottom: 20,
                  zIndex: 10,
                  left: 16,
                  width: 100,
                  justifyContent: "space-between",
                  padding: 0,
                  flexDirection: "row",
                  alignItems: "flex-end",
                  height: 30,
                }}
              >
                <TouchableOpacity
                  style={{ overflow: "visible" }}
                  onPress={() => {
                    BackHandler.getInstance().singleBack();
                  }}
                >
                  <View
                    style={{
                      padding: 12,
                      elevation: 5,
                      alignItems: "center",
                      overflow: "visible",
                      shadowColor: "#000",
                      width: 100,
                      backgroundColor: "white",
                      justifyContent: "center",
                      shadowOpacity: 0.25,
                      shadowOffset: { width: 2, height: 4 },
                      shadowRadius: 6,
                      borderRadius: 6,
                      flexDirection: "row",
                    }}
                  >
                    <Image
                      style={{
                        width: 12,
                        height: 12,
                        marginRight: 8,
                        opacity: 0.5,
                        resizeMode: "contain",
                        overflow: "visible",
                      }}
                      source={arrow_left}
                    />
                    <Text
                      style={{
                        opacity: 0.5,
                        fontSize: 12,
                        fontWeight: "700",
                        ...Platform.select({ android: { height: 18 } }),
                      }}
                    >
                      {"zurück"}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            ) : null} */}
          </View>
        </View>
      </SafeAreaView>
    );
  }
}

function category_to_image(category: string) {
  if (NavigationHandler.getInstance().currentSubject === "Anästhesiologie") {
    if (category == "Kinder") {
      return serenge_image;
    } else if (category == "Diabetiker") {
      return diabetic_image;
    } else if (category == "Alte & Adipöse") {
      return old_image;
    } else {
      return liver_image;
    }
  } else if (
    NavigationHandler.getInstance().currentSubject ===
    "Perioperative Komplikationen"
  ) {
    if (category === "Allgemein") {
      return general;
    } else {
      return glasses;
    }
  } else {
    if (category === "EKG - Tool") {
      return ekg;
    }
  }
}

function getViewToCurrentLevel(level: number, issuer: HomeScreen) {
  /* LayoutAnimation.configureNext(
    LayoutAnimation.Presets.easeInEaseOut
  );*/

  if (level === 0) {
    return <SelectCategoryComponent issuer={issuer} />;
  } else if (issuer.state.currentLevel === 1) {
    return <SelectSubCategoryComponent issuer={issuer} />;
  } else if (NavigationHandler.getInstance().shouldShowResult()) {
    return (
      <ResultComponent key={issuer.state.resID} home={issuer} isPH={false} />
    );
  } else {
    return <DecisionTreeComponent issuer={issuer} />;
  }
}
