import { useNavigation } from "@react-navigation/native";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import info from "../../assets/images/info.png";
import { Colors } from "../../Constants/Colors";
import NavigationHandler from "../../Handlers/NavigationHandler";

interface FirstResultProps {
  title: string;
  color: string;
  id: string;
}

export function InfoBoxHTML(props: FirstResultProps) {
  const navigation = useNavigation();

  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
      }}
    >
      <TouchableOpacity
        onPress={() => {
          NavigationHandler.getInstance().openPracticalHintsResult(props.id);
          NavigationHandler.getInstance().isFromInfo = true;

          navigation.navigate("PracticalHintsScreen");
        }}
        activeOpacity={0.7}
      >
        <View style={[styles.result_container, { borderColor: props.color }]}>
          <View
            style={{
              padding: 20,
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                width: "90%",
                alignItems: "center",
              }}
            >
              <Text style={[styles.result_text, { color: props.color }]}>
                {props.title}
              </Text>
            </View>

            <Image
              source={info}
              style={{
                width: 22,
                height: 22,
                resizeMode: "contain",
                marginLeft: 12,
                tintColor: props.color,
              }}
            />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  result_container: {
    borderRadius: 10,
    borderWidth: 2,

    flexDirection: "column",
    backgroundColor: Colors.white,
  },
  result_text: {
    fontWeight: "700",
    fontSize: 12,
    width: "90%",
    lineHeight: 15,
  },
});
