import { useState } from "react";
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { Colors } from "../../Constants/Colors";
import { Sizes } from "../../Constants/Sizes";
import NavigationHandler from "../../Handlers/NavigationHandler";
import box_check from "../../assets/images/box_check.png";
import box_nocheck from "../../assets/images/box_nocheck.png";

interface MulitpleChoiceProps {
  title: string;
  filterId: string | undefined;
  nextPage: string | undefined;
}

export function MultipleChoiceActionBoxHTML(props: MulitpleChoiceProps) {
  const [selected, setSelected] = useState(false);

  return (
    <View
      style={{
        width: "100%",
        marginTop: 20,
        ...Platform.select({ web: { cursor: "pointer" } }),
      }}
    >
      <TouchableWithoutFeedback
        style={{ width: "100%" }}
        onPress={() => {
          if (!selected) {
            if (props.filterId !== undefined) {
              NavigationHandler.getInstance().currentFilters.push(
                props.filterId
              );
            } else {
              NavigationHandler.getInstance().nextMultiplePages.push(
                props.nextPage!
              );
            }
          } else {
            if (props.filterId !== undefined) {
              NavigationHandler.getInstance().currentFilters =
                NavigationHandler.getInstance().currentFilters.filter(
                  (f) => f !== props.filterId
                );
            } else {
              NavigationHandler.getInstance().nextMultiplePages =
                NavigationHandler.getInstance().nextMultiplePages.filter(
                  (f) => f !== props.nextPage
                );
            }
          }

          setSelected(!selected);
        }}
      >
        <View
          style={[
            styles.container,
            {
              borderColor: selected ? Colors.gold : Colors.white,
              backgroundColor: selected ? "#FFF8DA" : Colors.white,
            },
          ]}
        >
          <Text style={styles.content_text}>{props.title}</Text>
          <Image
            source={selected ? box_check : box_nocheck}
            style={{
              width: 20,
              height: 20,
              resizeMode: "contain",
            }}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    borderWidth: 1,
    padding: 20,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.12,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    width: "100%",

    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ...Platform.select({ web: { cursor: "pointer" } }),
  },
  content_text: {
    color: "#000",
    opacity: 0.5,
    fontSize: Sizes.decisionText,
    lineHeight: Sizes.decisionTextLineHeight,
    fontWeight: "700",
    width: "90%",
  },
});
