import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "../Constants/Colors";

interface ProgressProps {
  steps: number;
  current_step: number;
  widthHeader?: string;
}

export function ProgressContainer(props: ProgressProps) {
  let currentStep = props.current_step;
  let steps = props.steps;
  if (steps < currentStep) {
    steps = currentStep;
  }

  const [width, setWidth] = useState(0);
  const progressWidthHeader: string = (100 / steps).toString() + "%";
  const progressWidthBar: string = (100 / steps) * (currentStep - 2) + "%";
  return (
    <View style={{ width: "100%" }}>
      <View style={{ alignItems: "center" }}>
        <View
          style={{
            justifyContent: "space-between",
            flexDirection: "row",
            marginLeft: 5 * steps,
            marginRight: 5 * steps,
          }}
        >
          {/* <TextList 
                steps={props.steps}
                current_step={props.current_step}
    widthHeader={progressWidthHeader}/>*/}
        </View>
      </View>
      <View
        style={styles.progress_background}
        onLayout={(event) => {
          setWidth(event.nativeEvent.layout.width);
        }}
      >
        <View
          style={[
            styles.progress,
            {
              width: progressWidthBar,
            },
          ]}
        />
        <View
          style={[
            styles.progress_head,
            {
              left: (width / steps) * (currentStep - 2),
              width: progressWidthHeader,
            },
          ]}
        />
      </View>
    </View>
  );
}

{
  /*function TextList(props: ProgressProps) {
    let return_array = [];
    props.steps.map((data) => {
        const index = props.steps.indexOf(data) + 1;
        return_array.push(<Text numberOfLines={1} style={[index == props.current_step ? styles.text_selected : styles.text,
        {width: props.widthHeader}
        ]}>{data}</Text>);
    })
    return return_array;
}*/
}

const styles = StyleSheet.create({
  text: {
    fontSize: 12,
    letterSpacing: 0.5,
    color: Colors.gold,
    textAlign: "center",
    marginLeft: 5,
    marginRight: 5,
  },
  text_selected: {
    fontSize: 14,
    letterSpacing: 0.5,
    color: Colors.gold,
    fontWeight: "700",
    textAlign: "center",
    marginLeft: 5,
    marginRight: 5,
  },
  progress_background: {
    backgroundColor: "#c4c4c432",
    borderRadius: 20,
    width: "100%",
    height: 8,
    marginTop: 8,
  },
  progress: {
    opacity: 0.2,
    backgroundColor: Colors.gold,
    borderRadius: 20,
    height: 8,
    position: "absolute",
    top: 0,
    left: 0,
  },
  progress_head: {
    backgroundColor: Colors.gold,
    borderRadius: 20,
    height: 8,
    position: "absolute",
    top: 0,
  },
});
