export const Colors = {
  white: "#ffffff",
  black: "#000",
  gold: "#f4c430",
  gold_darker: "#c1940a",
  gray: "#c4c4c4",
  gray_opacity: "#c4c4c480",
  background: "#FAFAFA",
  light_green: "#12B347",
  red: "#FF4040",
  black_opacity: "#00000099",
};
