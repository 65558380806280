import { useWindowDimensions } from "react-native";
import { Sizes } from "../Constants/Sizes";

export function getBoxWidth(height: number, width: number) {
  if (height === undefined || width === undefined) return 0;
  //  let { width, height } = useWindowDimensions();
  //height -= 200;

  if (width > Sizes.containerWidth) width = Sizes.containerWidth;

  return Math.min((width / 2) * 0.82, (height / 2) * 0.82);
}
