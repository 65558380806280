import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import {
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
  TouchableOpacity,
} from "react-native";
import { ActivityIndicator, DefaultTheme, Searchbar } from "react-native-paper";
import RenderHTML from "react-native-render-html";
import arrow_left from "../assets/images/arrow_right_search.png";
import { Header } from "../Components/Header";
import { NavBar } from "../Components/NavBar";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";
import { ContentHandler } from "../Handlers/ContentHandler";
import NavigationHandler from "../Handlers/NavigationHandler";

// global event bus

export function SearchScreen() {
  const [searchQuery, setSearchQuery] = useState("");
  const [res, setRes] = useState({
    tierOne: [],
    tierTwo: [],
    tierThree: [],
    tierFour: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const onChangeSearch = (query: string) => {
    setSearchQuery(query);
    if (query === "") {
      setRes({ tierOne: [], tierTwo: [], tierThree: [], tierFour: [] });
      setIsEmpty(false);
    }
  };
  const navigation = useNavigation();

  const exposedSearchQueryText = (text: string) => {
    let t = text.replace(/<[^>]*>/g, " ");
    t = t.replace(/  /g, " ");
    let exposedStringIndex = t.indexOf(searchQuery);
    let exposedString = t;
    if (exposedStringIndex > 30) {
      exposedString = t.slice(exposedStringIndex - 30);
    }
    if (exposedString.length > 60 + searchQuery.length) {
      exposedString = exposedString.slice(0, searchQuery.length + 60);
    }
    exposedStringIndex = exposedString.indexOf(searchQuery);
    exposedString = [
      exposedString.slice(0, exposedStringIndex),
      "<b>",
      exposedString.slice(exposedStringIndex),
    ].join("");
    exposedStringIndex = exposedString.indexOf(searchQuery);
    exposedString = [
      exposedString.slice(0, exposedStringIndex + searchQuery.length),
      "</b>",
      exposedString.slice(exposedStringIndex + searchQuery.length),
    ].join("");

    return "<p>..." + exposedString + "...</p>";
  };

  const renderItemTierOne = (item) => {
    let paths = NavigationHandler.getInstance().getPathFromID(item.id);
    if (paths === null) {
      return null;
    }
    return (
      <View style={{ margin: 12, width: "90%" }}>
        <TouchableOpacity
          style={{ width: "100%" }}
          onPress={() => {
            NavigationHandler.getInstance().setCurrentResultID(item.id);
            NavigationHandler.getInstance().setShouldShowResult(true);
            NavigationHandler.getInstance().homeRef.setState({
              currentLevel: 5,
              isFromSearch: true,
            });
            navigation.goBack();
          }}
        >
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 12,
                  color: Colors.black_opacity,
                  marginBottom: 8,
                }}
              >
                {paths?.pathString}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: Colors.black_opacity,
                  fontSize: 15,
                }}
              >
                {item.title}
              </Text>
            </View>
            <Image
              source={arrow_left}
              style={{
                marginLeft: 20,
                width: 10,
                height: 20,
                tintColor: Colors.black_opacity,
                resizeMode: "contain",
              }}
            />
          </View>
        </TouchableOpacity>
        <View
          style={{
            width: "100%",
            height: 1,
            opacity: 0.2,
            marginTop: 20,
            backgroundColor: "black",
          }}
        />
      </View>
    );
  };

  const renderItemTierThree = (item) => {
    let paths = NavigationHandler.getInstance().getPathFromID(item.id);
    if (paths === null) {
      return null;
    }
    return (
      <View style={{ margin: 12, width: "90%" }}>
        <TouchableOpacity
          style={{ width: "100%" }}
          onPress={() => {
            NavigationHandler.getInstance().setCurrentResultID(item.id);
            NavigationHandler.getInstance().setShouldShowResult(true);
            NavigationHandler.getInstance().homeRef.setState({
              currentLevel: 5,
              isFromSearch: true,
              currentPath: paths?.path,
            });
            navigation.goBack();
          }}
        >
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 12,
                  color: Colors.black_opacity,
                  marginBottom: 8,
                }}
              >
                {paths?.pathString}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: Colors.black_opacity,
                  fontSize: 15,
                }}
              >
                {item.title}
              </Text>
              <RenderHTML
                source={{ html: exposedSearchQueryText(item.text) }}
                tagsStyles={{
                  p: { color: Colors.black_opacity, fontSize: 12 },
                  b: { color: Colors.gold, fontSize: 14 },
                }}
              />
            </View>
            <Image
              source={arrow_left}
              style={{
                marginLeft: 20,
                width: 10,
                height: 20,
                tintColor: Colors.black_opacity,
                resizeMode: "contain",
              }}
            />
          </View>
        </TouchableOpacity>
        <View
          style={{
            width: "100%",
            height: 1,
            opacity: 0.2,
            marginTop: 20,
            backgroundColor: "black",
          }}
        />
      </View>
    );
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        ...Platform.select({ web: { maxHeight: "100vh" } }),
        justifyContent: "center",
        backgroundColor: "#fff",
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          width: "100%",
          alignItems: "center",
          backgroundColor: Colors.background,
          overflow: "hidden",
        }}
      >
        {isLoading ? (
          <View
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator
              theme={{
                colors: {
                  ...DefaultTheme.colors,
                  primary: Colors.gold,
                },
              }}
              size="large"
            />
          </View>
        ) : null}
        <Header isProfile={false} isDark={false} issuer={this} />
        <NavBar isNews={false} isHints={false} level={-1} issuer={this} />
        <View
          style={{
            marginTop: 35,
            width: "100%",
            alignItems: "center",
            flex: 1,
            marginBottom: -10,
            paddingBottom: 30,

            overflow: "hidden",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          <View
            style={{
              marginTop: 40,
              marginBottom: -45,
              paddingBottom: 20,
              zIndex: 10,
            }}
          >
            <TouchableOpacity
              onPress={() => navigation.goBack()}
              style={{ overflow: "visible" }}
            >
              <View
                style={{
                  backgroundColor: Colors.gray,
                  padding: 8,
                  paddingLeft: 20,
                  paddingRight: 20,
                  borderBottomEndRadius: 10,
                  borderBottomLeftRadius: 10,
                  zIndex: 10,
                  overflow: "visible",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: "white",
                    fontWeight: "700",
                    fontSize: 11,
                    zIndex: 10,
                  }}
                >
                  {NavigationHandler.getInstance().currentSubject}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
          <ScrollView
            style={{ width: "100%", flex: 1, zIndex: 1 }}
            contentContainerStyle={{
              flexGrow: 1,

              alignItems: "center",
              zIndex: 1,
            }}
            horizontal={false}
          >
            <View
              style={{
                width: "90%",
                alignItems: "center",
                marginTop: 50,

                zIndex: 1,
              }}
            >
              <Searchbar
                onChangeText={onChangeSearch}
                style={{
                  borderRadius: 6,
                  width: "100%",
                  maxWidth: Sizes.containerWidth,
                  marginBottom: 20,
                }}
                value={searchQuery}
                inputStyle={{ fontSize: 12, opacity: 0.5 }}
                theme={{
                  colors: {
                    ...DefaultTheme.colors,
                    primary: "#000",
                  },
                }}
                onSubmitEditing={() => {
                  if (searchQuery.length > 3) {
                    AnalyticsHandler.getInstance().logScreenEvent(
                      "Search",
                      "issued",
                      searchQuery
                    );
                    setIsLoading(true);
                    setIsEmpty(false);
                    let t = setInterval(() => {
                      clearInterval(t);
                      let res =
                        ContentHandler.getInstance().issueSearch(searchQuery);
                      setRes(res);

                      if (
                        res.tierOne.length === 0 &&
                        res.tierTwo.length === 0 &&
                        res.tierThree.length === 0 &&
                        res.tierFour.length === 0
                      ) {
                        setIsEmpty(true);
                      } else {
                        setIsEmpty(false);
                      }
                      setIsLoading(false);
                    }, 100);
                  }
                }}
                placeholder={"Suchbegriff eingeben"}
              />
            </View>
            {isEmpty ? (
              <Text style={{ fontSize: 13, color: Colors.black_opacity }}>
                Keine Suchergebnisse vorhanden
              </Text>
            ) : null}
            {res.tierFour
              .filter(
                (i) =>
                  i.title !== NaN && i.title !== undefined && i.title !== ""
              )
              .map((i) => {
                return (
                  <View
                    key={Math.random()}
                    style={{ width: "100%", maxWidth: Sizes.containerWidth }}
                  >
                    {renderItemTierOne(i)}
                  </View>
                );
              })}
            {res.tierOne
              .filter(
                (i) =>
                  i.title !== NaN && i.title !== undefined && i.title !== ""
              )
              .map((i) => {
                return (
                  <View
                    key={Math.random()}
                    style={{ width: "100%", maxWidth: Sizes.containerWidth }}
                  >
                    {renderItemTierOne(i)}
                  </View>
                );
              })}
            {res.tierTwo
              .filter(
                (i) =>
                  i.title !== NaN && i.title !== undefined && i.title !== ""
              )
              .map((i) => {
                return (
                  <View
                    key={Math.random()}
                    style={{ width: "100%", maxWidth: Sizes.containerWidth }}
                  >
                    {renderItemTierThree(i)}
                  </View>
                );
              })}
            {res.tierThree
              .filter(
                (i) =>
                  i.title !== NaN && i.title !== undefined && i.title !== ""
              )
              .map((i) => {
                return (
                  <View
                    key={Math.random()}
                    style={{ width: "100%", maxWidth: Sizes.containerWidth }}
                  >
                    {renderItemTierThree(i)}
                  </View>
                );
              })}
          </ScrollView>
        </View>

        <TouchableOpacity>
          <View
            style={{
              padding: 12,
              elevation: 5,
              alignItems: "center",
              shadowColor: "#000",
              width: 100,
              justifyContent: "center",
              shadowOpacity: 0.25,
              shadowOffset: { width: 2, height: 4 },
              shadowRadius: 6,
              backgroundColor: "white",
              borderRadius: 6,
              flexDirection: "row",
            }}
          >
            <Image
              style={{
                width: 12,
                height: 12,
                marginRight: 8,
                opacity: 0.5,
                resizeMode: "contain",
              }}
              source={arrow_left}
            />
            <Text style={{ opacity: 0.5, fontSize: 12, fontWeight: "700" }}>
              {"zurück"}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}
