import { Component } from "react";
import {
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from "react-native";
import gradient from "../assets/images/gradient.jpg";
import { Header } from "../Components/Header";
import { NavBar } from "../Components/NavBar";
import { SubCategoryOption } from "../Components/SubCategoryOption";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import NavigationHandler from "../Handlers/NavigationHandler";
import { ResultComponent } from "../MainScreenComponents/ResultComponent";

export class PracticalHintsScreen extends Component {
  state = {
    isResult: true,
    phID: "",
    previousResults: [],
    previousNodes: [],
    currentLevel: 0,
  };

  componentDidMount() {
    if (NavigationHandler.getInstance().currentPHResult === "") {
      NavigationHandler.getInstance().setFirstPracticalHintID();
    }

    NavigationHandler.getInstance().isPH = true;

    this.setState({
      phID: NavigationHandler.getInstance().currentPHResult,
      resID: 0,
      previousResults: [],
      previousNodes: [],
    });
  }

  render() {
    NavigationHandler.getInstance().phRef = this;

    return (
      <SafeAreaView
        style={{
          flex: 1,
          ...Platform.select({ web: { maxHeight: "100vh" } }),
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
          ...Platform.select({ web: { height: "100vh" } }),
        }}
      >
        {this.state.phID === "" ? null : (
          <View
            style={{
              flex: 1,
              justifyContent: "flex-start",
              width: "100%",
              alignItems: "center",
              backgroundColor: Colors.background,
            }}
          >
            <Image
              source={gradient}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: 0,
                top: 0,
              }}
            />
            <Header isProfile={false} isDark={false} />
            <NavBar isNews={false} isHints={true} level={0} issuer={this} />
            <View
              style={{
                marginTop: 75,
                width: "100%",
                alignItems: "center",
                flex: 1,
                marginBottom: 60,
                overflow: "visible",
                justifyContent: "center",
              }}
            >
              <ScrollView
                style={{ width: "100%", flex: 1 }}
                contentContainerStyle={{ flexGrow: 1, alignItems: "center" }}
                horizontal={false}
              >
                <View
                  style={{
                    width: "100%",
                    alignItems: "center",
                    height: "100%",
                    paddingTop: 30,
                    maxWidth: Sizes.containerWidth,
                  }}
                >
                  {getViewToLevelOfHnts(
                    this.state.isResult,
                    this,
                    this.state.phID
                  )}
                </View>
              </ScrollView>
            </View>
          </View>
        )}
      </SafeAreaView>
    );
  }
}

function createPracticalHintViews(issuer) {
  let ra: JSX.Element[] = [];
  const c = NavigationHandler.getInstance().hintsContent;
  c.map((topic) => {
    ra.push(
      <SubCategoryOption
        text={topic.title}
        action={() => {
          issuer.setState({ phID: topic.id });
          issuer.setState({ isResult: true });
        }}
      />
    );
  });
  return ra;
}

function getViewToLevelOfHnts(
  isResult: boolean,
  issuer: PracticalHintsScreen,
  id: string
) {
  NavigationHandler.getInstance().isPH = true;
  if (isResult) {
    //NavigationHandler.getInstance().setCurrentPHResult(id);
    return (
      <ResultComponent home={issuer} key={issuer.state.resID} isPH={true} />
    );
  } else {
    return (
      <View
        style={{
          marginTop: 120,
          width: "90%",
          alignItems: "flex-start",
          flex: 1,
          marginBottom: 60,
          height: "100%",
          overflow: "visible",
          justifyContent: "flex-start",
        }}
      >
        <Text style={styles.title}>PRAKTISCHE HINWEISE</Text>
        <View style={{ width: "100%", marginTop: 30 }}>
          {createPracticalHintViews(issuer)}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    fontWeight: "700",
    fontSize: 16,
    color: "#fff",
  },
});
