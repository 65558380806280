import AsyncStorage from "@react-native-async-storage/async-storage";
import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { Image, Platform, Text, TouchableOpacity, View } from "react-native";
import { NavigationProps } from "../App";
import launch from "../assets/images/launch_short_reverse.gif";
import { db } from "../Components/db";
import { PaymentHandler } from "../Handlers/PaymentHandler";
import { navigationRef } from "../Handlers/RootNavigation";
import UserHandler from "../Handlers/UserHandler";

import { Analytics, getAnalytics } from "firebase/analytics";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";
import { doc, getDoc } from "firebase/firestore";

export let analytics: Analytics;
let TARGET: string | undefined = undefined;

export function LaunchScreen({ navigation }: NavigationProps) {
  let shouldFire = false;
  const [retrack, setRetrack] = useState(false);

  let timer: ReturnType<typeof setInterval>;
  let timer_: ReturnType<typeof setInterval>;

  let isTut = false;

  useEffect(() => {
    if (Platform.OS === "web") {
      const firebaseConfig = {
        apiKey: "AIzaSyBLmhMclUq9WpBApZ9XQ-hoggfSUGkIR7U",
        authDomain: "medify-826de.firebaseapp.com",
        databaseURL:
          "https://medify-826de-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "medify-826de",
        storageBucket: "medify-826de.appspot.com",
        messagingSenderId: "7305114246",
        appId: "1:7305114246:web:d1fb6402a4ea6b660f1707",
        measurementId: "G-PKXGYMHVXC",
      };

      let app;

      if (firebase.apps.length === 0) {
        app = firebase.initializeApp(firebaseConfig);
      } else {
        app = firebase.app();
      }

      // Initialize Analytics and get a reference to the service
      analytics = getAnalytics(app);
    } else {
      (async () => {
        let t = await AsyncStorage.getItem("didShowTutorial");

        if (t !== "1") {
          clearInterval(timer_);
          isTut = true;
          _navigate("TutorialLanding", shouldFire);
        }
      })();
    }

    timer_ = setInterval(() => {
      clearInterval(timer_);

      setRetrack(true);
    }, 5000);
    timer = setInterval(() => {
      _navigate(TARGET, true);
      clearInterval(timer);
      shouldFire = true;
    }, 1200);

    (async () => {
      const logged = await getUserInformation();

      if (logged) {
        const success = await getUserData();
        if (!success) {
          return;
        }
        if (UserHandler.getInstance().getCurrentUser().email_verified === 0) {
          clearInterval(timer_);
          _navigate("WaitingForRegistrationScreen", shouldFire);
        } else {
          if (UserHandler.getInstance().isPayingUser()) {
            UserHandler.getInstance()._isPayingUser = true;
            clearInterval(timer_);
            _navigate("SelectMedicalCategoryScreen", shouldFire);
            return;
          } else {
            await PaymentHandler.getInstance().verifyPayment(function f(
              success
            ) {
              if (success) {
                var target = "SelectMedicalCategoryScreen";
              } else {
                var target = "PaymentScreen";
              }
              clearInterval(timer_);
              _navigate(target, shouldFire);
            });
          }
        }
      } else {
        if (!isTut) {
          clearInterval(timer_);
          _navigate("FirstLandingScreen", shouldFire);
        }
      }
    })();
  }, []);

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#000",
      }}
    >
      <Image
        source={launch}
        style={{
          width: "100%",
          height: "100%",
          resizeMode: "contain",
          ...Platform.select({
            web: { height: "80vh", marginBottom: 50, resizeMode: "contain" },
          }),
        }}
      />
      {retrack ? (
        <TouchableOpacity
          onPress={() => navigationRef.navigate("FirstLandingScreen")}
          style={{
            position: "absolute",
            bottom: 20,
            width: "100%",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: 15,
              color: "white",
              textDecorationLine: "underline",
            }}
          >
            Zur Anmeldung
          </Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );
}

function _navigate(target: string | undefined, shouldFire: boolean) {
  TARGET = target;

  if (shouldFire && target !== undefined) {
    navigationRef.navigate(target);
  }
}

async function getUserInformation() {
  return await UserHandler.getInstance().getLogged();
}

async function getUserData() {
  const user = await AsyncStorage.getItem("email");
  if (user === null) return false;

  try {
    const docRef = doc(db, "users", user.toLowerCase());
    const user_ref = await getDoc(docRef);

    const user_data = user_ref.data();

    console.log("date", user_data.free_use_until);

    if (user_data === undefined) {
      await UserHandler.getInstance().getOfflineUser();
      return true;
    }
    UserHandler.getInstance().setCurrentUser(user_data);
    AnalyticsHandler.getInstance().logLogin();
    UserHandler.getInstance().updateOfflineUser();
  } catch {
    await UserHandler.getInstance().getOfflineUser();
    return true;
  }

  return true;
}
