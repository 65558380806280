import { Component } from "react";
import {
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from "react-native";
import ce from "../../assets/images/ce.png";
import factory from "../../assets/images/factory.png";
import { Header } from "../../Components/Header";
import { NavBar } from "../../Components/NavBar";
import { Colors } from "../../Constants/Colors";
import { Sizes } from "../../Constants/Sizes";
import { VERSION } from "../../Constants/Versioning";

export class ImpressScreen extends Component {
  state = {
    isResult: false,
    phID: "",
  };

  render() {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "center",
            backgroundColor: Colors.background,
          }}
        >
          <Header isProfile={false} isDark={false} />
          <NavBar isHints={false} level={-1} isNews={false} issuer={this} />
          <View
            style={{
              marginTop: 0,
              width: "100%",
              alignItems: "center",
              flex: 1,
              marginBottom: 60,
              overflow: "visible",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                width: "100%",
                alignItems: "center",
                height: "100%",
                paddingTop: 12,
                maxWidth: Sizes.containerWidth,
              }}
            >
              <ScrollView
                style={{ width: "100%", flex: 1 }}
                contentContainerStyle={{ flexGrow: 1, alignItems: "center" }}
                horizontal={false}
              >
                <View
                  style={{
                    marginTop: 110,
                    width: "90%",
                    alignItems: "flex-start",
                    flex: 1,
                    marginBottom: 60,
                    height: "100%",
                    overflow: "visible",
                    justifyContent: "flex-start",
                  }}
                >
                  <Text style={styles.title}>Impressum</Text>
                  <Text>{`
Version ${VERSION}

MEDICEO GmbH
Siesmayerstraße 10,
60323 Frankfurt am Main
`}</Text>
                  <Image
                    source={factory}
                    style={{
                      marginVertical: 20,
                      width: 75,
                      resizeMode: "contain",
                      height: 75,
                    }}
                  />
                  <Text>{`Geschäftsführung: Tom Godbersen, Matthias Hippe

E-Mail-Adresse: info@medi.ceo
Umsatzsteuer-Identifikationsnummer: DE353993825
Gerichtsstand: HRB127644 | Amtsgericht Frankfurt

Verantwortlich für den Inhalt: Mediceo GmbH
`}</Text>
                  <Image
                    source={ce}
                    style={{
                      marginVertical: 20,
                      width: 75,
                      resizeMode: "contain",
                      height: 75,
                    }}
                  />
                  <Text>
                    Die MEDICEO App ist ein Medizinprodukt der Risikoklasse I im
                    Sinne der Verordnung (EU) 2017/745. Der Hersteller erklärt
                    die Konformität dieses Produktes mit den grundlegenden
                    Anforderungen gemäß Verordnung (EU) 2017/745, Anhang I und
                    dokumentiert dies durch die CE-Kennzeichnung.{"\n\n"}Basis
                    UDI: 426237899001DN
                  </Text>
                </View>
              </ScrollView>
            </View>
          </View>
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    fontWeight: "700",
    fontSize: 16,
    color: "#000",
  },
});
