import {
  CommonActions,
  StackActions,
  useNavigation,
} from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Image, Linking, Text, View, TouchableOpacity } from "react-native";
import arrow from "../assets/images/arrow_landing.png";
import download_ios from "../assets/images/DownloadAppStore.png";
import download_android from "../assets/images/DownloadPlayStore.png";
import schwarzer_stab from "../assets/images/schwarzer_stab.png";
import { Colors } from "../Constants/Colors";
import NavigationHandler from "../Handlers/NavigationHandler";
import UserHandler from "../Handlers/UserHandler";
import { PromocodeModal } from "../Screens/PromocodeModal";

export function PaymentScreenWeb() {
  const [modalVisible, setModalVisible] = useState(false);
  const navigation = useNavigation();

  return (
    <View
      style={{
        backgroundColor: "#000",
        height: "100%",
        overflow: "hidden",
        width: "100%",
        padding: 20,
        alignItems: "center",
      }}
    >
      <PromocodeModal
        isVisible={modalVisible}
        setIsVisible={setModalVisible}
        successAction={() => {
          const resetAction = CommonActions.reset({
            index: 1,
            routes: [{ name: "SelectMedicalCategoryScreen", params: {} }],
          });
          NavigationHandler.getInstance().firstRoute = false;
          navigation.dispatch(resetAction);
        }}
      />
      {modalVisible ? (
        <View
          style={{
            position: "absolute",
            width: "150%",
            height: "150%",
            backgroundColor: "#000",
            opacity: 0.75,
            zIndex: 10,
          }}
        />
      ) : null}
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Text
          style={{
            color: "#fff",
            fontSize: 20,
            fontWeight: "bold",
            marginTop: 100,
            textTransform: "uppercase",
          }}
        >
          {UserHandler.getInstance().getCurrentUser().role === "student"
            ? "schließen Sie Ihre Registrierung ab in der"
            : "SCHLIEße DEINE registrierung ab in der "}
        </Text>
        <Text
          style={{
            color: Colors.gold,
            fontSize: 46,
            fontWeight: "bold",
            marginTop: 20,
          }}
        >
          MEDICEO® APP
        </Text>
        <Image
          source={arrow}
          style={{
            position: "absolute",
            right: 20,
            top: 220,
            height: 120,
            width: 100,
            resizeMode: "contain",
          }}
        />
        <View
          style={{
            flex: 1,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              width: "50%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TouchableOpacity
              style={{ marginBottom: 20, height: 80, width: 150 }}
              onPress={() => {
                Linking.openURL(
                  "https://apps.apple.com/de/app/mediceo/id1629718911"
                );
              }}
            >
              <Image
                source={download_ios}
                style={{
                  width: "100%",
                  height: "100%",
                  resizeMode: "contain",
                }}
              />
            </TouchableOpacity>
            <Image
              source={download_android}
              style={{
                width: 150,
                height: 80,
                resizeMode: "contain",
                opacity: 0.5,
              }}
            />
          </View>
        </View>

        <View
          style={{
            height: "20%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setModalVisible(true);
            }}
          >
            <View
              style={{
                backgroundColor: "black",
                padding: 16,
                paddingLeft: 26,
                paddingRight: 26,
                shadowColor: "white",
                marginTop: 20,
                shadowOpacity: 0,
                shadowOffset: { width: 0, height: 0 },
                shadowRadius: 10,
                borderRadius: 20,
              }}
            >
              <Text
                style={{
                  color: Colors.gold,
                  fontSize: 15,
                  letterSpacing: 1,
                  fontWeight: "bold",
                }}
              >
                Promocode einlösen
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              UserHandler.getInstance().logout();
              navigation.dispatch(StackActions.popToTop());
              navigation.navigate("FirstLandingScreen");
            }}
            style={{ padding: 12, marginTop: 12 }}
          >
            <Text
              style={{
                color: "white",
                opacity: 0.5,
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              Abmelden
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <Image
        style={{
          width: "120%",
          height: "120%",
          position: "absolute",
          marginLeft: 24,
          top: 0,
          resizeMode: "contain",
          opacity: 0.35,
          zIndex: -1,
        }}
        source={schwarzer_stab}
      />
    </View>
  );
}
