class SearcherNode {
  children: SearcherNode[] = [];
  id;
  json;
  key;
  niveau;

  constructor(
    id: number,
    children: number[],
    json: any,
    key: string,
    niveau: number
  ) {
    this.id = id;
    this.json = json;
    this.key = key;
    this.niveau = niveau + 1;

    this.children = this._getChildren(children);
  }

  _getChildren(children: number[]) {
    const ra = [];

    for (const children_id of children) {
      ra.push(
        new SearcherNode(
          children_id,
          this._find_child(children_id),
          this.json,
          this.key,
          this.niveau
        )
      );
    }
    return ra;
  }

  _find_child(id: number) {
    for (const node of this.json.content[0][this.key]) {
      if (node["id"] === id) {
        return node.children;
      }
    }
  }
}

class Tree {
  rootnode;
  max_height;

  constructor(children: [], key: string, json: any) {
    this.rootnode = new SearcherNode(0, children, json, key, 0);

    this.max_height = 0;
  }

  get_size(node: SearcherNode) {
    for (const child of node.children) {
      if (child.niveau > this.max_height) {
        this.max_height = child.niveau;
      }
      this.get_size(child);
    }
  }
}

export function get_tree_size(json_data: any, key: string) {
  var tree;
  const node = json_data.content[0][key][0];

  tree = new Tree(node.children, key, json_data);
  tree.get_size(tree.rootnode);

  return tree.max_height - 1;
}
