import NetInfo from "@react-native-community/netinfo";
import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import burger from "../assets/images/burger.png";
import cross from "../assets/images/cross.png";
import logo from "../assets/images/logo.png";
import logo_dark from "../assets/images/logo_weiß.png";
import profileIcon from "../assets/images/profile.png";
import { Colors } from "../Constants/Colors";
import NavigationHandler from "../Handlers/NavigationHandler";
import UserHandler from "../Handlers/UserHandler";

import { useEffect } from "react";

interface HeaderProps {
  isDark: boolean;
  issuer: any;
  isProfile: boolean;
}

export function Header(props: HeaderProps) {
  const [menuIsOpen, setMenuIsOpen] = useState(props.isDark);
  const [networkState, setNetworkState] = useState(false);
  let loaded = false;
  const [needsRefresh, setNeedsRefresh] = useState(false);

  const [profileImage, setProfileImage] = useState(
    UserHandler.getInstance().getCurrentUser().profile_picture
  );

  let hasProfileImage =
    UserHandler.getInstance().getCurrentUser().profile_picture !== "";

  useEffect(() => {
    if (!loaded) {
      var unsubscribe = NetInfo.addEventListener((state) => {
        if (state.isConnected !== null) setNetworkState(state.isConnected);
        if (state.isConnected) {
          //NavigationHandler.getInstance().setLastUpdateTime();
        }
      });
      loaded = true;
    }
    NavigationHandler.getInstance().headerRefs.push(setNeedsRefresh);
    return () => {
      NavigationHandler.getInstance().headerRefs =
        NavigationHandler.getInstance().headerRefs.filter(
          (f) => setNeedsRefresh !== f
        );
      unsubscribe();
    };
  }, []);

  const navigation = useNavigation();
  let user_name = "";
  if (UserHandler.getInstance().getCurrentUser() != undefined) {
    user_name =
      UserHandler.getInstance().getCurrentUser().firstname +
      " " +
      UserHandler.getInstance().getCurrentUser().lastname;
  } else {
    navigation.navigate("FirstLandingScreen");
  }

  return (
    <View
      style={{
        position: "absolute",
        top: 0,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 1000,
        width: "100%",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        backgroundColor: props.isDark ? "#000" : "#fff",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.15,
        shadowRadius: 2,
        elevation: 2,
      }}
    >
      <TouchableOpacity
        activeOpacity={0.65}
        onPress={() => {
          NavigationHandler.getInstance().reset();
          NavigationHandler.getInstance().setShouldShowResult(false);
          if (props.issuer !== undefined)
            props.issuer.setState({
              currentLevel: 0,
              currentID: 0,
            });
          navigation.reset({
            index: 0,
            routes: [{ name: "SelectMedicalCategoryScreen" }],
          });
        }}
      >
        <Image
          source={props.isDark ? logo_dark : logo}
          style={{
            height: 50,
            width: 50,
          }}
        />
      </TouchableOpacity>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            if (props.isProfile) {
              navigation.goBack();
            } else {
              navigation.navigate("ProfileScreen");
            }
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View>
              <Text style={styles.user}>{user_name}</Text>
              <Text
                style={{
                  fontSize: 11,
                  textAlign: "right",
                  marginRight: 10,
                  color: networkState ? Colors.light_green : Colors.red,
                }}
              >
                {networkState ? "online" : "offline"}
              </Text>
            </View>
            <Image
              source={hasProfileImage ? { uri: profileImage } : profileIcon}
              style={[
                styles.profile_icon,
                hasProfileImage
                  ? { width: 38, height: 38, borderRadius: 19 }
                  : { width: 24, height: 38, tintColor: Colors.gold },
              ]}
            />
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          style={{ padding: 12 }}
          onPress={() => {
            if (menuIsOpen) {
              navigation.goBack();
            } else {
              navigation.navigate("MenuScreen");
            }
          }}
        >
          <Image
            source={menuIsOpen ? cross : burger}
            style={{
              tintColor: props.isDark ? "#fff" : "#000",
              width: 24,
              height: 16,
              resizeMode: "contain",
              marginLeft: 9,
            }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  user: {
    fontSize: 12,
    fontWeight: "500",
    lineHeight: 15,
    letterSpacing: 0.1,
    textAlign: "right",
    textTransform: "uppercase",
    color: Colors.gold,
    marginRight: 8,
  },
  profile_icon: {},
});
