import {
  Image,
  Keyboard,
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import uuid from "react-native-uuid";

import { Colors } from "../Constants/Colors";

import {
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  query,
  addDoc,
  serverTimestamp,
  Timestamp,
  updateDoc,
  where,
} from "@firebase/firestore";
import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import bubble from "../assets/images/bubble.png";
import cross from "../assets/images/cross.png";
import filter from "../assets/images/filter.png";
import like from "../assets/images/like.png";
import profile_image from "../assets/images/profile.png";
import trash from "../assets/images/trash.png";
import { db } from "../Components/db.js";
import { Sizes } from "../Constants/Sizes";
import NavigationHandler from "../Handlers/NavigationHandler";
import UserHandler from "../Handlers/UserHandler";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";

interface CommentProps {
  id: string;
}

interface CommentState {
  comments: Array<Comment>;
}

interface Comment {
  comment: string;
  author: string;
  likes: number;
  id: string;
  email: string;
  time: Timestamp;
  likedby: Array<string>;
  image: string;
}

interface NewCommentProps {
  main: Comments;
}

interface NewCommentState {
  value: string;
  height: string | number;
}

class NewComment extends React.Component<NewCommentProps, NewCommentState> {
  constructor(props: NewCommentProps) {
    super(props);
    this.state = { value: "", height: Platform.OS === "web" ? 15 : 21 };
  }
  saveComment(value: string) {
    const answer = async () => {
      const handler = UserHandler.getInstance().getCurrentUser();
      const ref = collection(db, "comments");
      const docRef = await addDoc(ref, {
        author: handler.firstname + " " + handler.lastname,
        comment: value,
        likes: 0,
        time: serverTimestamp(),
        parentID: null,
        resultID: NavigationHandler.getInstance().getCurrentResultID(),
        email: handler.email,
        likedby: [],
        image: UserHandler.getInstance().getCurrentUser().profile_picture,
      });
      var comment: Comment = {
        comment: value,
        author: handler.firstname + " " + handler.lastname,
        likes: 0,
        id: docRef.id,
        email: handler.email,
        time: Timestamp.now(),
        likedby: [],
        image: handler.profile_picture,
      };
      const responses = [...this.props.main.state.comments, comment];
      var state: CommentState = { comments: responses };
      this.props.main.setState(state);
    };
    if (value != "") {
      answer();
    }
  }

  render() {
    return (
      <View
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextInput
          style={{
            flex: 1,
            width: "100%",
            fontWeight: "400",
            fontSize: 12,
            minHeight: this.state.height,
          }}
          onChangeText={(text) => this.setState({ value: text })}
          value={this.state.value}
          onFocus={() => this.setState({ height: 68 })}
          onBlur={() => this.setState({ height: "auto" })}
          multiline={true}
          placeholder={"Neuen Kommentar verfassen"}
        />
        {this.state.value !== "" ? (
          <TouchableOpacity
            style={{ width: "90%", marginTop: 20, alignItems: "center" }}
            onPress={() => {
              this.saveComment(this.state.value);
              this.setState({ value: "" });
            }}
          >
            <Text style={{ fontSize: 15, color: Colors.gold }}>Abschicken</Text>
          </TouchableOpacity>
        ) : null}
      </View>
    );
  }
}

interface ResponseState {
  on: boolean;
  responses: Comment[];
  value: string;
}

class Response extends React.Component<CommentProps, ResponseState> {
  constructor(props: CommentProps) {
    super(props);
    this.state = { on: false, responses: [], value: "" };
  }

  saveResponse(value: string) {
    const answer = async () => {
      const handler = UserHandler.getInstance().getCurrentUser();
      const ref = collection(db, "comments");
      const docRef = await addDoc(ref, {
        author: handler.firstname + " " + handler.lastname,
        comment: value,
        likes: 0,
        time: serverTimestamp(),
        parentID: this.props.id,
        resultID: NavigationHandler.getInstance().getCurrentResultID(),
        email: handler.email,
        likedby: [],
      });

      var newResponse: Comment = {
        comment: value,
        author: handler.firstname + " " + handler.lastname,
        likes: 0,
        id: docRef.id,
        email: handler.email,
        time: Timestamp.now(),
        likedby: [],
        image: handler.profile_picture,
      };
      const responses = [...this.state.responses, newResponse];
      this.setState({ on: true, responses: responses });
    };
    if (value != "") {
      answer();
    }
  }

  update() {
    const comments = async () => {
      const q = query(
        collection(db, "comments"),
        where("parentID", "==", this.props.id)
      );
      const response = await getDocs(q);

      var comments: Comment[] = new Array<Comment>();
      response.forEach((element) => {
        var data = element.data();
        var comment: Comment = {
          comment: data.comment,
          author: data.author,
          likes: data.likes,
          id: element.id,
          email: data.email,
          time: data.time,
          likedby: data.likedby,
          image: data.image,
        };
        comments.push(comment);
      });
      comments.sort((a, b) => {
        if (a.time.seconds > b.time.seconds) {
          return 1;
        } else if (a.time.seconds < b.time.seconds) {
          return -1;
        } else {
          if (a.time.nanoseconds > b.time.nanoseconds) {
            return 1;
          } else if (a.time.nanoseconds < b.time.nanoseconds) {
            return -1;
          }
          return 0;
        }
      });
      //var state : ResponseState = {on:true ,responses: comments, value: this.state.value}
      this.setState({ on: true, responses: comments });
    };

    if (this.state.on == false) {
      comments();
    }
  }

  removeResponse(id: string) {
    this.setState({
      responses: this.state.responses.filter(function (response) {
        return response.id !== id;
      }),
    });
  }

  deleteResponse(id: string) {
    const deleteR = async () => {
      const ref = doc(db, "comments", id);
      const emailresponse = await getDoc(ref);
      const data = emailresponse.data();
      if (typeof data !== "undefined") {
        const email = data.email;
        if (email == UserHandler.getInstance().getCurrentUser().email) {
          await deleteDoc(doc(db, "comments", id));
          this.removeResponse(id);
        }
      }
    };

    deleteR();
  }

  updateLikes(id: string, email: string) {
    var array = [...this.state.responses];
    var index = array.findIndex((i) => i.id === id);
    if (index != -1) {
      array[index].likes += 1;
      array[index].likedby.push(email);
    }
    this.setState({ responses: array });
  }

  likeResponse(id: string) {
    var array = [...this.state.responses];
    var index = array.findIndex((i) => i.id === id);
    const like = async () => {
      const ref = doc(db, "comments", id);
      const response = await getDoc(ref);

      const data = response.data();
      if (typeof data !== "undefined") {
        updateDoc(doc(db, "comments", id), {
          likes: data.likes + 1,
          likedby: arrayUnion(UserHandler.getInstance().getCurrentUser().email),
        });
      } else {
      }
    };
    if (index != -1) {
      var position = array[index].likedby.indexOf(
        UserHandler.getInstance().getCurrentUser().email
      );

      if (position == -1) {
        like();
        this.updateLikes(id, UserHandler.getInstance().getCurrentUser().email);
      }
    }
  }

  render() {
    if (this.state.on == false) {
      return (
        <View
          onTouchStart={() => {
            this.update();
          }}
        >
          <Text style={[styles.likes_text, { fontSize: 10 }]}>
            Antworten anschauen &amp; verfassen
          </Text>
        </View>
      );
    } else {
      return (
        <View>
          {this.state.responses.map((c) => {
            const isSelf =
              c.email === UserHandler.getInstance().getCurrentUser().email;

            return (
              <View
                key={c.id}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 12,
                }}
              >
                <View style={{ flexDirection: "column", width: "90%" }}>
                  <Text style={[styles.profile_name, { marginRight: 5 }]}>
                    {c.author}
                  </Text>
                  <Text style={[styles.occupation_text]}>{c.comment}</Text>
                </View>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text style={[styles.likes_text, { marginRight: 4 }]}>
                    {c.likes}
                  </Text>
                  <TouchableOpacity
                    onPress={() =>
                      isSelf
                        ? this.deleteResponse(c.id)
                        : this.likeResponse(c.id)
                    }
                  >
                    <Image
                      source={isSelf ? trash : like}
                      style={{ width: 14, height: 14, resizeMode: "contain" }}
                    ></Image>
                  </TouchableOpacity>
                </View>
              </View>
            );
          })}

          <TextInput
            onChangeText={(text) => this.setState({ value: text })}
            value={this.state.value}
            style={styles.occupation_text}
            placeholder={"Antworten"}
            onSubmitEditing={({ nativeEvent }) => {
              this.saveResponse(nativeEvent.text);
              this.setState({ value: "" });
            }}
          />
        </View>
      );
    }
  }
}

class Comments extends React.Component<{}, CommentState> {
  constructor(props: CommentProps) {
    super(props);
    this.state = {
      comments: [],
    };
  }
  componentDidMount() {
    const comments = async () => {
      const q = query(
        collection(db, "comments"),
        where("parentID", "==", null),
        where(
          "resultID",
          "==",
          NavigationHandler.getInstance().getCurrentResultID()
        )
      );
      const response = await getDocs(q);

      var comments: Comment[] = new Array<Comment>();
      response.forEach((element) => {
        var data = element.data();
        var comment: Comment = {
          comment: data.comment,
          author: data.author,
          likes: data.likes,
          id: element.id,
          email: data.email,
          time: data.time,
          likedby: data.likedby,
          image: data.image,
        };

        comments.push(comment);
      });
      comments.sort((a, b) => {
        if (a.likes < b.likes) {
          return 1;
        }
        if (a.likes > b.likes) {
          return -1;
        }
        return 0;
      });
      if (comments.length === 0) {
        const welcomeComment: Comment = {
          comment:
            "Herzlichen Willkommen in der Kommentarfunktion!\nDiese bietet Raum für Feedback und einen fachlichen Austausch.\n\nMit besten Grüße,\nIhr MEDICEO - Team!",
          author: "MEDICEO®",
          likes: 0,
          id: uuid.v4().toString(),
          email: "info@medi.ceo",
          time: Timestamp.fromDate(new Date()),
          likedby: [],
          image:
            "https://firebasestorage.googleapis.com/v0/b/medify-826de.appspot.com/o/Mediceo_Logo_Circle_Black_optimised.png?alt=media&token=9f5e7287-8635-437c-be4e-2dce1b5af0ba",
        };
        comments.push(welcomeComment);
      }

      var state: CommentState = { comments: comments };
      this.setState(state);
    };
    comments();
  }

  removeComment(id: string) {
    this.setState({
      comments: this.state.comments.filter(function (comment) {
        return comment.id !== id;
      }),
    });
  }

  updateLikes(id: string, email: string) {
    var array = [...this.state.comments];
    var index = array.findIndex((i) => i.id === id);

    if (index != -1) {
      array[index].likes += 1;
      array[index].likedby.push(email);
    }

    this.setState({ comments: array });
  }

  likeComment(id: string) {
    var array = [...this.state.comments];
    var index = array.findIndex((i) => i.id === id);
    const like = async () => {
      const ref = doc(db, "comments", id);
      const response = await getDoc(ref);

      const data = response.data();
      if (typeof data !== "undefined") {
        updateDoc(doc(db, "comments", id), {
          likes: data.likes + 1,
          likedby: arrayUnion(UserHandler.getInstance().getCurrentUser().email),
        });
      } else {
      }
    };
    if (index != -1) {
      var position = array[index].likedby.indexOf(
        UserHandler.getInstance().getCurrentUser().email
      );

      if (position == -1) {
        like();
        this.updateLikes(id, UserHandler.getInstance().getCurrentUser().email);
      }
    }
  }

  deleteComment(id: string) {
    const deleteC = async () => {
      const ref = doc(db, "comments", id);
      const emailresponse = await getDoc(ref);
      const data = emailresponse.data();
      if (typeof data !== "undefined") {
        const email = data.email;
        if (email == UserHandler.getInstance().getCurrentUser().email) {
          const q = query(
            collection(db, "comments"),
            where("parentID", "==", id)
          );
          const response = await getDocs(q);
          response.forEach(async (element) => {
            await deleteDoc(doc(db, "comments", element.id));
          });
          await deleteDoc(doc(db, "comments", id));
          this.removeComment(id);
        }
      }
    };

    deleteC();
  }

  render() {
    return (
      <View style={{ width: "100%", alignItems: "center", height: "100%" }}>
        <View style={{ width: "100%", alignItems: "center", height: "100%" }}>
          <View style={{ flex: 1, marginHorizontal: 0, width: "100%" }}>
            {this.state.comments.map((c) => {
              const isSelf =
                c.email === UserHandler.getInstance().getCurrentUser().email;
              return (
                <View
                  key={c.id}
                  style={[
                    styles.container_two,
                    { flexDirection: "column", alignItems: "flex-start" },
                  ]}
                >
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Image
                      style={{
                        width: 50,
                        height: 50,
                        resizeMode: "contain",
                        borderRadius: 25,
                      }}
                      source={
                        c.image === "" || c.image === undefined
                          ? profile_image
                          : { uri: c.image }
                      }
                    />
                    <View style={{ marginLeft: 16, flex: 1 }}>
                      <Text style={styles.profile_name}>{c.author}</Text>
                      <Text style={styles.occupation_text}>{c.comment}</Text>
                      <View
                        style={{
                          marginTop: 12,
                          paddingRight: 16,
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={styles.likes_text}>
                          {c.time.toDate().toLocaleDateString()} {c.likes}{" "}
                          zugestimmt
                        </Text>
                        <TouchableOpacity
                          onPress={() =>
                            isSelf
                              ? this.deleteComment(c.id)
                              : this.likeComment(c.id)
                          }
                        >
                          <Image
                            source={isSelf ? trash : like}
                            style={{
                              width: 18,
                              height: 18,
                              resizeMode: "contain",
                            }}
                          ></Image>
                        </TouchableOpacity>
                      </View>
                      <View />
                    </View>
                  </View>
                  <View
                    style={{
                      paddingTop: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                    }}
                  >
                    <Response id={c.id} />
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </View>
    );
  }
}

export function CommentsScreen() {
  const [keyboardOffset, setKeyboardOffset] = useState(0);
  const onKeyboardShow = (event) =>
    setKeyboardOffset(event.endCoordinates.height - 20);
  const onKeyboardHide = () => setKeyboardOffset(0);
  const keyboardDidShowListener = useRef();
  const keyboardDidHideListener = useRef();

  useEffect(() => {
    keyboardDidShowListener.current = Keyboard.addListener(
      "keyboardWillShow",
      onKeyboardShow
    );
    keyboardDidHideListener.current = Keyboard.addListener(
      "keyboardWillHide",
      onKeyboardHide
    );

    return () => {
      keyboardDidShowListener.current.remove();
      keyboardDidHideListener.current.remove();
    };
  }, []);

  let [commentsRef, setCommentsRef] = useState(null);

  return (
    <SafeAreaView
      onTouchStart={() =>
        Platform.select({ ios: Keyboard.dismiss, android: Keyboard.dismiss })
      }
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Header />

        <View style={{ width: "100%", flex: 1, alignItems: "center" }}>
          <View
            style={{
              marginTop: 20,
              alignItems: "flex-start",
              width: "90%",
              maxWidth: Sizes.containerWidth,
              flex: 1,
              marginBottom: 100,
            }}
          >
            <KeyboardAwareScrollView
              style={{ width: "100%", overflow: "visible" }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Text style={styles.text}>Bisherige Kommentare</Text>
                <Image source={filter} style={{ width: 22, height: 11 }} />
              </View>
              <Comments ref={(t) => setCommentsRef(t)} />
            </KeyboardAwareScrollView>
          </View>
          <View
            style={{
              backgroundColor: "#ffffff",
              zIndex: 5,
              width: "100%",
              position: "absolute",
              bottom: -40 + keyboardOffset,
              alignItems: "center",
              height: 100,
              maxWidth: Sizes.containerWidth,
            }}
          >
            <View
              style={[styles.container, { position: "absolute", bottom: 40 }]}
            >
              <NewComment main={commentsRef} />
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}

function Header() {
  const navigation = useNavigation();

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 1000,
        width: "100%",
        paddingLeft: 20,
        height: 80,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        backgroundColor: "#fff",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.15,
        shadowRadius: 2,
        elevation: 2,
      }}
    >
      <View
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Image
          source={bubble}
          style={{
            width: 32,
            height: 32,
            marginRight: 16,
            tintColor: Colors.gold,
          }}
        />
        <Text style={styles.title}>KOMMENTARE</Text>
        <TouchableOpacity
          style={{ position: "absolute", right: 5, top: 5 }}
          onPress={() => {
            navigation.goBack();
          }}
        >
          <Image
            source={cross}
            style={{ width: 20, height: 20, tintColor: Colors.gold }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    opacity: 0.5,
  },
  title: {
    fontWeight: "700",
    fontSize: 16,
    color: "#000",
  },
  user: {
    fontSize: 12,
    fontWeight: "500",
    lineHeight: 15,
    letterSpacing: 0.1,
    textAlign: "right",
    textTransform: "uppercase",
    color: Colors.gold,
    marginRight: 8,
  },
  profile_icon: {
    height: 24,
    width: 24,
    tintColor: Colors.gold,
  },
  container_two: {
    marginTop: 20,
    borderRadius: 10,
    padding: 12,
    paddingTop: 20,
    paddingBottom: 20,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    backgroundColor: Colors.background,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  profile_name: {
    fontSize: 12,
    fontWeight: "700",
    opacity: 0.5,
  },
  occupation_text: {
    fontSize: 12,
    fontWeight: "400",
    opacity: 0.5,
  },
  likes_text: {
    fontSize: 10,
    fontWeight: "700",
    opacity: 0.5,
  },
  container: {
    marginTop: 30,
    marginBottom: 30,
    borderRadius: 20,
    padding: 12,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    backgroundColor: Colors.background,
    width: "90%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
