import { setDoc, doc, getDoc } from "@firebase/firestore";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as sha512 from "js-sha512";
import uuid from "react-native-uuid";
import { db } from "../Components/db";
import { EncryptionHandler } from "../Handlers/EncryptionHandler";
import { AnalyticsHandler } from "./AnalyticsHandler";
import { showErrorMessage } from "./ErrorHandler/ErrorHandler";

async function isLogged() {
  const l = await AsyncStorage.getItem("isLogged");
  if (l !== null) {
    if (l == "true") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export interface NotesInterface {
  id: string;
  note: string;
  showInResult: boolean;
}

export interface User {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  clinic: string;
  promocode: string;
  uuid: string;
  semester?: string;
  email_verified: number;
  bookmarks: string[];
  notes: NotesInterface[];
  description: string;
  receipt: string;
  profile_picture?: string;
  last_update_time?: string;
  free_use_until: Date;
  role: string;
  paymentPlan: { plan: string; categories: string[] };
  user_specific_code: string;
  has_payment: number;
  has_rated: boolean;
  used_specific_codes: number;
  used_codes_array: string[];
}

export default class UserHandler {
  static instance: UserHandler;
  _logged = false;

  _isPayingUser = false; // IMPORTANT CHANGE WHEN LIVE

  _setClinic;

  currentUser: User;
  role = "";

  /**
   * @returns {UserHandler}
   */
  static getInstance() {
    if (this.instance == null) {
      this.instance = new UserHandler();
    }

    return this.instance;
  }

  sessionId: string | undefined = undefined;

  getSessionId() {
    if (this.sessionId === undefined) {
      this.sessionId = uuid.v4().toString();
    }
    return this.sessionId;
  }

  getCurrentUser() {
    return this.currentUser;
  }

  setCurrentUser(user: User) {
    this.currentUser = user;
    if (this.currentUser.bookmarks === undefined) {
      this.currentUser.bookmarks = [];
    }

    if (this.currentUser.notes === undefined) {
      this.currentUser.notes = [];
    }
    if (
      this.currentUser.description === undefined ||
      this.currentUser.description === ""
    ) {
      this.currentUser.description = "Bitte einfügen";
    }
    if (this.currentUser.profile_picture === undefined) {
      this.currentUser.profile_picture = "";
    }
  }

  update() {
    try {
      const docRef = doc(db, "users", this.currentUser.email);
      setDoc(docRef, this.currentUser);
      AnalyticsHandler.getInstance().logUpdateProfile();
      this.updateOfflineUser();
    } catch {
      // showErrorMessage({
      //   title: "Etwas hat nicht geklappt",
      //   text: "Versuchen Sie es erneut. Sollte der Fehler bestehen, kontaktieren Sie uns unter support@medi.ceo.",
      // });
    }
  }

  async checkPromocode(code: string) {
    try {
      const user = EncryptionHandler.getInstance().getEncryptedUser(
        this.currentUser.email
      );

      const response = await fetch("https://api.medi.ceo/apply_code", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          authenticator: EncryptionHandler.getInstance().getAuth(),
          user: user,
          code: code,
        }),
      });

      if (response.status !== 200) {
        return { status: false };
      }

      let data = await response.json();

      if (data["main user"] !== undefined) {
        return data["main user"];
        //TODO ADD MESSAGE HANDLING FOR OTHER USER
      } else {
        return data;
      }
    } catch {
      return { status: false };
    }
  }

  createUuid() {
    return uuid.v4().toString();
  }

  async register(
    email: string,
    firstname: string,
    lastname: string,
    password: string,
    clinic: string,
    semester: string | undefined
  ) {
    var uuid = this.createUuid();
    var hashed_password = sha512.sha512(password);
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    try {
      const docRef = doc(db, "users", email.toLowerCase());
      await setDoc(docRef, {
        uuid: uuid,
        email: email.toLowerCase(),
        firstname: firstname,
        lastname: lastname,
        clinic: clinic,
        has_rated: false,
        promocode: "",
        has_payment: 0,
        used_specific_codes: 0,
        user_specific_code: "",
        password: hashed_password,
        used_codes_array: [],
        semester: semester,
        email_verified: 0,
        free_use_until: yesterday,
        role: UserHandler.getInstance().role,
        description: "",
      });

      const user: User = {
        firstname: firstname,
        lastname: lastname,
        email: email.toLowerCase(),
        clinic: clinic,
        password: hashed_password,
        uuid: uuid,
        receipt: "",
        paymentPlan: { plan: "", categories: [] },
        has_payment: 0,
        has_rated: false,
        used_specific_codes: 0,
        user_specific_code: "",
        used_codes_array: [],
        promocode: "",
        semester: semester,
        bookmarks: [],
        email_verified: 0,
        free_use_until: yesterday,
        notes: [],
        description: "",
        role: UserHandler.getInstance().role,
      };

      UserHandler.getInstance().setCurrentUser(user);
      await this.createUserCode(email);
      this.updateOfflineUser();
      return await this.sendVerificationMail(email);
    } catch {
      console.log("wrong");
      return false;
    }
  }

  async createUserCode(email: string) {
    try {
      const mail = EncryptionHandler.getInstance().getEncryptedUser(
        email.toLowerCase()
      );

      let response = await fetch(
        "https://server.medi.ceo/create_user_code?y=" +
          mail +
          "&x=" +
          EncryptionHandler.getInstance().getAuth()
      );

      if (response.status !== 200) {
        return false;
      }

      let data = await response.json();
      this.currentUser.user_specific_code = data.code;
      return true;
    } catch {
      return false;
    }
  }

  async sendVerificationMail(email: string) {
    try {
      const mail = EncryptionHandler.getInstance().getEncryptedUser(
        email.toLowerCase()
      );

      const response = await fetch(
        "https://server.medi.ceo/send_verify?y=" +
          mail +
          "&x=" +
          EncryptionHandler.getInstance().getAuth()
      );

      if (response.status !== 200) {
        return false;
      }

      return true;
    } catch {
      return false;
    }
  }

  async sendForgotPwMail(mail: string) {
    const auth = EncryptionHandler.getInstance().getAuth();
    const user = EncryptionHandler.getInstance().getEncryptedUser(mail);

    const req = "https://server.medi.ceo/sendmail?y=" + user + "&x=" + auth;
    try {
      let response = await fetch(req);
      if (response.status !== 200) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  }

  async sendChangedPwdInAppMail(mail: string, pw: string) {
    var hashed_password = sha512.sha512(pw);
    const docRef = doc(db, "users", this.currentUser.email);
    try {
      setDoc(
        docRef,
        {
          password: hashed_password,
        },
        { merge: true }
      );
      this.currentUser.password = hashed_password;
      AnalyticsHandler.getInstance().logUpdateProfile();
    } catch {
      return false;
    }

    const auth = EncryptionHandler.getInstance().getAuth();
    const user = EncryptionHandler.getInstance().getEncryptedUser(mail);

    const req =
      "https://server.medi.ceo/password_reset_app?y=" + user + "&x=" + auth;
    try {
      let response = await fetch(req);
      if (response.status !== 200) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  }

  async deleteProfileImage() {
    try {
      const docRef = doc(db, "users", this.currentUser.email);
      setDoc(
        docRef,
        {
          profile_picture: "",
        },
        { merge: true }
      );
      UserHandler.getInstance().getCurrentUser().profile_picture = "";
      AnalyticsHandler.getInstance().logUpdateProfile();
      return true;
      // TODO: DELETE FILE FROM STORAGE
    } catch {
      return false;
    }
  }

  async login(user: string, password: string) {
    var hashed_password = sha512.sha512(password);
    console.log("db", db);
    try {
      const docRef = doc(db, "users", user.toLowerCase());
      const user_ref = await getDoc(docRef);

      // const user_ref = await db
      //   .collection("users")
      //   .doc(user.toLowerCase())
      //   .get();
      const user_data = user_ref.data();

      console.log("date", user_data.free_use_until);
      if (user_data === undefined) {
        return false;
      }

      if (user_data["password"] == hashed_password) {
        UserHandler.getInstance().setCurrentUser(user_data);
        AnalyticsHandler.getInstance().createSession(user_data.uuid, {
          name: user_data.name,
          email: user_data.email,
          role: user_data.role,
        });
        AnalyticsHandler.getInstance().logLogin();
        this.updateOfflineUser();
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  updateNote(note: string, id: string, shouldShow: boolean) {
    this.currentUser.notes = this.currentUser.notes.filter(
      (note) => note.id != id
    );
    const n: NotesInterface = {
      id: id,
      note: note,
      showInResult: shouldShow,
    };
    this.currentUser.notes.push(n);
    const docRef = doc(db, "users", this.currentUser.email);
    setDoc(
      docRef,
      {
        notes: this.currentUser.notes,
      },
      { merge: true }
    );
    this.updateOfflineUser();
  }

  async getLogged() {
    const l: boolean = await isLogged();
    this._logged = l;

    return this._logged;
  }

  setLogged(logged: boolean) {
    this._logged = logged;
    AsyncStorage.setItem("isLogged", logged.toString());
    if (logged && this.currentUser != undefined) {
      AsyncStorage.setItem("email", this.currentUser.email);
      AsyncStorage.setItem("password", this.currentUser.password);
    }
  }

  isPayingUser() {
    let freeUse = this.getCurrentUser().free_use_until;

    let date = new Date(freeUse.seconds * 1000 + freeUse.nanoseconds / 1000000);
    if (date > new Date()) {
      return true;
    }
    return false;
  }

  updateOfflineUser() {
    if (this.currentUser === undefined) return;
    AsyncStorage.setItem("user", JSON.stringify(this.currentUser));
  }

  async getOfflineUser() {
    let u = await AsyncStorage.getItem("user");

    if (u === null) return;

    this.currentUser = JSON.parse(u);
  }

  setPromoCode(code: string) {
    const docRef = doc(db, "users", this.currentUser.email);
    setDoc(
      docRef,
      {
        promocode: code,
      },
      { merge: true }
    );
  }

  addBookmark(id: string) {
    this.currentUser.bookmarks.push(id);
    this.updateBookmarks();
  }

  removeBookmark(id: string) {
    this.currentUser.bookmarks.splice(
      this.currentUser.bookmarks.indexOf(id),
      1
    );
    this.updateBookmarks();
  }

  private updateBookmarks() {
    const docRef = doc(db, "users", this.currentUser.email);
    setDoc(
      docRef,
      {
        bookmarks: this.currentUser.bookmarks,
      },
      { merge: true }
    );
    this.updateOfflineUser();
  }

  logout() {
    this.setLogged(false);
    this.currentUser = undefined;
  }
}
