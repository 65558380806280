import { useEffect } from "react";
import {
  Image,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { NavigationProps } from "../App";
import logo from "../assets/images/logo.png";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";

export function FirstLandingScreen({ navigation }: NavigationProps) {
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}>
        <Image
          source={logo}
          style={{
            width: "100%",
            resizeMode: "contain",
            ...Platform.select({ web: { height: "45vh" } }),
          }}
        />
      </View>
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <View style={{ justifyContent: "center", width: "80%", maxWidth: 700 }}>
          <TouchableOpacity
            style={[
              styles.loginScreenButton,
              {
                backgroundColor: Colors.gold,
                borderColor: Colors.gold,
                maxWidth: Sizes.containerWidth,
              },
            ]}
            onPress={() => {
              navigation.navigate("RoleSelectionScreen");
            }}
          >
            <Text
              style={[
                styles.loginText,
                {
                  color: Colors.white,
                },
              ]}
            >
              {"REGISTRIEREN"}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.loginScreenButton,
              {
                backgroundColor: Colors.white,
                borderColor: Colors.gold,
                maxWidth: Sizes.containerWidth,
              },
            ]}
            onPress={() => {
              navigation.navigate("LoginScreen");
            }}
          >
            <Text
              style={[
                styles.loginText,
                {
                  color: Colors.gold,
                },
              ]}
            >
              {"ICH HABE SCHON EINEN ACCOUNT"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  loginScreenButton: {
    marginTop: 10,
    marginBottom: 10,
    flex: 1,
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 50,
    minHeight: 53,
    borderWidth: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  loginText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,

    paddingLeft: 16,
    paddingRight: 16,
  },
});
