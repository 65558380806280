import * as InAppPurchases from "expo-in-app-purchases";
import { Platform } from "react-native";
import { AnalyticsHandler } from "./AnalyticsHandler";
import { showErrorMessage } from "./ErrorHandler/ErrorHandler";
import UserHandler from "./UserHandler";

export class PaymentHandler {
  static instance: PaymentHandler;

  /**
   * @returns {PaymentHandler}
   */
  static getInstance() {
    if (this.instance == null) {
      this.instance = new PaymentHandler();
    }
    return this.instance;
  }

  isConnected = false;
  purchases: InAppPurchases.IAPItemDetails[] | undefined = [];

  isWeb = false;

  constructor() {
    if (Platform.OS === "web") {
      this.isWeb = true;
      return;
    }
    InAppPurchases.connectAsync().then((res) => {
      InAppPurchases.getProductsAsync([
        "doc.mediceo.year",
        "doc.mediceo.month",
        "student.mediceo.year",
        "student.mediceo.month",
        "one.mediceo.custom",
        "two.mediceo.custom",
        "three.mediceo.custom",
      ]).then(({ responseCode, results }) => {
        if (responseCode === InAppPurchases.IAPResponseCode.OK) {
          this.purchases = results;
        }
      });
      this.isConnected = true;
    });
  }

  async makePayment(id: string, finished: (success: boolean) => void) {
    AnalyticsHandler.getInstance().logScreenEvent(
      "Payment Flow",
      "started",
      id
    );

    if (!this.isConnected) {
      finished(false);
      return false;
    }

    InAppPurchases.setPurchaseListener(
      ({ responseCode, results, errorCode }) => {
        if (results === undefined) {
          finished(false);
          return;
        }

        if (results!.length === 0) {
          finished(false);
          return;
        }

        if (
          responseCode === InAppPurchases.IAPResponseCode.OK &&
          results[0].transactionReceipt !== undefined
        ) {
          UserHandler.getInstance().getCurrentUser()!.receipt =
            results[0].transactionReceipt;
          UserHandler.getInstance().getCurrentUser().has_payment = 1;
          UserHandler.getInstance().update();
          UserHandler.getInstance().updateOfflineUser();
          UserHandler.getInstance()._isPayingUser = true;
          InAppPurchases.finishTransactionAsync(results![0], true);
          AnalyticsHandler.getInstance().logPaymentDone(
            id,
            this.getPaymentToId(id),
            results[0].transactionReceipt
          );
          finished(true);
        } else {
          InAppPurchases.finishTransactionAsync(results![0], true);
          finished(false);
        }
      }
    );

    InAppPurchases.purchaseItemAsync(id)
      .then((res) => {})
      .catch((err) => {
        finished(false);
      });
  }

  getPaymentToId(id: string) {
    if (id.includes("doc.mediceo.year")) return 119.99;
    if (id.includes("student.mediceo.year")) return 49.99;
    if (id.includes("doc.mediceo.month")) return 12.99;
    if (id.includes("student.mediceo.month")) return 4.99;
    if (id.includes("one.mediceo.custom")) return 8.99;
    if (id.includes("two.mediceo.custom")) return 10.99;
    if (id.includes("three.mediceo.custom")) return 12.99;
  }

  async verifyPayment(finished: (success: boolean) => void) {
    if (this.isWeb) {
      if (UserHandler.getInstance().getCurrentUser().has_payment === 1) {
        UserHandler.getInstance()._isPayingUser = true;
        finished(true);
        return;
      }
      finished(false);
      return;
    }
    if (!this.isConnected) {
      var c = 0;
      let t = setInterval(() => {
        c *= 1;
        if (this.isConnected) {
          clearTimeout(t);
          this._val()
            .then((succ) => finished(succ))
            .catch((err) => finished(false));
        } else {
          if (c === 25) {
            clearTimeout(t);
            finished(false);
          }
        }
      }, 50);
    } else {
      this._val()
        .then((succ) => finished(succ))
        .catch((err) => {
          showErrorMessage({
            title: "Verbindungsfehler",
            text: "Die Verbindung zum App Store ist fehlgeschlagen. Sollte der Fehler weiterhin bestehen, kontaktieren Sie uns unter support@medi.ceo",
          });
          finished(false);
        });
    }
  }

  async _val() {
    if (UserHandler.getInstance().getCurrentUser().has_payment === 1) {
      UserHandler.getInstance()._isPayingUser = true;
      this.validatePayment();
      return true;
    }
    return await this.validatePayment();
  }

  async validatePaymentWithKnownReceipt(receipt: string) {
    const prodURL = "https://buy.itunes.apple.com/verifyReceipt";
    const stagingURL = "https://sandbox.itunes.apple.com/verifyReceipt";
    const appSecret = "731d4db4bf774789b0f64554be440cb6";

    const payload = {
      "receipt-data": receipt,
      password: appSecret,
      "exclude-old-transactions": true,
    };

    // First, try to validate against production
    try {
      const prodRes = await fetch(prodURL, {
        method: "POST",
        body: JSON.stringify(payload),
      });
      const data = await prodRes.json();

      //If status is 21007, fall back to sandbox
      if (data && data.status === 21007) {
        const sandboxRes = await fetch(stagingURL, {
          method: "POST",
          body: JSON.stringify(payload),
        });
        const data = await sandboxRes.json();
        if (data?.latest_receipt_info[0]) {
          return data;
        }
      }

      // Otherwise, return the prod data!

      if (data?.latest_receipt_info[0]) {
        return data;
      }

      return null;
    } catch {
      return null;
    }
  }

  async validatePayment() {
    console.log("validate payment called");

    let receipt = UserHandler.getInstance().getCurrentUser().receipt;
    if (receipt === undefined) {
      return false;
    }
    if (Platform.OS === "android") return true;
    let data = await this.validatePaymentWithKnownReceipt(receipt);
    if (data === null) return false;
    return _continue(data);
  }

  async restorePurchases(finished: (success: boolean) => void) {
    if (!this.isConnected) {
      var c = 0;
      let t = setInterval(() => {
        c *= 1;
        if (this.isConnected) {
          clearTimeout(t);
          this._valHistory()
            .then((succ) => finished(succ))
            .catch((err) => finished(false));
        } else {
          if (c === 25) {
            clearTimeout(t);
            finished(false);
          }
        }
      }, 50);
    } else {
      this._valHistory()
        .then((succ) => finished(succ))
        .catch((err) => {
          showErrorMessage({
            title: "Verbindungsfehler",
            text: "Die Verbindung zum App Store ist fehlgeschlagen. Sollte der Fehler weiterhin bestehen, kontaktieren Sie uns unter support@medi.ceo",
          });
          finished(false);
        });
    }
  }

  async _valHistory() {
    console.log("test");
    try {
      const response = await InAppPurchases.getPurchaseHistoryAsync();
      let results = response.results;

      if (results === undefined) return false;
      var array: {
        status: boolean;
        date: Date;
        receipt: string;
        id: string;
      }[] = [];
      for (const res of results) {
        let data = await this.validatePaymentWithKnownReceipt(
          res.transactionReceipt!
        );
        if (data === null) continue;
        let date = data.latest_receipt_info[0];

        let dateArray = date.expires_date.split(" ");
        let d = new Date(dateArray[0] + " " + dateArray[1]);

        if (d > new Date()) {
          array.push({
            status: true,
            date: date,
            receipt: res.transactionReceipt!,
            id: res.productId,
          });
        }
        array.push({
          status: false,
          date: date,
          receipt: res.transactionReceipt!,
          id: res.productId,
        });
      }

      array = array.filter((f) => {
        console.log(f.status, "status is");
        return f.status;
      });
      if (array.length === 0) return false;
      let valid = array[0];
      UserHandler.getInstance().getCurrentUser().free_use_until = valid.date;
      UserHandler.getInstance().getCurrentUser().has_payment = 1;
      UserHandler.getInstance().update();
      UserHandler.getInstance().updateOfflineUser();
      return true;
    } catch {
      return false;
    }
  }
}

function _continue(data: {}) {
  let date = data.latest_receipt_info[0];

  let dateArray = date.expires_date.split(" ");
  let dateString = dateArray[0] + " " + dateArray[1];

  UserHandler.getInstance().getCurrentUser().free_use_until = new Date(
    dateString
  );

  if (UserHandler.getInstance().isPayingUser()) {
    UserHandler.getInstance().getCurrentUser().has_payment = 1;
    UserHandler.getInstance().update();
    UserHandler.getInstance().updateOfflineUser();
    return true;
  } else {
    UserHandler.getInstance().getCurrentUser().has_payment = 0;
    UserHandler.getInstance().update();
    UserHandler.getInstance().updateOfflineUser();
    return false;
  }
}
