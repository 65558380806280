import {
  Image,
  Keyboard,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { NavigationProps } from "../App";
import logo from "../assets/images/logo.png";
import { CustomButton } from "../Components/CustomButton";
import { Colors } from "../Constants/Colors";

import Ionicons from "@expo/vector-icons/Ionicons";
import { useState } from "react";
import { Sizes } from "../Constants/Sizes";
import { PaymentHandler } from "../Handlers/PaymentHandler";
import UserHandler from "../Handlers/UserHandler";

import { showErrorMessage } from "../Handlers/ErrorHandler/ErrorHandler";

export function LoginScreen({ navigation }: NavigationProps) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [pwVisible, setPwVisible] = useState(true);
  const [isWaiting, setIsWaiting] = useState(false);
  const isReady = () => {
    if (validateEmail(email) && password.length > 5) return true;
    return false;
  };

  return (
    <TouchableWithoutFeedback
      style={{ backgroundColor: "#fff" }}
      onPress={() =>
        Platform.select({ ios: Keyboard.dismiss, android: Keyboard.dismiss })
      }
      accessible={false}
    >
      <SafeAreaView
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "flex-start",
          paddingTop: 20,
          backgroundColor: "#fff",
        }}
      >
        <KeyboardAwareScrollView style={{ width: "100%" }}>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Image
              source={logo}
              style={{
                width: "90%",
                height: 280,
                resizeMode: "contain",
                ...Platform.select({
                  web: { height: "20vh", marginBottom: 50 },
                }),
              }}
            />

            <Text style={styles.welcomeText}>WILLKOMMEN ZURÜCK</Text>
            <Text style={styles.greetingText}>
              Jetzt einloggen und loslegen.
            </Text>

            <TextInput
              textContentType={"username"}
              placeholder={"E-Mail"}
              style={[
                styles.textField,
                validateEmail(email)
                  ? null
                  : { borderColor: "red", borderWidth: 1 },
              ]}
              keyboardType="email-address"
              onChangeText={(text) => {
                setEmail(text);
              }}
            />
            <View style={{ width: "85%", maxWidth: Sizes.containerWidth }}>
              <TextInput
                placeholder="Passwort"
                textContentType={"password"}
                style={[
                  styles.textField,
                  { width: "100%" },
                  password.length < 8
                    ? {
                        borderWidth: 1,
                        borderColor: "red",
                      }
                    : null,
                ]}
                secureTextEntry={pwVisible}
                onChangeText={(text) => {
                  setPassword(text);
                }}
              />
              <TouchableOpacity
                style={{ position: "absolute", right: 20, top: 11 }}
                onPress={() => setPwVisible(!pwVisible)}
              >
                <Ionicons
                  name={pwVisible ? "eye-outline" : "eye-off-outline"}
                  size={24}
                  color={Colors.black_opacity}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                width: "85%",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <CustomButton
                backgroundColor={"#fff"}
                foregroundColor={isWaiting ? Colors.black_opacity : Colors.gold}
                text="ANMELDEN"
                enabled={!isReady() || isWaiting}
                action={async () => {
                  setIsWaiting(true);
                  const valid = await UserHandler.getInstance().login(
                    email.trim(),
                    password
                  );

                  if (valid == true) {
                    if (
                      UserHandler.getInstance().getCurrentUser()
                        .email_verified === 0
                    ) {
                      navigation.navigate("WaitingForRegistrationScreen");
                    } else {
                      if (UserHandler.getInstance().isPayingUser()) {
                        UserHandler.getInstance()._isPayingUser = true;

                        navigation.navigate("SelectMedicalCategoryScreen");
                      } else {
                        await PaymentHandler.getInstance().verifyPayment(
                          function f(success) {
                            if (success) {
                              var target = "SelectMedicalCategoryScreen";
                            } else {
                              var target = "PaymentScreen";
                            }

                            navigation.navigate(target);
                          }
                        );
                      }
                    }
                  } else {
                    setIsWaiting(false);
                    showErrorMessage({
                      title: "Anmeldung fehlgeschlagen!",
                      text: "Versuchen Sie es erneut. Wenn weiterhin Fehler auftreten, kontaktieren Sie uns unter support@medi.ceo",
                    });
                  }
                }}
                borderColor={Colors.gold}
              />
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("ForgotPasswordScreen");
                  }}
                >
                  <Text style={styles.actionText}>Passwort vergessen?</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("RoleSelectionScreen");
                  }}
                >
                  <Text style={styles.actionText}>Jetzt registrieren?</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
}

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const styles = StyleSheet.create({
  welcomeText: {
    fontSize: 20,
    fontWeight: "700",
  },
  greetingText: {
    marginTop: 3,
    fontSize: 14,
    fontWeight: "700",
    color: "#C4C4C4",
    marginBottom: 42,
  },
  textField: {
    backgroundColor: "#fafafa",
    shadowColor: "#000",
    shadowOpacity: 0.12,
    borderRadius: 80,
    width: "85%",
    height: 50,
    padding: 12,
    elevation: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    marginBottom: 12,
    maxWidth: Sizes.containerWidth,
  },
  box: {
    backgroundColor: "#fff",
    borderRadius: 5,
    width: 126,
    height: 43,
    borderColor: "#c4c4c433",
    borderWidth: 1,
  },
  actionText: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 12,
    fontWeight: "700",
    opacity: 0.5,
    marginBottom: 20,
    marginTop: 12,
  },
});
