import { StackActions } from "@react-navigation/native";
import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import {
  Image,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { NavigationProps } from "../App";
import logo_weiß from "../assets/images/logo_weiß.png";
import { CustomButton } from "../Components/CustomButton";
import { db } from "../Components/db";
import { Colors } from "../Constants/Colors";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";
import { showErrorMessage } from "../Handlers/ErrorHandler/ErrorHandler";
import NavigationHandler from "../Handlers/NavigationHandler";
import UserHandler from "../Handlers/UserHandler";

export function WaitingForRegistrationScreen({ navigation }: NavigationProps) {
  const [sendText, setSendText] = useState("E-Mail erneut senden");
  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000",
        paddingTop: 20,
      }}
    >
      <Image
        source={logo_weiß}
        style={{
          height: 350,
          width: "90%",
          resizeMode: "contain",
          ...Platform.select({ web: { height: "50vh" } }),
        }}
      />
      <Text style={styles.thankYouText}>VIELEN DANK</Text>
      <Text style={styles.registryText}>FÜR DIE REGISTRIERUNG</Text>
      <Text style={styles.confirmText}>
        {UserHandler.getInstance().getCurrentUser().role === "doc"
          ? "Sie erhalten in Kürze eine E-Mail zur Bestätigung Ihrer Registrierung. Achtung: Checken Sie auch Ihren Spam-Ordner."
          : "Du erhälst in Kürze eine E-Mail zur Bestätigung Deiner Registrierung. Achtung: Checke auch Deinen Spam-Ordner."}
      </Text>
      <View style={styles.buttonContainer}>
        <CustomButton
          backgroundColor={Colors.gold}
          foregroundColor={Colors.white}
          borderColor={Colors.gold}
          text="VERIFIZIERUNG CHECKEN"
          action={async () => {
            const docRef = doc(
              db,
              "users",
              UserHandler.getInstance().getCurrentUser().email.toLowerCase()
            );
            const user_ref = await getDoc(docRef);
            const user_data = user_ref.data();
            if (user_data === undefined) {
              return;
            }
            if (user_data["email_verified"] === 1) {
              UserHandler.getInstance().currentUser.email_verified = 1;
              NavigationHandler.getInstance().firstRoute = true;
              AnalyticsHandler.getInstance().logSignUp();
              navigation.navigate("SelectMedicalCategoryScreen");
            }
          }}
        />
        <Text
          style={{
            color: "#fff",
            fontSize: 12,
            marginTop: 8,
            textAlign: "center",
          }}
        >
          Hiermit bestätigen Sie auch die
          <Text
            onPress={() => navigation.navigate("PreLoginDatenschutz")}
            style={{ color: Colors.gold, fontSize: 12 }}
          >
            {" "}
            Datenschutzerklärung
          </Text>{" "}
          und die{" "}
          <Text
            onPress={() => navigation.navigate("PreLoginPrivacyPolicy")}
            style={{ color: Colors.gold, fontSize: 12 }}
          >
            Privacy Policy
          </Text>{" "}
          von MEDICEO®.
        </Text>
        <TouchableOpacity
          onPress={async () => {
            let success = await UserHandler.getInstance().sendVerificationMail(
              UserHandler.getInstance().getCurrentUser().email
            );
            if (success) {
              setSendText("E-Mail versendet");
            } else {
              showErrorMessage({
                title: "Senden fehlgeschlagen!",
                text: "Das senden der E-Mail ist fehlgeschlagen. Bitte probieren Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie support@medi.ceo.",
              });
            }
          }}
        >
          <Text style={{ color: "white", fontSize: 12, marginTop: 30 }}>
            {sendText}
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => {
            UserHandler.getInstance().logout();
            navigation.dispatch(StackActions.popToTop());
            navigation.navigate("FirstLandingScreen");
          }}
          style={{ padding: 12, marginTop: 12 }}
        >
          <Text
            style={{
              color: "white",
              opacity: 0.5,
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            Abmelden
          </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  thankYouText: {
    fontSize: 36,
    color: "#fff",
    fontWeight: "700",
    letterSpacing: 0.05,
  },
  registryText: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "700",
  },
  confirmText: {
    fontSize: 12,
    color: "#fff",
    fontWeight: "700",
    marginTop: 38,
    textAlign: "center",
    paddingLeft: 40,
    paddingRight: 40,
  },
  buttonContainer: {
    width: "85%",
    alignItems: "center",
    marginTop: 58,
    marginBottom: 78,
  },
});
