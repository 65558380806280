import { useEffect, useRef, useState } from "react";
import {
  Image,
  Linking,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { ActivityIndicator, DefaultTheme } from "react-native-paper";
import { WebView } from "react-native-webview";
import cross from "../assets/images/cross.png";
import { Colors } from "../Constants/Colors";
import { navigationRef } from "../Handlers/RootNavigation";

export function WebBrowserScreen({ route }) {
  const { url } = route.params;

  const ref = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (Platform.OS === "web" || Platform.OS === "android") {
      Linking.openURL(url);
      navigationRef.goBack();
      return;
    }
  }, []);

  return (
    <SafeAreaView
      style={{
        backgroundColor: "#fff",
        height: "100%",
        width: "100%",
        flex: 1,
      }}
    >
      <View
        style={{
          backgroundColor: "#fff",
          height: 50,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          style={{ position: "absolute", left: 12 }}
          onPress={() => navigationRef.goBack()}
        >
          <Image
            source={cross}
            style={{
              resizeMode: "contain",
              width: 20,
              height: 20,
              tintColor: "black",
            }}
          />
        </TouchableOpacity>
        <Text
          numberOfLines={2}
          style={{
            fontSize: 12,
            color: Colors.black_opacity,
            marginLeft: 40,
            marginRight: 20,
          }}
        >
          {url}
        </Text>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: 0.5,
            backgroundColor: "black",
            opacity: 0.7,
          }}
        />
      </View>
      {Platform.OS !== "web" ? (
        <WebView
          ref={ref}
          onLoad={() => setIsLoading(false)}
          source={{ uri: url }}
          style={{ width: "100%", flex: 1 }}
        />
      ) : null}
      {isLoading ? (
        <View
          style={{
            width: "100%",
            height: "70%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator
            theme={{
              colors: {
                ...DefaultTheme.colors,
                primary: Colors.gold,
              },
            }}
            size="large"
          />
        </View>
      ) : null}
    </SafeAreaView>
  );
}
