import AsyncStorage from "@react-native-async-storage/async-storage";
import { Component } from "react";
import {
  Image,
  ImageBackgroundComponent,
  LayoutAnimation,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import arrow_left from "../assets/images/arrow_left.png";
import bookmarkt from "../assets/images/bolt.png";
import bubble from "../assets/images/bubble.png";
import bubble_gold from "../assets/images/bubble_gold.png";
import bolt_gold from "../assets/images/gold_bolt.png";
import notes_icon_gold from "../assets/images/notes_gold.png";
import { Colors } from "../Constants/Colors";
import NavigationHandler, { Result } from "../Handlers/NavigationHandler";
import UserHandler, { NotesInterface } from "../Handlers/UserHandler";

import { collection, getDocs, query, where } from "@firebase/firestore";
import { CommonActions } from "@react-navigation/native";
import { ActivityIndicator, DefaultTheme } from "react-native-paper";
import RenderHTML from "react-native-render-html";
import notes_icon from "../assets/images/notes.png";
import { db } from "../Components/db";
import { Sizes } from "../Constants/Sizes";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";
import { ContentHandler } from "../Handlers/ContentHandler";
import * as RootNavigation from "../Handlers/RootNavigation";
import { HomeScreen } from "../Screens/HomeScreen";
import { CustomButton } from "../Components/CustomButton";
import LocalBackHandler from "../Handlers/BackHandler";
import { HintBoxHTML } from "./ResultBoxComponents/HintBoxHTML";

interface ResultProps {
  home: HomeScreen;
  isPH: boolean;
}

export class ResultComponent extends Component<ResultProps> {
  en: NotesInterface | undefined = undefined;

  state = {
    isBookmark: false,
    unit: undefined,
    amount: undefined,
    duration: undefined,
    loss: undefined,
    age: undefined,
    serum: undefined,
    qt: undefined,
    rr: undefined,
    note: this.en,
    commentsColor: "#000",
    showSources: false,
    result: {
      id: "",
      boxes: [],
      title: "",
      pageId: "",
      sources: "",
      nextPage: "",
      filters: [],
    },
  };

  timer: NodeJS.Timer | undefined;

  componentDidMount() {
    NavigationHandler.getInstance().isPH = this.props.isPH;

    const currentResID = NavigationHandler.getInstance().isPH
      ? NavigationHandler.getInstance().currentPHResult
      : NavigationHandler.getInstance().currentResultID;

    NavigationHandler.getInstance().setResPages();

    (async () => {
      const q = query(
        collection(db, "comments"),
        where("parentID", "==", null),
        where(
          "resultID",
          "==",
          NavigationHandler.getInstance().getCurrentResultID()
        )
      );
      const response = await getDocs(q);
      this.setState({
        resID: NavigationHandler.getInstance().getCurrentResultID(),
      });
      if (response.docs.length !== 0) {
        this.setState({
          commentsColor: this.props.isPH ? Colors.light_green : Colors.gold,
        });
      }
    })();

    (async () => {
      let response = await AsyncStorage.getItem("did_show_navbar_tut");
      if (response !== null && response === "1") {
        return;
      }
      NavigationHandler.getInstance().homeRef!.setState({ isTutorial: true });
      AsyncStorage.setItem("did_show_navbar_tut", "1");
    })();

    // MARK: End

    //MARK: Content loading

    (async () => {
      if (
        UserHandler.getInstance()
          .getCurrentUser()
          .bookmarks.includes(currentResID)
      ) {
        this.setState({
          isBookmark: true,
          //  result: ContentHandler.getInstance().getResultFromOffline(currentResID)
        });
      }

      let res = await NavigationHandler.getInstance().getResultBoxes(
        this.props.isPH
      );
      this.setState({ result: res });
      if (LocalBackHandler.getInstance().isFromBack)
        LocalBackHandler.getInstance().handleBack(res);

      AnalyticsHandler.getInstance().logScreen("result_screen");
      AnalyticsHandler.getInstance().logCustomAnalytics("result_page", {
        pageId: res.pageId,
        title: res.title,
        id: res.id,
      });

      ContentHandler.getInstance().getIsResult()
        ? AnalyticsHandler.getInstance().logResultPage(res.pageId)
        : AnalyticsHandler.getInstance().logDecisionTreeComponent(res.title);

      const notes = UserHandler.getInstance().getCurrentUser().notes;
      let i = notes.filter((note) => note.id == this.state.result.id);

      if (i[0] !== undefined) {
        console.log("note", i[0]);
        this.setState({ note: i[0] });
      }
    })();

    if (NavigationHandler.getInstance().openedResultPages >= 2) {
      if (!UserHandler.getInstance()._isPayingUser) {
        this.timer = setInterval(this.presentPayment, 5000);
      }
    }
  }

  presentPayment = () => {
    clearInterval(this.timer);
    const resetAction = CommonActions.reset({
      index: 1,
      routes: [{ name: "PaymentScreen", params: {} }],
    });
    RootNavigation.navigationRef.dispatch(resetAction);
  };

  render() {
    if (
      NavigationHandler.getInstance().resultRef === undefined ||
      NavigationHandler.getInstance().resultRef !== this
    )
      NavigationHandler.getInstance().resultRef = this;
    if (this.state.result.id === "") {
      return (
        <View
          style={{
            flex: 1,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator
            theme={{
              colors: {
                ...DefaultTheme.colors,
                primary: Colors.gold,
              },
            }}
            size="large"
          />
        </View>
      );
    } else {
      return (
        <View
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "90%",
            maxWidth: Sizes.containerWidth,
            paddingBottom: 80,
            marginTop: 20,
            flex: 1,
          }}
        >
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            {getItemToParent(this.props.isPH)}
            {ContentHandler.getInstance().getIsResult() ? (
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 12,
                  marginTop: 0,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    RootNavigation.navigate("NotesScreen");
                  }}
                >
                  <Image
                    source={
                      this.state.note === undefined
                        ? notes_icon
                        : this.state.note.note === ""
                        ? notes_icon
                        : notes_icon_gold
                    }
                    style={{
                      width: 30,
                      height: 30,
                      resizeMode: "contain",
                      marginLeft: 20,
                    }}
                  />
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => {
                    RootNavigation.navigate("CommentsScreen");
                  }}
                >
                  <Image
                    source={
                      this.state.commentsColor === "#000" ? bubble : bubble_gold
                    }
                    style={{
                      width: 30,
                      height: 30,
                      resizeMode: "contain",
                      marginLeft: 20,
                      tintColor: this.state.commentsColor,
                    }}
                  />
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => {
                    if (this.state.isBookmark) {
                      UserHandler.getInstance().removeBookmark(
                        this.state.result.id
                      );
                      ContentHandler.getInstance().removeBookmarkFromOffline(
                        this.state.result
                      );
                      AnalyticsHandler.getInstance().logScreenEvent(
                        "Shortcuts",
                        "removed",
                        this.state.result.id
                      );
                    } else {
                      UserHandler.getInstance().addBookmark(
                        this.state.result.id
                      );
                      ContentHandler.getInstance().addBookmarkToOffline(
                        this.state.result
                      );
                      AnalyticsHandler.getInstance().logScreenEvent(
                        "Shortcuts",
                        "added",
                        this.state.result.id
                      );
                    }

                    this.setState({ isBookmark: !this.state.isBookmark });
                  }}
                >
                  <Image
                    source={this.state.isBookmark ? bolt_gold : bookmarkt}
                    style={{
                      width: NavigationHandler.getInstance().isCustomResult
                        ? 0
                        : 30,
                      opacity: NavigationHandler.getInstance().isCustomResult
                        ? 0
                        : 1,
                      tintColor: this.state.isBookmark
                        ? this.props.isPH
                          ? Colors.light_green
                          : Colors.gold
                        : Colors.black,
                      height: 30,
                      resizeMode: "contain",
                      marginLeft: 20,
                    }}
                  />
                </TouchableOpacity>
              </View>
            ) : null}
          </View>
          {NavigationHandler.getInstance().isPH ? null : (
            <Text
              style={{
                fontSize: 13,
                color: this.props.isPH ? Colors.black_opacity : Colors.gold,
                marginBottom: 12,
                fontWeight: "bold",
              }}
            >
              {NavigationHandler.getInstance()
                .getPath()
                .map((p) => {
                  if (
                    NavigationHandler.getInstance().getPath().indexOf(p) !==
                    NavigationHandler.getInstance().getPath().length - 1
                  ) {
                    return p + " | ";
                  } else {
                    return p;
                  }
                })}
            </Text>
          )}
          <Text style={styles.title}>{this.state.result.title}</Text>
          <View
            style={{
              width: "100%",
              minHeight: ContentHandler.getInstance().getIsResult()
                ? "85%"
                : Platform.OS === "web"
                ? "90%"
                : "75%",
              justifyContent: ContentHandler.getInstance().getIsResult()
                ? "flex-start"
                : "center",
            }}
          >
            <ResultBoxes result={this.state.result} note={this.state.note} />
            {ContentHandler.getInstance().hasMulitpleChoiceBoxes ? (
              <View>
                <View
                  style={{ width: "100%", alignItems: "center", marginTop: 20 }}
                >
                  <CustomButton
                    text={"Auswahl bestätigen"}
                    backgroundColor={Colors.background}
                    foregroundColor={Colors.gold}
                    borderColor={Colors.gold}
                    action={() => {
                      NavigationHandler.getInstance().navigateToNextPage(
                        this.state.result.nextPage
                      );
                    }}
                    enabled={false}
                  />
                </View>
              </View>
            ) : null}
            {ContentHandler.getInstance().getIsResult() ? (
              <View
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    color: Colors.black,
                    opacity: 0.3,
                    fontSize: 12,
                    marginTop: 40,
                  }}
                >
                  Resultat-ID: {this.state.result.pageId}
                </Text>
                {this.state.result.sources !== undefined ? (
                  <TouchableOpacity
                    onPress={() => {
                      LayoutAnimation.configureNext(
                        LayoutAnimation.Presets.easeInEaseOut
                      );
                      this.setState({ showSources: !this.state.showSources });
                    }}
                  >
                    <Text
                      style={{
                        color: Colors.black,
                        textDecorationLine: "underline",
                        opacity: 0.3,
                        fontSize: 12,
                        marginTop: 40,
                      }}
                    >
                      Quellen
                      {this.state.showSources ? " ausblenden" : " einsehen"}
                    </Text>
                  </TouchableOpacity>
                ) : null}
              </View>
            ) : null}
            {this.state.showSources ? (
              <View>
                <RenderHTML
                  source={{ html: this.state.result.sources }}
                  tagsStyles={{
                    body: {
                      marginTop: 20,
                      ...Platform.select({ web: { paddingBottom: 50 } }),
                    },
                    p: {
                      opacity: 0.5,
                      fontSize: 12,
                    },
                  }}
                  renderersProps={{
                    a: {
                      onPress: onLinkPress,
                    },
                  }}
                />
              </View>
            ) : (
              <View
                style={{ ...Platform.select({ web: { marginBottom: 70 } }) }}
              />
            )}
          </View>
        </View>
      );
    }
  }
}

function onLinkPress(event: any, href: any) {
  RootNavigation.navigationRef.navigate("WebBrowserScreen", { url: href });
}

const ResultBoxes = (props: { result: Result; note: NotesInterface }) => {
  const { result, note } = props;
  ContentHandler.getInstance().refreshBoxes();
  console.log("note", note);
  let boxes = result.boxes;
  if (note !== undefined && note.note !== "" && note.showInResult) {
    let noteBox = (
      <HintBoxHTML
        title={"<p><u>Notiz:</u><br>" + note.note + "</p>"}
        expandableContent={""}
        inlineImage={undefined}
        key={"NOTES"}
      />
    );
    boxes = boxes.filter((b) => b.key !== "NOTES");
    boxes.unshift(noteBox);
  }
  return boxes;
};

function getItemToParent(isPH: boolean) {
  if (!isPH) {
    if (!ContentHandler.getInstance().getIsResult()) {
      return <View></View>;
    }
    return <Text style={styles.sub_title}>Ihr resultat</Text>;
  } else {
    return (
      <TouchableOpacity
        onPress={() => {
          //   if (NavigationHandler.getInstance().isFromInfo) {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          if (
            NavigationHandler.getInstance().phRef!.state.previousResults
              .length > 0
          ) {
            let prevRes =
              NavigationHandler.getInstance().phRef!.state.previousResults;

            const prev = prevRes[prevRes.length - 1];

            prevRes.splice(-1);
            LayoutAnimation.configureNext(
              LayoutAnimation.Presets.easeInEaseOut
            );
            NavigationHandler.getInstance().setCurrentPHResult(prev);
            NavigationHandler.getInstance().phRef!.setState({
              //  currentLevel: NavigationHandler.getInstance().phRef.state.currentLevel - 1,
              resID: NavigationHandler.getInstance().phRef!.state.resID - 1,
              previousResults: prevRes,
              phID: prev,
            });
          } else {
            RootNavigation.goBack();
            NavigationHandler.getInstance().setCurrentPHResult("");
            NavigationHandler.getInstance().isFromInfo = false;
            NavigationHandler.getInstance().isPH = false;
          }
          //      } else {
          //      issuer.setState({ isResult: false });
          //   }
        }}
        style={{ marginTop: -30 }}
      >
        <View style={styles.container}>
          <Image
            style={{
              width: 12,
              height: 12,
              marginRight: 8,
              opacity: 0.5,
              resizeMode: "contain",
            }}
            source={arrow_left}
          />
          <Text style={styles.back_button}>zurück</Text>
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 20,
    letterSpacing: 0.7,
    textAlign: "left",
    color: "#000000",
    //textTransform: "uppercase",
  },
  back_button: {
    fontSize: 12,
    color: Colors.black_opacity,
    fontWeight: "700",
  },
  sub_title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 15,
    textAlign: "left",
    color: Colors.light_green,
    marginBottom: 5,
    textTransform: "uppercase",
  },
  container: {
    marginTop: 10,
    marginBottom: 15,
    borderRadius: 50,
    padding: 12,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    backgroundColor: Colors.background,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
