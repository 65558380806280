import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  Linking,
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Header } from "../Components/Header";

export function MenuScreen() {
  const nav = useNavigation();
  const [hasPromo, setHasPromo] = useState(false);

  useEffect(() => {
    getPromo();
  }, []);

  const getPromo = async () => {
    const response = await fetch("https://server.medi.ceo/has_pw");
    let data = await response.json();

    if (data == true) {
      setHasPromo(true);
    } else {
      setHasPromo(false);
    }
  };

  return (
    <SafeAreaView
      style={{
        ...Platform.select({ web: { height: "100vh" } }),
        backgroundColor: "#000",
        flex: 1,
        height: "100%",
        justifyContent: "center",
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <View
        style={{
          width: "100%",
          backgroundColor: "#000",
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Header isProfile={false} isDark={true} issuer={this} />
        <View
          style={{
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            flex: 1,
            maxHeight: 350,
          }}
        >
          {/*<MenuItem title={"Einstellungen"}/>*/}
          <MenuItem
            title={"Nutzungsbedingungen"}
            action={() => nav.navigate("TermsOfUseScreen")}
          />
          <MenuItem
            title={"Datenschutz"}
            action={() => nav.navigate("PrivacyPolicyScreen")}
          />
          <MenuItem
            title={"Impressum"}
            action={() => nav.navigate("ImpressScreen")}
          />
          <MenuItem
            title={"Feedback"}
            action={() => {
              const url = "mailto:feedback@medi.ceo";
              Linking.canOpenURL(url).then((supported) => {
                if (supported) {
                  Linking.openURL(url);
                }
              });
            }}
          />
          {/*<MenuItem title={"Impressum"}/>*/}
          {!hasPromo ? null : (
            <MenuItem
              title={"Website"}
              action={() => {
                const url = "https://www.medi.ceo";
                Linking.canOpenURL(url).then((supported) => {
                  if (supported) {
                    // Linking.openURL(url);
                    nav.navigate("WebBrowserScreen", {
                      url: "https://www.medi.ceo",
                    });
                  } else {
                  }
                });
              }}
            />
          )}
          {/*<MenuItem title={"Empfehlen"}/>*/}
          {/*<MenuItem title={"Unterstützen"}/>*/}
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  menu_text: {
    color: "#fff",
    letterSpacing: 1,
    fontSize: 18,
    fontWeight: "500",
    textTransform: "uppercase",
  },
});

interface Props {
  title: string;
  action?: () => void;
}

function MenuItem(props: Props) {
  return (
    <View>
      <TouchableOpacity onPress={props.action}>
        <Text style={styles.menu_text}>{props.title}</Text>
      </TouchableOpacity>
    </View>
  );
}
