import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { Sizes } from "../Constants/Sizes";

interface ButtonProps {
  text: string;
  backgroundColor: string;
  foregroundColor: string;
  borderColor: string;
  action: () => void;
  enabled?: boolean;
}

export function CustomButton(props: ButtonProps) {
  return (
    <TouchableOpacity
      style={[
        styles.loginScreenButton,
        {
          backgroundColor: props.backgroundColor,
          borderColor: props.borderColor,
          width: "100%",
          opacity: props.enabled ? 0.5 : 1,
          maxWidth: Sizes.containerWidth,
        },
      ]}
      disabled={props.enabled ?? false}
      onPress={props.action}
    >
      <Text
        style={[
          styles.loginText,
          {
            color: props.foregroundColor,
          },
        ]}
      >
        {props.text}
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  loginScreenButton: {
    marginRight: 40,
    marginLeft: 40,
    marginTop: 10,
    marginBottom: 10,
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 50,
    borderWidth: 2,
  },
  loginText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    paddingLeft: 16,
    paddingRight: 16,
  },
});
