import { View } from "react-native";
import RenderHTML from "react-native-render-html";
import { Sizes } from "../../Constants/Sizes";
import { ContentHandler } from "../../Handlers/ContentHandler";
import NavigationHandler from "../../Handlers/NavigationHandler";

interface TextComponentProps {
  content: string;
  style: {};
  isHtml?: boolean;
}

export function BoxTextComponent(props: TextComponentProps) {
  const { content, style, isHtml } = props;

  const unit = ContentHandler.getInstance()._currentUnit;
  const amount = NavigationHandler.getInstance().resultRef!.state.amount;
  const age = NavigationHandler.getInstance().resultRef!.state.age;
  const duration = NavigationHandler.getInstance().resultRef!.state.duration;
  const loss = NavigationHandler.getInstance().resultRef!.state.loss;
  const serum = NavigationHandler.getInstance().resultRef!.state.serum;
  const qt = NavigationHandler.getInstance().resultRef!.state.qt;
  const rr = NavigationHandler.getInstance().resultRef!.state.rr;

  if (isHtml === undefined || isHtml) {
    if (content.includes("&lt;custom_calc_f&gt;")) {
      let u = "&lt;custom_calc_f&gt;";
      if (unit === undefined || amount === undefined) {
        let c = content.replace(
          new RegExp(u, "g"),
          "Zur genauen Berechnung bitte Gewicht eintragen."
        );
        return (
          <RenderHTML
            contentWidth={Sizes.containerWidth}
            tagsStyles={style}
            source={{ html: c }}
          />
        );
      } else {
        const w = amount;
        if (w <= 10) {
          return (
            <BoxTextComponent
              key={Math.random()}
              style={style}
              content={
                "<ul><li>pro Stunde: 4 ml/kg </li><li>pro Tag: 100 ml/kg </li></ul>"
              }
            />
          );
        } else if (w <= 20) {
          let dose_h = 40 + (w - 10) * 2;
          let dose_d = 1000 + (w - 10) * 50;
          return (
            <BoxTextComponent
              key={Math.random()}
              style={style}
              content={
                "<ul><li>pro Stunde: 4 ml/kg </li><li>pro Tag: 100 ml/kg </li></ul>"
              }
            />
          );
          //ra.push(<TextComponent key={Math.random()} style={props.style} content={"<bullet>pro Stunde: " + dose_h + " ml<bullet>pro Tag: " + dose_d + " ml"}/>);
        } else {
          let dose_h = 60 + (w - 20) * 1;
          let dose_d = 1500 + (w - 20) * 20;
          return (
            <BoxTextComponent
              key={Math.random()}
              style={style}
              content={
                "<ul><li>pro Stunde: 4 ml/kg </li><li>pro Tag: 100 ml/kg </li></ul>"
              }
            />
          );
          //ra.push(<TextComponent key={Math.random()} style={props.style} content={"<bullet>pro Stunde: " + dose_h + " ml<bullet>pro Tag: " + dose_d + " ml"}/>);
        }
      }
    } else if (content.includes("&lt;kuss_var&gt;")) {
      const v = NavigationHandler.getInstance().calculationVar;
      let t = "";
      if (v > 3) {
        t =
          "Die KUSS Analyse ergibt " +
          v +
          " Punkte. Damit ist EIN analgetischer Therapiebedarf vorhanden.";
      } else {
        t =
          "Die KUSS Analyse ergibt " +
          v +
          " Punkte. Damit ist KEIN analgetischer Therapiebedarf vorhanden.";
      }
      const text = props.content.split("&lt;kuss_var&gt;").join(t);
      return (
        <BoxTextComponent
          key={Math.random()}
          style={style}
          content={"<p>" + text + "<p>"}
        />
      );
    } else if (content.includes("&lt;custom_calc_l&gt;")) {
      let u = "&lt;custom_calc_l&gt;";
      if (amount === undefined) {
        let c = content.replace(
          new RegExp(u, "g"),
          "Zur genauen Berechnung bitte Gewicht eintragen."
        );
        return (
          <RenderHTML
            tagsStyles={style}
            source={{ html: c }}
            contentWidth={Sizes.containerWidth}
          />
        );
      } else {
        const w = amount;

        if (w <= 5) {
          var t = getTable("1", "3.0 ID", "6 / 6");
        } else if (w >= 5 && w < 10) {
          var t = getTable("1.5", "3.5 ID", "6 / 8");
        } else if (w >= 10 && w < 20) {
          var t = getTable("2", "4.0 ID", "10 / 10");
        } else if (w >= 20 && w < 30) {
          var t = getTable("2.5", "5.0 ID", "10 / 10");
        } else if (w >= 30 && w < 50) {
          var t = getTable("3", "6.0 ID", "14 / 16");
        } else if (w >= 50 && w < 70) {
          var t = getTable("4", " ", "14 / 16");
        } else {
          var t = getTable("5", " ", "14 / 16");
        }
        return (
          <BoxTextComponent key={Math.random()} style={style} content={t} />
        );
      }
    } else if (content.includes("&lt;custom_calc_lt&gt;")) {
      if (age === undefined || amount === undefined) {
        let u = "&lt;custom_calc_lt&gt;";
        let c = content.replace(
          new RegExp(u, "g"),
          "Zur genauen Berechnung bitte Alter und Gewicht eintragen."
        );
        return (
          <RenderHTML
            tagsStyles={style}
            source={{ html: c }}
            contentWidth={Sizes.containerWidth}
          />
        );
      } else {
        let lage = "";
        if (age < 5) {
          lage = "2 cm über der Carina trachae";
        } else if (age < 16) {
          lage = "3 cm über der Carina trachae";
        } else {
          lage = "4 cm über der Carina trachae";
        }

        let tiefe = "";

        if (age < 1) {
          tiefe = "1, 2,3, kg KG-\n7, 8, 9 cm-Regel)";
        } else if (amount < 2) {
          tiefe = "7 cm";
        } else if (amount < 3) {
          tiefe = "8 cm";
        } else if (amount < 4) {
          tiefe = "9 cm";
        } else {
          tiefe = "10 cm";
        }
        let content = `<table><tr><th><p>Einführtiefe</p></th><th><p>Lage</p></th></tr><tr><td><p>${tiefe}</p></td><td><p>${lage}</p></td></tr></table>`;
        return (
          <RenderHTML
            tagsStyles={style}
            source={{ html: content }}
            contentWidth={Sizes.containerWidth}
          />
        );
      }
    } else if (content.includes("&lt;custom_calc_tg&gt;")) {
      if (age === undefined) {
        let u = "&lt;custom_calc_tg&gt;";
        let c = content.replace(
          new RegExp(u, "g"),
          "Zur genauen Berechnung bitte Alter eintragen."
        );
        return (
          <RenderHTML
            tagsStyles={style}
            source={{ html: c }}
            contentWidth={Sizes.containerWidth}
          />
        );
      } else {
        const x = 3.5 + age / 4 + " mm";
        const y = 4.5 + age / 2 + " mm";

        let content = `<table><tr><th><p>Art des Tubus</p></th><th><p>Innendurchmesser</p></th></tr><tr><td><p>Mit Cuff</p></td><td><p>${x}</p></td></tr></tr><tr><td><p>Ohne Cuff</p></td><td><p>${y}</p></td></tr></table>`;
        return (
          <RenderHTML
            tagsStyles={style}
            source={{ html: content }}
            contentWidth={Sizes.containerWidth}
          />
        );
      }
    } else if (content.includes("&lt;custom_calc_peri&gt;")) {
      if (
        amount === undefined ||
        duration === undefined ||
        loss === undefined
      ) {
        let u = "&lt;custom_calc_peri&gt;";
        let c = content.replace(
          new RegExp(u, "g"),
          "Zur genauen Berechnung bitte Gewicht, Dauer und Verluste eintragen."
        );
        return (
          <RenderHTML
            tagsStyles={style}
            source={{ html: c }}
            contentWidth={Sizes.containerWidth}
          />
        );
      } else {
        const v = 1.5 * amount + 1.5 * amount * duration + loss;

        return (
          <RenderHTML
            tagsStyles={style}
            contentWidth={Sizes.containerWidth}
            source={{ html: "<p><b>" + v + " ml" + "</b></p>" }}
          />
        );
      }
    } else if (content.includes("&lt;custom_calc_gd&gt;")) {
      if (amount === undefined) {
        let u = "&lt;custom_calc_gd&gt;";
        let c = content.replace(
          new RegExp(u, "g"),
          "Zur genauen Berechnung bitte Gewicht eintragen."
        );
        return (
          <RenderHTML
            tagsStyles={style}
            source={{ html: c }}
            contentWidth={Sizes.containerWidth}
          />
        );
      } else {
        const perHour = 6 * amount;

        return (
          <RenderHTML
            contentWidth={Sizes.containerWidth}
            tagsStyles={style}
            source={{
              html: "<p>1 ml/h einer <b>" + perHour + "%</b> Lösung.</p>",
            }}
          />
        );
      }
    } else if (content.includes("&lt;custom_calc_gfr&gt;")) {
      if (amount === undefined || age === undefined || serum === undefined) {
        let u = "&lt;custom_calc_gfr&gt;";
        let c = content.replace(
          new RegExp(u, "g"),
          "Zur genauen Berechnung bitte Gewicht, Alter und Serum-Kreatinin eintragen."
        );
        return (
          <RenderHTML
            tagsStyles={style}
            source={{ html: c }}
            contentWidth={Sizes.containerWidth}
          />
        );
      } else {
        let cal = ((140 - age) * amount) / (72 * serum);
        let female = cal * 0.85;
        cal = Math.round(cal * 1000) / 1000;
        female = Math.round(female * 1000) / 1000;
        return (
          <RenderHTML
            contentWidth={Sizes.containerWidth}
            tagsStyles={style}
            source={{
              html:
                "<p><b>♂ " + cal + " ml/min<br>♀ " + female + " ml/min</b></p>",
            }}
          />
        );
      }
    } else if (content.includes("&lt;custom_calc_bazett&gt;")) {
      if (qt === undefined || rr === undefined) {
        let u = "&lt;custom_calc_bazett&gt;";
        let c = content.replace(
          new RegExp(u, "g"),
          "Zur genauen Berechnung bitte QT-Dauer und RR-Abstand eintragen."
        );
        return (
          <RenderHTML
            tagsStyles={style}
            source={{ html: c }}
            contentWidth={Sizes.containerWidth}
          />
        );
      } else {
        let cal = qt / 1000 / Math.sqrt(rr);
        cal = Math.round(cal * 1000) / 1000;
        return (
          <RenderHTML
            contentWidth={Sizes.containerWidth}
            tagsStyles={style}
            source={{ html: "<p><b>" + cal + " msec</b></p>" }}
          />
        );
      }
    } else if (content.includes("&lt;custom_calc_fridericia&gt;")) {
      if (qt === undefined || rr === undefined) {
        let u = "&lt;custom_calc_fridericia&gt;";
        let c = content.replace(
          new RegExp(u, "g"),
          "Zur genauen Berechnung bitte QT-Dauer und RR-Abstand eintragen."
        );
        return (
          <RenderHTML
            tagsStyles={style}
            source={{ html: c }}
            contentWidth={Sizes.containerWidth}
          />
        );
      } else {
        let cal = qt / 1000 / Math.pow(rr, 1 / 3);
        cal = Math.round(cal * 1000) / 1000;
        return (
          <RenderHTML
            contentWidth={Sizes.containerWidth}
            tagsStyles={style}
            source={{ html: "<p><b> " + cal + " msec</b></p>" }}
          />
        );
      }
    } else if (content.includes("&lt;custom_calc_freq&gt;")) {
      if (rr === undefined) {
        let u = "&lt;custom_calc_freq&gt;";
        let c = content.replace(
          new RegExp(u, "g"),
          "Zur genauen Berechnung bitte RR-Abstand eintragen."
        );
        return (
          <RenderHTML
            tagsStyles={style}
            source={{ html: c }}
            contentWidth={Sizes.containerWidth}
          />
        );
      } else {
        let cal = 300 / rr;
        cal = Math.round(cal * 1000) / 1000;
        let cal2 = 600 / rr;
        cal2 = Math.round(cal2 * 1000) / 1000;
        return (
          <RenderHTML
            contentWidth={Sizes.containerWidth}
            tagsStyles={style}
            source={{
              html:
                "<p><ul><li>Papiervorschub 25 mm/s: <b>" +
                cal +
                " Schläge/min</b></li><li>Papiervorschub 50 mm/s: <b>" +
                cal2 +
                " Schläge/min</li></ul></b></p>",
            }}
          />
        );
      }
    }

    if (unit === undefined || amount === undefined) {
      return (
        <RenderHTML
          tagsStyles={style}
          source={{ html: content }}
          contentWidth={Sizes.containerWidth}
        />
      );
    } else {
      return (
        <ParsedText
          unit={unit}
          amount={amount}
          content={content}
          style={style}
        />
      );
    }
  } else {
    return <View />;
  }
}

function getTable(x: string, y: string, z: string) {
  return `<table><tr><th><p>Größe der LMA</p></th><th><p>Tubus (ohne Cuff)</p></th><th><p>Magensonde</p></th></tr><tr><td><p>${x}</p></td><td><p>${y}</p></td><td><p>${z}</p></td></tr></table>`;
}

function ParsedText(props: TextComponentProps) {
  // find all occurences of the unit
  const { content, style, unit, amount } = props;

  const unit_with_space = "/" + unit;

  const indices = getIndicesOf(unit_with_space, content, true);

  if (indices.length === 0) {
    return (
      <RenderHTML
        tagsStyles={style}
        source={{ html: content! }}
        contentWidth={Sizes.containerWidth}
      />
    );
  } else {
    // const comparison_units = checkIfValidAndReturnComparisonUnits(content, indices);
    //const new_content = getFullComparisonAmount(content, comparison_units!, amount!, unit!);
    const new_content = calc(content, amount, unit);

    return (
      <RenderHTML
        tagsStyles={style}
        source={{ html: new_content! }}
        contentWidth={Sizes.containerWidth}
      />
    );
  }
}

function iu(u: string, m: string[]) {
  let l = m.length - 2;
  let mc = m;
  for (let i = 0; i < l; i++) {
    mc.splice(i + i - 1, 0, u);
  }
  mc.push(u);

  return mc;
}

function rca(i: number, cu: string[], ra: string[]): any {
  const u = cu[i];
  let ra_c = ra;
  for (const s of ra) {
    ra_c = ra_c.filter((f) => f !== s);
    let m = s.split(u);

    if (m.length > 1) m = iu(u, m);

    ra_c = ra_c.concat(m);
  }
  if (cu.length - 1 > i) {
    return rca(i + 1, cu, ra_c);
  } else {
    return ra_c;
  }
}

function calc(content: string, amount: number, unit: string) {
  const c = content.split("/" + unit);
  let e = c.splice(-1);
  let ret = "";

  for (const m of c) {
    const u = getUnit(m);

    const ca = getCalcAmount(m, u.length);

    var r = [];
    if (ca.hm) {
      r = ca.cm.split("-");
    } else {
      r.push(ca.cm);
    }
    var nr = [];
    for (const n of r) {
      let nn = n.replace(new RegExp(",", "g"), ".");
      let v = Number(nn);
      v = v * amount;
      nr.push(Math.round(v * 1000) / 1000);
    }

    let rs = "";
    if (nr.length > 1) {
      rs = nr[0] + " - " + nr[1];
    } else {
      rs = nr[0] + "";
    }

    // rs = rs.replace(new RegExp(".", "g"), ",");
    rs = "<b>" + rs;
    let h = m;

    var nc = h.replace(new RegExp(ca.cm + " ", "g"), rs + " ");

    let nu = u.unit.replace(new RegExp("/" + unit, "g"), "");

    nc = nc.replace(new RegExp(u.unit, "g"), nu + "</b>");

    if (c.indexOf(m) + 1 < c.length) {
      if (c[c.indexOf(m) + 1].includes("KG")) {
        nc = nc.replace(new RegExp(" KG", "g"), "");
      }
    }
    ret += nc;
  }

  var b = e[0];
  if (b.includes("KG")) {
    b = b.replace(new RegExp(" KG", "g"), "");
  }
  return ret + b;
}

function getCalcAmount(m: string, ti: number) {
  let i = ti + 1;

  let hasMultiple = false;

  const calc = m;

  while (
    isNumeric(calc[calc.length - i]) ||
    calc[calc.length - i] === "," ||
    calc[calc.length - i] === "-" ||
    calc[calc.length - i] === " " ||
    calc[calc.length - i] === "."
  ) {
    if (calc[calc.length - i] === "-") {
      hasMultiple = true;
    }
    i += 1;
  }

  i -= 1;

  let calcAmount = calc.slice(calc.length - i, calc.length - ti - 1);

  while (calcAmount.startsWith(" ") || calcAmount.startsWith("."))
    calcAmount = calcAmount.substring(1);

  return { cm: calcAmount, hm: hasMultiple };
}

function getUnit(m: string) {
  let i = 0;
  let k = m.length - 1;

  while (m[k - i] !== " " && !isNumeric(m[k - i])) {
    i += 1;
  }

  return { unit: m.slice(m.length - i, m.length), length: i };
}

function getFullComparisonAmount(
  content: string,
  cu: string[],
  amount: number,
  unit: string
) {
  //  let c = content.split(cu);

  let c: string[] = [content];
  c = rca(0, cu, c);

  //if (c.length === 2) {
  let e = c.splice(-1);
  var ret = "";

  for (const m of c) {
    let i = 3; //ENDS WITH SPACE THEREFORE NEEDS TO BE 2
    let hasMultiple = false;

    const calc = m;

    while (
      isNumeric(calc[calc.length - i]) ||
      calc[calc.length - i] === "," ||
      calc[calc.length - i] === "-" ||
      calc[calc.length - i] === " " ||
      calc[calc.length - i] === "."
    ) {
      if (calc[calc.length - i] === "-") {
        hasMultiple = true;
      }
      i += 1;
    }

    i -= 1;

    let calcAmount = calc.slice(calc.length - i, calc.length - 1);

    while (calcAmount.startsWith(" ") || calcAmount.startsWith("."))
      calcAmount = calcAmount.substring(1);

    var r = [];
    if (hasMultiple) {
      r = calcAmount.split("-");
    } else {
      r.push(calcAmount);
    }
    var nr = [];
    for (const n of r) {
      let nn = n.replace(new RegExp(",", "g"), ".");
      let v = Number(nn);

      v = v * amount;

      nr.push(Math.round(v * 1000) / 1000);
    }
    let rs = "";
    if (nr.length > 1) {
      rs = nr[0] + " - " + nr[1];
    } else {
      rs = nr[0] + "";
    }

    // rs = rs.replace(new RegExp(".", "g"), ",");
    rs = "<b>" + rs;
    let o_cu = [];
    for (const p of cu) {
      o_cu.push(m + p);
    }
    let h = m + cu;
    var nc = h.replace(new RegExp(calcAmount + " ", "g"), rs + " ");
    let n_cu = [];
    for (const i of cu) {
      n_cu.push(i.replace(new RegExp("/" + unit, "g"), ""));
    }

    // let u = cu.replace(new RegExp("/"+unit, "g"), "")
    for (const n_cuu of n_cu) {
      let u = n_cuu.replace(new RegExp("/" + unit, "g"), "");
      nc = nc.replace(new RegExp(n_cuu, "g"), u + "</b>");
    }

    if (c.indexOf(m) + 1 < c.length) {
      if (c[c.indexOf(m) + 1].includes("KG")) {
        nc = nc.replace(new RegExp(" KG", "g"), "");
      }
    }

    ret += nc;
  }

  var b = e[0];
  if (b.includes("KG")) {
    b = b.replace(new RegExp(" KG", "g"), "");
  }
  return ret + b;
}

function isNumeric(str: any) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

function checkIfValidAndReturnComparisonUnits(
  content: string,
  indices: number[]
) {
  let ra = [];
  for (const i of indices) {
    const index = i + 1;
    if (content[index - 1] === "/") {
      let i = 0;
      while (!content.slice(index - i, index + 2).startsWith(" ")) {
        i += 1;
      }
      i -= 1;
      ra.push(content.slice(index - i, index + 2));
    }
  }
  ra = [...new Set(ra)];
  return ra;
}

function getIndicesOf(searchStr: string, str: string, caseSensitive: boolean) {
  var searchStrLen = searchStr.length;
  if (searchStrLen == 0) {
    return [];
  }
  var startIndex = 0,
    index,
    indices = [];
  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
}
