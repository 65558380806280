import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import {
  Image,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import check_ from "../assets/images/check_.png";
import cross from "../assets/images/cross.png";
import notes from "../assets/images/pen.png";
import { Colors } from "../Constants/Colors";
import NavigationHandler from "../Handlers/NavigationHandler";
import UserHandler from "../Handlers/UserHandler";

interface CommentsProps {
  id: string;
  isPH: boolean;
}

export function NotesScreen(props: CommentsProps) {
  if (props.isPH) {
    var id = NavigationHandler.getInstance().currentPHResult;
  } else {
    var id = NavigationHandler.getInstance().currentResultID;
  }

  const [showCheck, setShowCheck] = useState(false);

  const notes = UserHandler.getInstance().currentUser.notes;
  let note = "";

  let i = notes.filter((note) => note.id == id);
  let noteShow = false;

  if (i[0] != undefined) {
    note = i[0].note;
    i[0].showInResult !== undefined
      ? (noteShow = i[0].showInResult)
      : (noteShow = false);
  }

  const [noteText, setNoteText] = useState(note);
  const [value, setValue] = useState(noteShow);
  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, alignItems: "center" }}
        keyboardShouldPersistTaps="handled"
      >
        <Header
          showCheck={showCheck}
          id={id}
          text={noteText}
          shouldUpdate={value}
        />
        <View
          style={{
            marginTop: 20,
            alignItems: "flex-start",
            width: "90%",
            flex: 1,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1,
              width: "100%",
            }}
          >
            <TextInput
              multiline={true}
              style={[
                styles.notes_text,
                {
                  height: "100%",
                  width: "100%",
                  backgroundColor: Colors.background,
                  padding: 12,
                  borderRadius: 6,
                },
              ]}
              onChangeText={(text) => {
                setNoteText(text);
                setShowCheck(true);
              }}
            >
              {note}
            </TextInput>
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              padding: 20,
              height: "25%",
              alignItems: "center",
            }}
          >
            <Switch
              value={value}
              onValueChange={(v) => {
                setValue(v);
                setShowCheck(true);
              }}
              activeThumbColor={Colors.gold}
              trackColor={{ true: Colors.gold, false: Colors.gray }}
              style={{ marginRight: 20 }}
            />
            <Text
              style={{
                fontSize: 12,
                fontWeight: "bold",
                color: Colors.black_opacity,
              }}
            >
              Notiz auf der Resultatseite anzeigen
            </Text>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

interface HP {
  id: string;
  text: string;
  showCheck: boolean;
  shouldUpdate: boolean;
}

function Header(props: HP) {
  const navigation = useNavigation();

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 1000,
        width: "100%",
        paddingLeft: 20,
        height: 80,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        backgroundColor: "#fff",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.15,
        shadowRadius: 2,
        elevation: 2,
      }}
    >
      <View
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Image
          source={notes}
          style={{
            width: 32,
            height: 22,
            resizeMode: "contain",
            marginRight: 16,
            tintColor: Colors.gold,
          }}
        />
        <Text style={styles.title}>NOTIZEN</Text>
        <TouchableOpacity
          style={{ position: "absolute", right: 5, top: 5 }}
          onPress={() => {
            UserHandler.getInstance().updateNote(
              props.text,
              props.id,
              props.shouldUpdate
            );
            if (props.text.length > 0) {
              NavigationHandler.getInstance().resultRef!.setState({
                note: {
                  note: props.text,
                  id: props.id,
                  showInResult: props.shouldUpdate,
                },
              });
            } else {
              NavigationHandler.getInstance().resultRef!.setState({
                note: undefined,
              });
            }
            navigation.goBack();
          }}
        >
          <Image
            source={props.showCheck ? check_ : cross}
            style={{
              width: 20,
              height: 20,
              tintColor: Colors.gold,
              resizeMode: "contain",
            }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    opacity: 0.5,
  },
  title: {
    fontWeight: "700",
    fontSize: 16,
    color: "#000",
  },
  user: {
    fontSize: 12,
    fontWeight: "500",
    lineHeight: 15,
    letterSpacing: 0.1,
    textAlign: "right",
    textTransform: "uppercase",
    color: Colors.gold,
    marginRight: 8,
  },
  notes_text: {
    fontSize: 15,
    color: "black",
  },
  profile_icon: {
    height: 24,
    width: 24,
    tintColor: Colors.gold,
  },
  container_two: {
    marginTop: 20,
    borderRadius: 10,
    padding: 12,
    paddingTop: 20,
    paddingBottom: 20,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    backgroundColor: Colors.background,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  profile_name: {
    fontSize: 12,
    fontWeight: "700",
    opacity: 0.5,
  },
  occupation_text: {
    fontSize: 12,
    fontWeight: "400",
    opacity: 0.5,
  },
  likes_text: {
    fontSize: 13,
    fontWeight: "700",
    opacity: 0.5,
    marginTop: 12,
  },
  container: {
    marginTop: 30,
    marginBottom: 30,
    borderRadius: 50,
    padding: 12,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    backgroundColor: Colors.background,
    width: "90%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
