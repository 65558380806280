import { CommonActions } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { NavigationProps } from "../App";
import logo_weiß from "../assets/images/logo_weiß.png";
import { CustomButton } from "../Components/CustomButton";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import NavigationHandler from "../Handlers/NavigationHandler";
import UserHandler from "../Handlers/UserHandler";

export function DisclaimerScreen({ navigation }: NavigationProps) {
  const [color, setColor] = useState(
    Platform.select({
      web: Colors.gold,
      android: Colors.gray_opacity,
      ios: Colors.gray_opacity,
    })
  );
  const [disabled, setDisabled] = useState(
    Platform.select({ web: true, android: true, ios: true })
  );

  const initial = () => {
    if (!NavigationHandler.getInstance().firstRoute)
      navigation.navigate("SelectMedicalCategoryScreen");
  };
  initial();

  useEffect(() => {
    UserHandler.getInstance().setLogged(true);
  });

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: "#000",
      }}
    >
      <Image
        source={logo_weiß}
        style={{
          height: 120,
          width: "90%",
          marginTop: 20,
          resizeMode: "contain",
          ...Platform.select({ web: { height: "25vh" } }),
        }}
      />
      <Text style={styles.h1Text}>HAFTUNGSAUSSCHLUSS</Text>
      <Text style={styles.h2Text}>MEDICEO</Text>
      <View style={styles.disclaimerContainer}>
        <ScrollView
          onScroll={({ nativeEvent }) => {
            if (isCloseToBottom(nativeEvent)) {
              setColor(Colors.gold);
              setDisabled(false);
            }
          }}
          scrollEventThrottle={400}
        >
          <Text style={styles.disclaimerText}>
            1) Die MEDICEO Applikation („App“) ist ausschließlich für Ärztinnen
            und Ärzte bzw. Angehörige medizinischer Fachkreise bestimmt. Mit dem
            Download, der Installation und der Nutzung dieser App versichern
            Sie, dass Sie der o.g. Personengruppe angehören. Es wird keine
            Garantie für die Vollständigkeit, Korrektheit oder
            Funktionsfähigkeit der MEDICEO App gegeben.
          </Text>
          <Text style={styles.disclaimerText}>
            2) Der Zweck der MEDICEO App ist die unterstützende Information
            medizinischer Fachkräfte. Die Empfehlungen der App orientieren sich
            an den Leitlinien der jeweiligen Fachgesellschaften. Es wird
            ausdrücklich daraufhin gewiesen, dass Abweichungen von diesen
            Leitlinien möglich sind und in der App enthalten sein können.
            Insbesondere sind die Empfehlungen unabhängig von Sponsoren,
            pharmazeutischen Unternehmen oder anderen dritten Parteien.
          </Text>
          <Text style={styles.disclaimerText}>
            3) Die MEDICEO App ersetzt nicht professionelles medizinisches
            Urteilsvermögen. Die Verantwortung und Haftung für die Richtigkeit
            der Therapie und evtl. Patientenschädigungen liegt ausschließlich
            beim Anwender bzw. dem jeweils behandelnden Arzt. Die Autoren,
            Sponsoren und Vertreiber der App sowie MEDICEO GmbH sind von
            jeglicher Haftung für Nebenwirkungen oder Folgen, die sich aus der
            Verwendung der Medify App im Rahmen der Patientenbehandlung ergeben,
            ausdrücklich ausgeschlossen.
          </Text>
          <Text style={styles.disclaimerText}>
            4) Der Anwender verpflichtet sich, Updates zu installieren, die über
            die jeweiligen App-Stores angezeigt werden. MEDICEO GmbH übernimmt
            keine Verantwortung oder Haftung für Fehler, Auslassungen oder
            veraltetes Material und ist auch nicht verpflichtet, die App
            dauerhaft zur Verfügung zu stellen.
          </Text>
          <Text style={styles.disclaimerText}>
            5) Mit der Nutzung der App stimmen Sie den o.g. Nutzungsbedingungen
            zu. Insbesondere stimmen Sie zu, dass MEDICEO GmbH nicht haftbar ist
            für jegliche Fehler, Unvollständigkeiten oder Schäden, auch
            Personenschäden, im Zusammenhang mit der Nutzung der App.
          </Text>
          <Text style={styles.disclaimerText}>©2022 MEDICEO GmbH</Text>
        </ScrollView>
      </View>
      <View style={styles.buttonContainer}>
        <CustomButton
          backgroundColor={Colors.black}
          foregroundColor={color}
          borderColor={color}
          text="AKZEPTIEREN"
          //enabled={disabled}
          action={() => {
            const resetAction = CommonActions.reset({
              index: 1,
              routes: [{ name: "SelectMedicalCategoryScreen", params: {} }],
            });
            navigation.dispatch(resetAction);
          }}
        />
      </View>
    </SafeAreaView>
  );
}

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
  const paddingToBottom = 20;
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
};

const styles = StyleSheet.create({
  h1Text: {
    fontSize: 18,
    color: "#fff",
    marginTop: 12,
    fontWeight: "700",
    letterSpacing: 0.05,
  },
  h2Text: {
    fontSize: 14,
    color: "#c4c4c4",
    marginTop: 3,
    fontWeight: "700",
  },
  confirmText: {
    fontSize: 12,
    color: "#000",
    fontWeight: "700",
    marginTop: 38,
    textAlign: "center",
    paddingLeft: 40,
    paddingRight: 40,
  },
  buttonContainer: {
    width: "85%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  disclaimerContainer: {
    width: "90%",
    maxWidth: Sizes.containerWidth,
    marginTop: 20,
    flex: 1,
    borderRadius: 10,
    backgroundColor: "#000",
    shadowColor: "#fff",
    padding: 12,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.35,
    elevation: 4,
    shadowRadius: 4,
  },
  disclaimerText: {
    marginBottom: 15,
    paddingRight: 12,
    fontSize: 12,
    color: "#fff",
    fontWeight: "400",
    lineHeight: 15,
  },
});
