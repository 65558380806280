import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { Platform } from "react-native";
import publicIP from "react-native-public-ip";
import uuid from "react-native-uuid";
import { VERSION } from "../Constants/Versioning";
import { analytics } from "../LoginScreens/LaunchScreen";
import NavigationHandler from "./NavigationHandler";
import UserHandler from "./UserHandler";

export class AnalyticsHandler {
  static _instance;

  _conversionID = undefined;

  getConversionId() {
    if (this._conversionID === undefined) {
      this._conversionID = uuid.v4().toString();
    }
    return this._conversionID;
  }

  static getInstance() {
    if (this._instance === undefined) {
      this._instance = new AnalyticsHandler();
    }
    return this._instance;
  }

  createSession(id, properties) {
    if (UserHandler.getInstance().getCurrentUser().role === "dev") return;
    if (analytics !== undefined) setUserId(analytics, id);
    if (properties !== undefined) {
      if (analytics !== undefined) setUserProperties(analytics, properties);
    }
  }

  logCustomAnalytics(title, data) {
    if (UserHandler.getInstance().getCurrentUser().role === "dev") return;
    if (analytics !== undefined) logEvent(analytics, title, data);
  }

  logResultPage(res) {
    if (UserHandler.getInstance().getCurrentUser().role === "dev") return;
    (async () => {
      try {
        let response = await fetch(
          "https://api.medi.ceo/result_component_event",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: this.createUID(),
              session_data: this.getSessionData(),
              event_data: {
                medical_category:
                  NavigationHandler.getInstance().currentSubject,
                category: NavigationHandler.getInstance().currentTarget,
                result_id: res,
                path: NavigationHandler.getInstance().getFullPath(),
              },
            }),
          }
        );
      } catch {
        // TODO CATCH
      }
    })();
  }

  logSelectMedicalCategory(category) {
    if (UserHandler.getInstance().getCurrentUser().role === "dev") return;
    (async () => {
      try {
        let response = await fetch(
          "https://api.medi.ceo/select_medical_category",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: this.createUID(),
              session_data: this.getSessionData(),
              event_data: {
                medical_category: category,
              },
            }),
          }
        );
      } catch {
        // TODO CATCH
      }
    })();
  }

  logSelectCategory(category) {
    if (UserHandler.getInstance().getCurrentUser().role === "dev") return;
    (async () => {
      try {
        let response = await fetch(
          "https://api.medi.ceo/select_category_component",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: this.createUID(),
              session_data: this.getSessionData(),
              event_data: {
                medical_category:
                  NavigationHandler.getInstance().currentSubject,
                category: category,
              },
            }),
          }
        );
      } catch {
        // TODO CATCH
      }
    })();
  }

  logDecisionTreeComponent(node) {
    if (UserHandler.getInstance().getCurrentUser().role === "dev") return;
    (async () => {
      try {
        let response = await fetch(
          "https://api.medi.ceo/decision_tree_component_event",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: this.createUID(),
              session_data: this.getSessionData(),
              event_data: {
                medical_category:
                  NavigationHandler.getInstance().currentSubject,
                category: NavigationHandler.getInstance().currentTarget,
                node: node,
                path: NavigationHandler.getInstance().getFullPath(),
              },
            }),
          }
        );
      } catch {
        // TODO CATCH
      }
    })();
  }

  logLogin() {
    if (UserHandler.getInstance().getCurrentUser().role === "dev") return;
    let t = setInterval(() => {
      clearInterval(t);
      (async () => {
        let ip = await publicIP();

        try {
          let reponse = await fetch("https://api.medi.ceo/login", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: this.createUID(),
              ip: ip,
              meta_data: this.getMetaData(),
              session_data: this.getSessionData(),
            }),
          });
        } catch {
          // TODO CATCH
        }
      })();
    }, 100);

    if (analytics !== undefined) logEvent(analytics, "login");
  }

  getMetaData() {
    return {
      device_data: {
        version: VERSION,
        device: Platform.OS,
        os: Platform.OS,
      },
    };
  }

  logPaymentDone(subscription, price, receipt) {
    if (UserHandler.getInstance().getCurrentUser().role === "dev") return;
    (async () => {
      try {
        let response = await fetch("https://api.medi.ceo/payment", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: this.createUID(),
            session_data: this.getSessionData(),
            role: UserHandler.getInstance().getCurrentUser().role,
            subscription: subscription,
            price: price,
            payment_id: receipt,
          }),
        });
      } catch {
        // TODO CATCH
      }
    })();
  }

  getSessionData() {
    let uid = "undefined";

    if (UserHandler.getInstance().getCurrentUser() !== undefined) {
      uid = UserHandler.getInstance().getCurrentUser().uuid;
    }
    return {
      session_id: UserHandler.getInstance().getSessionId(),
      user_id: uid,
    };
  }

  logPreRegistration() {
    let user = UserHandler.getInstance().getCurrentUser();
    let role = "";
    if (user === undefined) role = "";
    else role = user.role;
    if (role === "dev") return;
    (async () => {
      let ip = await publicIP();

      try {
        let reponse = await fetch(
          "https://api.medi.ceo/pre_registration_event ",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: this.createUID(),
              meta_data: this.getMetaData(),
              session_data: {
                conversion_id: this.getConversionId(),
              },
            }),
          }
        );
      } catch {
        // TODO CATCH
      }
    })();
  }

  logScreenEvent(name, event, value) {
    let user = UserHandler.getInstance().getCurrentUser();
    let role = "";
    if (user === undefined) role = "";
    else role = user.role;
    if (role === "dev") return;
    (async () => {
      try {
        let reponse = await fetch("https://api.medi.ceo/screen_event", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: this.createUID(),
            session_data: this.getSessionData(),
            event_data: {
              screen: name,
              event: event,
              value: value,
            },
          }),
        });
      } catch {
        // TODO CATCH
      }
    })();
  }

  logUpdateProfile() {
    if (UserHandler.getInstance().getCurrentUser().role === "dev") return;
    (async () => {
      try {
        const user = UserHandler.getInstance().getCurrentUser();
        let reponse = await fetch("https://api.medi.ceo/update_profile", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: this.createUID(),
            session_data: this.getSessionData(),
            event_data: {
              first_name: user.firstname,
              last_name: user.lastname,
              clinic: user.clinic,
              semester: user.semester,
              used_specific_codes: 0,
              password: user.password,
            },
          }),
        });
      } catch {
        // TODO CATCH
      }
    })();
  }

  logSignUp(props) {
    if (UserHandler.getInstance().getCurrentUser().role === "dev") return;
    const user = UserHandler.getInstance().getCurrentUser();

    (async () => {
      try {
        let response = await fetch("https://api.medi.ceo/register", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: this.createUID(),
            session_data: {
              conversion_id: this.getConversionId(),
              user_id: user.uuid,
            },
            user_data: {
              first_name: user.firstname,
              last_name: user.lastname,
              email: user.email,
              clinic: user.clinic,
              role: user.role,
              semester: user.semester,
              used_specific_codes: 0,
              password: user.password,
            },
          }),
        });
      } catch {}
    })();

    if (analytics !== undefined) logEvent(analytics, "sign_up", props);
  }

  logScreen(name) {
    if (UserHandler.getInstance().getCurrentUser().role === "dev") return;
    if (analytics !== undefined)
      logEvent(analytics, "screen_view", { screen_name: name });
  }

  logSelectContent(type, id) {
    if (UserHandler.getInstance().getCurrentUser().role === "dev") return;
    if (analytics !== undefined)
      logEvent(analytics, "select_content", {
        content_type: type,
        item_id: id,
      });
  }

  createUID() {
    return uuid.v4().toString();
  }
}
