export const universities = [
  { value: "RWTH Aachen " },
  { value: "Universität Augsburg " },
  {
    value: "Humboldt-Universität zu Berlin (Charité)",
  },
  { value: "Ruhr-Universität Bochum " },
  { value: "Universität Bielefeld" },
  {
    value: "Friedrich-Wilhelms-Universität Bonn",
  },
  {
    value: "Technische Universität Dresden ",
  },
  {
    value: "Heinrich-Heine-Universität Düsseldorf ",
  },
  {
    value: "Friedrich-Alexander-Universität Erlangen-Nürnberg ",
  },
  {
    value: "Universität Duisburg-Essen ",
  },
  {
    value: "Johann-Wolfgang-Goethe-Universität Frankfurt am Main ",
  },
  {
    value: "Albert-Ludwigs-Universität Freiburg ",
  },
  {
    value: "Justus-Liebig-Universität Gießen ",
  },
  {
    value: "Georg-August-Universität Göttingen ",
  },
  {
    value: "Ernst-Moritz-Arndt-Universität Greifswald ",
  },
  {
    value: "Martin-Luther-Universität Halle-Wittenberg ",
  },
  { value: "Universität Hamburg " },
  {
    value: "Medizinische Hochschule Hannover ",
  },
  {
    value: "Ruprecht-Karls-Universität Heidelberg ",
  },
  {
    value: "Universität des Saarlandes Homburg ",
  },
  {
    value: "Friedrich-Schiller-Universität Jena ",
  },
  {
    value: "Christian-Albrechts-Universität Kiel ",
  },
  { value: "Universität Köln " },
  { value: "Universität Leipzig " },
  { value: "Universität Lübeck " },
  {
    value: "Otto-von-Guericke-Universität Magdeburg ",
  },
  {
    value: "Medizinische Fakultät Mannheim der Universität Heidelberg ",
  },
  {
    value: "Johannes-Gutenberg-Universität Mainz ",
  },
  {
    value: "Philipps-Universität Marburg ",
  },
  {
    value: "Ludwig-Maximilians-Universität München ",
  },
  {
    value: "Technische Universität München",
  },
  {
    value: "Westfälische Wilhelms-Universität Münster ",
  },
  {
    value: "Carl-von-Ossietzky-Universität Oldenburg ",
  },
  { value: "Universität Regensburg " },
  { value: "Universität Rostock " },
  {
    value: "Eberhard-Karls-Universität Tübingen ",
  },
  { value: "Universität Ulm " },
  {
    value: "Julius-Maximilians-Universität Würzburg ",
  },
  {
    value: "Universität Witten/Herdecke ",
  },
  {
    value: "Medizinische Hochschule Brandenburg ",
  },
  {
    value: "Paracelsus-Universität Standort Nürnberg ",
  },
  { value: "Kassel School of Medicine " },
  {
    value: "MSH Medical School Hamburg ",
  },
  { value: "Universität Innsbruck " },
  { value: "Universität Wien" },
  { value: "Universität Graz" },
  { value: "Universität Linz" },
  { value: "Universität Basel" },
  { value: "Universität Bern" },
  {
    value: "Asklepios Campus Hamburg - Semmelweis Universität",
  },
  { value: "Universität Zürich" },
];

export const mail_suffix = [
  "ukaachen.de",
  "rwth-aachen.de",
  "stud.uni-frankfurt.de",
  "med.uni-augsburg.de",
  "uni-a.de",
  "charite.de",
  "student.hu-berlin.de",
  "ruhr-uni-bochum.de",
  "edu.ruhr-uni-bochum.de",
  "uni-bielefeld.de",
  "ekvv.uni-bielefeld.de",
  "uni-bonn.de",
  "ukbonn.de",
  "tu-dresden.de",
  "mailbox.tu-dresden.de",
  "med.uni.duesseldorf.de",
  "uni-duesseldorf.de",
  "hhu.de",
  "uk-erlangen.de",
  "fau.de",
  "uk-essen.de",
  "stud.uni-due.de",
  "stud.uni-duisburg-essen.de",
  "kgu.de",
  "students.uni-freiburg.de",
  "med.uni-giessen.de",
  "",
  "uni-goettingen.de",
  "stud.uni-goettingen.de",
  "uni-greifswald.de",
  "uk-halle.de",
  "student.uni-halle.de",
  "uke.uni-hamburg.de",
  "studium.uni-hamburg.de",
  "mh-hannover.de",
  "stud.mh-hannover.de",
  "stud.uni-heidelberg.de",
  "uni-saarland.de",
  "med.uni-jena.de",
  "med.uni-kiel.de",
  "mail.uni-kiel.de",
  "smail.uni-koeln.de",
  "uk-koeln.de",
  "studserv.uni-leipzig.de",
  "student.uni-luebeck.de",
  "st.ovgu.de",
  "stud.uni-heidelberg.de",
  "students.uni-mainz.de",
  "students.uni-marburg.de",
  "campus.lmu.de",
  "tum.de",
  "mytum.de",
  "uni-muenster.de",
  "uni-oldenburg.de",
  "stud.uni-regensburg.de",
  "uni-rostock.de",
  "Nicole oder Lou Fragen",
  "student.uni-tuebingen.de",
  "uni-ulm.de",
  "stud-mail.uni-wuerzburg.de",

  "mhb-fontane.de",
  "@semmelweis-hamburg.de",
  "student.i-med.ac.at",
  "students.meduniwien.ac.at",
  "medunigraz.at",
  "students.jku.at",
  "unibas.ch",
  "students.unibe.ch",
  "uzh.ch",
  "medi.ceo",
];
