import {
  Image,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { NavigationProps } from "../App";
import back_arrow from "../assets/images/back_arrow.png";
import { Colors } from "../Constants/Colors";

import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import logo_white_background from "../assets/images/logo_white_background.png";
import doctor from "../assets/images/nurse.png";
import student from "../assets/images/student.png";
import { Sizes } from "../Constants/Sizes";
import UserHandler from "../Handlers/UserHandler";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";
import * as ScreenOrientation from "expo-screen-orientation";

export function RoleSelectionScreen() {
  const navigation = useNavigation();

  useEffect(() => {
    AnalyticsHandler.getInstance().logPreRegistration();
    if (Platform.OS === "web") return;
    ScreenOrientation.unlockAsync();
  }, []);

  return (
    <SafeAreaView
      style={{
        height: "100%",
        width: "100%",
        flex: 1,
        backgroundColor: "white",
      }}
    >
      <View style={{ width: "100%", alignItems: "center", marginTop: 12 }}>
        <Image
          source={logo_white_background}
          style={{
            width: "90%",
            height: 120,
            zIndex: 5,
            resizeMode: "contain",
          }}
        />
        <View
          style={{
            height: 2,
            width: "120%",
            backgroundColor: Colors.gold,
            position: "absolute",
            top: 60,
          }}
        />
      </View>
      <View style={{ flex: 1 }}>
        <View
          style={{ flex: 2, marginTop: 50, marginBottom: 50, width: "100%" }}
        >
          <Image
            source={{
              uri: "https://medi.ceo/wp-content/uploads/2022/10/PBLTF61-1536x864.png",
            }}
            style={{
              flex: 1,
              resizeMode: "contain",
              width: "100%",
              alignSelf: "center",
              maxWidth: Sizes.containerWidth,
            }}
          />
        </View>
        <View style={{ alignItems: "center", flex: 1 }}>
          <Text
            style={{
              color: Colors.black,
              fontSize: 20,
              fontWeight: "bold",
              marginBottom: 12,
            }}
          >
            MEDICEO® NUTZEN ALS
          </Text>
          <View
            style={{
              height: 1,
              width: "100%",
              backgroundColor: Colors.black_opacity,
            }}
          />
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              marginTop: 20,
              marginBottom: 20,
              width: "100%",
            }}
          >
            <TouchableOpacity
              style={{
                flex: 1,
                paddingLeft: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => {
                UserHandler.getInstance().role = "student";
                navigation.navigate("RegistrationScreen");
              }}
            >
              <Text
                style={{ fontSize: 32, fontWeight: "bold", color: Colors.gold }}
              >
                STUDENT
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => {
                UserHandler.getInstance().role = "doc";
                navigation.navigate("RegistrationScreen");
              }}
            >
              <Text
                style={{ fontSize: 32, fontWeight: "bold", color: Colors.gold }}
              >
                ARZT
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}

export function Role({ navigation }: NavigationProps) {
  const [selectedStudent, setSelectedStudent] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(false);

  return (
    <SafeAreaView style={styles.container}>
      <View
        style={[
          styles.centerContainer,
          {
            width: "85%",
            marginTop: 0,

            flexDirection: "column",
            justifyContent: "space-around",
            flex: 1,
          },
        ]}
      >
        <View style={{ width: "100%", alignItems: "center" }}>
          <Image
            source={logo_white_background}
            style={{
              width: "90%",
              height: 120,
              zIndex: 5,
              resizeMode: "contain",
              // ...Platform.select({
              //   web: { height: "20vh", marginBottom: 50 },
              // }),
            }}
          />
          <View
            style={{
              height: 2,
              width: "120%",
              backgroundColor: Colors.gold,
              position: "absolute",
              top: 60,
            }}
          />
        </View>
        <View
          style={{
            maxWidth: Sizes.containerWidth,
            width: "100%",
            flex: 1,
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            onPressIn={() => setSelectedDoc(true)}
            onPress={() => {
              UserHandler.getInstance().role = "doc";
              navigation.navigate("RegistrationScreen");
            }}
            onPressOut={() => setSelectedDoc(false)}
            style={{
              flex: 1,
              marginBottom: 25,
              alignItems: "center",
              justifyContent: "center",
            }}
            activeOpacity={0.5}
          >
            <View
              style={{
                flex: 3,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  flex: 2,
                  opacity: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    marginTop: 12,
                    marginBottom: 20,
                    fontSize: 18,
                    width: "100%",
                    textAlign: "center",
                    color: selectedDoc ? Colors.gold : Colors.black_opacity,
                    fontWeight: "600",
                  }}
                >
                  {"ARZT"}
                </Text>
                <Image
                  source={doctor}
                  style={{
                    width: 80,
                    height: 80,
                    ...Platform.select({ web: { width: 120 } }),
                    ...Platform.select({ web: { height: 120 } }),
                    resizeMode: "contain",
                    tintColor: selectedDoc ? Colors.gold : Colors.black_opacity,
                  }}
                />
              </View>
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  source={back_arrow}
                  style={{
                    resizeMode: "contain",
                    height: 48,
                    transform: [{ rotate: "180deg" }],
                    tintColor: selectedDoc ? Colors.gold : Colors.black_opacity,
                  }}
                />
              </View>
            </View>
          </TouchableOpacity>
          <View
            style={{
              height: 2,
              width: "100%",
              backgroundColor: Colors.black_opacity,
            }}
          />
          <TouchableOpacity
            onPressIn={() => setSelectedStudent(true)}
            onPress={() => {
              UserHandler.getInstance().role = "student";
              navigation.navigate("RegistrationScreen");
            }}
            onPressOut={() => setSelectedStudent(false)}
            style={{
              flex: 1,
              marginBottom: 25,
              alignItems: "center",
              justifyContent: "center",
            }}
            activeOpacity={0.5}
          >
            <View
              style={{
                flex: 3,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  flex: 2,
                  opacity: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    marginTop: 12,
                    marginBottom: 20,
                    fontSize: 18,
                    width: "100%",
                    textAlign: "center",
                    color: selectedStudent ? Colors.gold : Colors.black_opacity,
                    fontWeight: "600",
                  }}
                >
                  {"STUDENT"}
                </Text>
                <Image
                  source={student}
                  style={{
                    width: 80,
                    height: 80,
                    ...Platform.select({ web: { width: 120 } }),
                    ...Platform.select({ web: { height: 120 } }),
                    resizeMode: "contain",
                    tintColor: selectedStudent
                      ? Colors.gold
                      : Colors.black_opacity,
                  }}
                />
              </View>
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  source={back_arrow}
                  style={{
                    resizeMode: "contain",
                    height: 48,
                    transform: [{ rotate: "180deg" }],
                    tintColor: selectedStudent
                      ? Colors.gold
                      : Colors.black_opacity,
                  }}
                />
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={{
          width: "95%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: 30,
        }}
      >
        {/* <View style={{height: 1, width: "100%", backgroundColor: Colors.black_opacity,        marginBottom: 12}}/> */}
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("LoginScreen");
          }}
        >
          <Text style={styles.actionText}>Ich habe schon einen Account?</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  centerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  actionText: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 12,
    fontWeight: "700",
    opacity: 0.5,
    ...Platform.select({ web: { marginBottom: 30 } }),
  },
});
