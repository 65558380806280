import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  FlatList,
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import arrow_left from "../assets/images/arrow_right_search.png";
import bookmark from "../assets/images/bolt_single.png";
import { Header } from "../Components/Header";
import { NavBar } from "../Components/NavBar";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import { ContentHandler } from "../Handlers/ContentHandler";
import NavigationHandler from "../Handlers/NavigationHandler";
import { navigationRef } from "../Handlers/RootNavigation";
import UserHandler from "../Handlers/UserHandler";

export function BookmarksScreen({ route }) {
  const [bookmarks, setBookmarks] = useState(
    UserHandler.getInstance().getCurrentUser()!.bookmarks
  );

  const { isFromProfile } = route.params;

  const navigation = useNavigation();

  useEffect(() => {
    (async () => {
      let ra = [];
      for (const b of bookmarks) {
        let box = await ContentHandler.getInstance().request(b);
        ra.push({ id: b, title: box.title });
      }
      setBookmarks(ra);
    })();
  }, []);

  const renderItemTierOne = ({ item, index }) => {
    let paths = NavigationHandler.getInstance().getPathFromID(item.id);
    if (paths === null) return null;
    return (
      <View style={{ margin: 12, width: "100%" }}>
        <TouchableOpacity
          style={{ width: "100%" }}
          onPress={() => {
            NavigationHandler.getInstance().setCurrentResultID(item.id);
            NavigationHandler.getInstance().setShouldShowResult(true);
            NavigationHandler.getInstance().pa;
            NavigationHandler.getInstance().homeRef.setState({
              currentLevel: 5,
              isFromSearch: true,
              currentPath: paths?.path,
            });
            if (isFromProfile) navigation.goBack();
            navigation.goBack();
          }}
        >
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 12,
                  color: Colors.black_opacity,
                  marginBottom: 8,
                }}
              >
                {paths?.pathString}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: Colors.black_opacity,
                  fontSize: 15,
                }}
              >
                {item.title}
              </Text>
            </View>
            <Image
              source={arrow_left}
              style={{
                width: 10,

                flex: 0.175,
                height: 20,
                tintColor: Colors.black_opacity,
                resizeMode: "contain",
              }}
            />
          </View>
        </TouchableOpacity>
        <View
          style={{
            width: "100%",
            height: 1,
            opacity: 0.2,
            marginTop: 20,
            backgroundColor: "black",
          }}
        />
      </View>
    );
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          width: "100%",
          alignItems: "center",
          backgroundColor: Colors.white,
        }}
      >
        <Header isProfile={false} isDark={false} />
        <View
          style={{
            marginBottom: -50,
            paddingBottom: 20,
            zIndex: 10,
            marginTop: 75,
          }}
        >
          <TouchableOpacity
            onPress={() => navigationRef.goBack()}
            style={{ overflow: "visible" }}
          >
            <View
              style={{
                backgroundColor: Colors.gray,
                padding: 8,
                paddingLeft: 20,
                paddingRight: 20,
                borderBottomEndRadius: 10,
                borderBottomLeftRadius: 10,
                zIndex: 10,
                overflow: "visible",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontWeight: "700",
                  fontSize: 11,
                  zIndex: 10,
                }}
              >
                {NavigationHandler.getInstance().currentSubject}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <NavBar isNews={false} isHints={false} level={-1} issuer={this} />
        <View
          style={{
            width: "100%",
            alignItems: "center",
            flex: 1,
            marginBottom: 60,
            justifyContent: "flex-start",
          }}
        >
          <ScrollView
            style={{ width: "100%", flex: 1 }}
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "flex-start",
            }}
            horizontal={false}
          >
            <View
              style={{
                width: "100%",
                paddingTop: 55,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                maxWidth: Sizes.containerWidth,
              }}
            >
              <Image
                source={bookmark}
                style={{
                  width: 21,
                  height: 32,
                  marginRight: 16,
                  tintColor: Colors.gold,
                  alignSelf: "flex-end",
                }}
              />
              <Text style={styles.title}>SHORTCUTS</Text>
            </View>
            <View
              style={{
                width: "90%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
                maxWidth: Sizes.containerWidth,
              }}
            >
              {/* <View style={styles.container}>
                    <Image
                    source={search} style={{width: 16, height: 16, marginRight: 8}}/>
                    <TextInput style={{flex: 1, fontWeight: "400", fontSize: 12,}} placeholder={"Suchbegriff hier eingeben"} />
                </View> */}
              <FlatList
                data={bookmarks}
                renderItem={renderItemTierOne}
                style={{ width: "100%" }}
              />
              {bookmarks.length === 0 ? (
                <Text style={{ fontSize: 12, color: Colors.black_opacity }}>
                  Fügen Sie die wichtigsten Resultate zu Ihren Shortcuts hinzu.
                </Text>
              ) : null}
            </View>
          </ScrollView>
        </View>
      </View>
    </SafeAreaView>
  );
}

// function getBookmarkViews(issuer) {
//     const navigation = useNavigation();
//     const allBookmarks =

// }

const styles = StyleSheet.create({
  title: {
    fontWeight: "700",
    fontSize: 16,
    color: "#000",
  },
  container: {
    marginTop: 30,
    marginBottom: 30,
    borderRadius: 50,
    padding: 12,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    backgroundColor: Colors.background,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
