import Ionicons from "@expo/vector-icons/Ionicons";
import { useState } from "react";
import {
  Image,
  Keyboard,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import DropDownPicker from "react-native-dropdown-picker";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { NavigationProps } from "../App";
import logo from "../assets/images/logo.png";
import { CustomButton } from "../Components/CustomButton";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import { showErrorMessage } from "../Handlers/ErrorHandler/ErrorHandler";
import UserHandler from "../Handlers/UserHandler";
import { mail_suffix, universities } from "./ConstRegistryFiles";
import { queries } from "./SQLInjectionPrevention";

export function RegistrationScreen({ navigation }: NavigationProps) {
  const [email, setEmail] = useState<string>(" ");
  const [semester, setSemester] = useState<string>(
    UserHandler.getInstance().role === "doc" ? "0" : ""
  );
  const [prename, setPrename] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [clinic, setClinic] = useState<string>("");

  const [pwVisible, setPwVisible] = useState(true);
  const [open, setOpen] = useState(false);

  const [waiting, setWaiting] = useState(false);

  const [items, setItems] = useState(universities);

  return (
    <TouchableWithoutFeedback
      style={{ backgroundColor: "#fff" }}
      onPress={() =>
        Platform.select({ ios: Keyboard.dismiss, android: Keyboard.dismiss })
      }
      accessible={false}
    >
      <SafeAreaView
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "flex-start",
          backgroundColor: "#fff",
        }}
      >
        <ScrollView
          style={{
            width: "100%",
            paddingTop: 20,
          }}
        >
          <KeyboardAwareScrollView style={{ width: "100%" }}>
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Image
                source={logo}
                style={{
                  width: "70%",
                  height: 180,
                  resizeMode: "contain",
                  ...Platform.select({
                    web: { height: "20vh", marginBottom: 50 },
                  }),
                }}
              />

              <Text style={styles.welcomeText}>REGISTRIERUNG</Text>
              <Text style={styles.greetingText}>Jetzt Profil erstellen.</Text>
              <TextInput
                placeholder="Titel"
                style={[
                  styles.textField,
                  { width: "auto", marginRight: 8, minWidth: 150 },
                ]}
                onChangeText={(text) => {
                  setTitle(text);
                }}
              />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  width: "85%",
                  maxWidth: Sizes.containerWidth,
                }}
              >
                <TextInput
                  placeholder="Vorname"
                  style={[
                    styles.textField,
                    { width: "auto", flex: 1, marginRight: 8 },
                    prename === ""
                      ? {
                          borderColor: Colors.red,
                          borderWidth: 1,
                        }
                      : null,
                  ]}
                  onChangeText={(text) => {
                    setPrename(text);
                  }}
                />
                <TextInput
                  placeholder="Nachname"
                  style={[
                    styles.textField,
                    { width: "auto", flex: 1 },
                    surname === ""
                      ? {
                          borderColor: Colors.red,
                          borderWidth: 1,
                        }
                      : null,
                  ]}
                  onChangeText={(text) => {
                    setSurname(text);
                  }}
                />
              </View>
              {UserHandler.getInstance().role === "doc" ? (
                <TextInput
                  placeholder={"Krankenhaus/Praxis"}
                  style={[
                    styles.textField,
                    ,
                    clinic === ""
                      ? {
                          borderColor: Colors.red,
                          borderWidth: 1,
                        }
                      : null,
                  ]}
                  onChangeText={(text) => {
                    setClinic(text);
                  }}
                />
              ) : (
                // <View
                //   style={[
                //     styles.textField,
                //     {
                //       overflow: "visible",
                //       borderBottomEndRadius: open ? 0 : 80,
                //       borderBottomStartRadius: open ? 0 : 80,
                //       borderTopStartRadius: open ? 30 : 80,
                //       borderTopEndRadius: open ? 30 : 80,
                //       ...Platform.select({ web: { paddingTop: 8 } }),
                //       padding: 0,
                //       zIndex: 4,
                //     },
                //   ]}
                // >
                <TouchableOpacity
                  style={[
                    styles.textField,
                    clinic === ""
                      ? {
                          borderColor: Colors.red,
                          borderWidth: 1,
                          justifyContent: "center",
                        }
                      : { justifyContent: "center" },
                  ]}
                  onPress={() => {
                    UserHandler.getInstance()._setClinic = setClinic;
                    navigation.navigate("SelectUniversityScreen");
                  }}
                >
                  <Text
                    style={[
                      clinic === ""
                        ? {
                            color: "#757575",
                          }
                        : { color: "black" },
                    ]}
                  >
                    {clinic === "" ? "Universität" : clinic}
                  </Text>
                </TouchableOpacity>

                /* <DropDownPicker
                    open={open}
                    value={clinic}
                    items={items}
                    setOpen={setOpen}
                    setValue={setClinic}
                    setItems={setItems}
                    placeholder={"Universität auswählen"}
                    placeholderStyle={{ color: Colors.black, opacity: 0.2 }}
                    style={{
                      paddingStart: 12,
                      paddingEnd: 12,
                      backgroundColor: "transparent",
                      overflow: "visible",
                      borderColor: "red",
                      borderRadius: 20,
                      borderWidth: clinic === "" ? 1 : 0,
                    }}
                    dropDownContainerStyle={[
                      styles.textField,
                      {
                        zIndex: 20,
                        width: "100%",
                        borderWidth: 0,
                        height: 150,
                        borderBottomEndRadius: 30,
                        borderBottomStartRadius: 30,
                        overflow: "visible",
                      },
                    ]}
                  /> */
                // </View>
              )}
              {UserHandler.getInstance().role === "student" ? (
                <TextInput
                  placeholder={"Semester"}
                  style={[
                    styles.textField,
                    semester === ""
                      ? { borderColor: Colors.red, borderWidth: 1 }
                      : null,
                  ]}
                  onChangeText={(text) => {
                    setSemester(text);
                  }}
                />
              ) : null}

              <TextInput
                textContentType={"username"}
                placeholder={
                  UserHandler.getInstance().role === "student"
                    ? "Uni E-Mail"
                    : "E-Mail"
                }
                style={[
                  styles.textField,
                  validateEmail(email)
                    ? null
                    : { borderColor: "red", borderWidth: 1 },
                ]}
                keyboardType="email-address"
                onChangeText={(text) => {
                  setEmail(text);
                }}
              />
              <View style={{ width: "85%", maxWidth: Sizes.containerWidth }}>
                <TextInput
                  placeholder="Passwort"
                  textContentType={"password"}
                  style={[
                    styles.textField,
                    { width: "100%" },
                    password.length < 8
                      ? {
                          borderWidth: 1,
                          borderColor: "red",
                        }
                      : null,
                  ]}
                  secureTextEntry={pwVisible}
                  onChangeText={(text) => {
                    setPassword(text);
                  }}
                />
                <TouchableOpacity
                  style={{ position: "absolute", right: 20, top: 11 }}
                  onPress={() => setPwVisible(!pwVisible)}
                >
                  <Ionicons
                    name={pwVisible ? "eye-outline" : "eye-off-outline"}
                    size={24}
                    color={Colors.black_opacity}
                  />
                </TouchableOpacity>
              </View>
            </View>

            <View
              style={{
                width: "100%",

                alignItems: "center",
                marginTop: 30,
              }}
            >
              <View
                style={{
                  width: "85%",

                  alignItems: "center",
                }}
              >
                <CustomButton
                  backgroundColor="#fff"
                  foregroundColor={waiting ? Colors.black_opacity : Colors.gold}
                  text="REGISTRIEREN"
                  enabled={
                    !(
                      validateEmail(email) &&
                      password.length > 5 &&
                      prename !== "" &&
                      surname !== "" &&
                      clinic !== "" &&
                      semester !== ""
                    ) || waiting
                  }
                  action={async () => {
                    setWaiting(true);
                    if (
                      !checkInputs([
                        email,
                        prename,
                        surname,
                        password,
                        clinic,
                        semester,
                        title,
                      ])
                    ) {
                      setWaiting(false);
                      showErrorMessage({
                        title: "Ungültiges Zeichen eingegeben",
                        text: "Sie haben einen Text eingegeben, der nicht erlaubt ist. Bitte ändern Sie diesen. Falls das Problem weiter bestehen sollte, kontaktieren Sie uns unter support@medi.ceo",
                      });
                      return;
                    }
                    if (UserHandler.getInstance().role === "student") {
                      if (await isValidStudentMail(email)) {
                        const s = await UserHandler.getInstance().register(
                          email.trim(),
                          title.trim() + " " + prename.trim(),
                          surname.trim(),
                          password,
                          clinic.trim(),
                          semester.trim()
                        );
                        if (s) {
                          navigation.navigate("WaitingForRegistrationScreen");
                        } else {
                          showErrorMessage({
                            title: "Registrierung fehlgeschlagen",
                            text: "Versuchen Sie es erneut. Falles der Fehler besteht, kontaktieren Sie uns unter support@medi.ceo",
                          });
                        }
                      } else {
                        setWaiting(false);
                        showErrorMessage({
                          title: "Keine Studenten-E-Mail",
                          text: "Du hast keine gültige Studenten E-Mail Adresse eingegeben. Falls wir die Adresse noch nicht im System haben sollte, kontaktiere uns gerne unter support@medi.ceo. Vielen Dank!",
                        });
                      }
                    } else {
                      const s = await UserHandler.getInstance().register(
                        email.trim(),
                        title.trim() + " " + prename.trim(),
                        surname.trim(),
                        password,
                        clinic.trim(),
                        "0"
                      );
                      if (s) {
                        navigation.navigate("WaitingForRegistrationScreen");
                      } else {
                        setWaiting(false);
                        showErrorMessage({
                          title: "Registrierung fehlgeschlagen",
                          text: "Versuchen Sie es erneut. Falles der Fehler besteht, kontaktieren Sie uns unter support@medi.ceo",
                        });
                      }
                    }
                  }}
                  borderColor={Colors.gold}
                />
              </View>
              <View
                style={{
                  width: "85%",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("LoginScreen");
                  }}
                >
                  <Text style={styles.actionText}>Jetzt anmelden?</Text>
                </TouchableOpacity>
              </View>
            </View>
          </KeyboardAwareScrollView>
        </ScrollView>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
}

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function checkInputs(text: string[]) {
  let ra = [];
  for (const t of text) {
    ra.push(checkIfHasQuery(t));
  }
  if (ra.includes(false)) return false;
  return true;
}

function checkIfHasQuery(text: string) {
  for (const i of queries) {
    if (text.includes(i)) return false;
  }
  return true;
}

async function isValidStudentMail(email: string) {
  const t = email.split("@");
  const suffix = t[t.length - 1];

  try {
    const response = await fetch("https://api.medi.ceo", {
      method: "POST",
      body: JSON.stringify({
        suffix: suffix,
      }),
    });
    let status = await response.json();
    return status.status;
  } catch {
    return false;
  }
}

const styles = StyleSheet.create({
  textInput: {
    marginTop: 10,
    marginBottom: 10,
    padding: 16,
    borderColor: "#000",
    borderRadius: 10,
    borderWidth: 1.5,
    width: "85%",
    maxWidth: 500,
  },
  welcomeText: {
    fontSize: 20,
    fontWeight: "700",
  },
  greetingText: {
    marginTop: 3,
    fontSize: 14,
    fontWeight: "700",
    color: "#C4C4C4",
    marginBottom: 30,
  },
  textField: {
    backgroundColor: "#fafafa",
    shadowColor: "#000",
    shadowOpacity: 0.12,
    maxWidth: Sizes.containerWidth,
    borderRadius: 80,
    width: "85%",
    height: 50,
    padding: 12,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    marginBottom: 12,
    elevation: 2,
    zIndex: 0,
  },
  box: {
    backgroundColor: "#fff",
    borderRadius: 5,
    width: 126,
    height: 43,
    borderColor: "#c4c4c433",
    borderWidth: 1,
  },
  actionText: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 12,
    fontWeight: "700",
    opacity: 0.5,
    marginBottom: 50,
    marginTop: 12,
  },
});
