import { NavigationContainer } from "@react-navigation/native";
import { setCustomText } from "react-native-global-props";
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import {
  TransitionPresets,
  createStackNavigator,
} from "@react-navigation/stack";
import { useFonts } from "expo-font";
import { StatusBar } from "expo-status-bar";
import { Dimensions, DrawerLayoutAndroidBase, Platform } from "react-native";
import { Colors } from "./Constants/Colors";
import Root from "./Handlers/ErrorHandler/Root";
import { navigationRef } from "./Handlers/RootNavigation";
import { FirstLandingScreen } from "./LoginScreens/FirstLandingScreen";
import { ForgotPasswordScreen } from "./LoginScreens/ForgotPasswordScreen";
import { LaunchScreen } from "./LoginScreens/LaunchScreen";
import { LoginScreen } from "./LoginScreens/LoginScreen";
import { MobileFirstLandingScreen } from "./LoginScreens/MobileFirstLanding";
import { PreLoginDatenschutz } from "./LoginScreens/PreLoginDatenschutz";
import { PreLoginPrivacyPolicy } from "./LoginScreens/PreLoginPrivacyPolicy";
import { PWScreen } from "./LoginScreens/PWScreen";
import { RegistrationScreen } from "./LoginScreens/RegistrationScreen";
import { RoleSelectionScreen } from "./LoginScreens/RoleSelectionScreen";
import { WaitingForRegistrationScreen } from "./LoginScreens/WaitingForRegistrationScreen";
import { PaymentScreenWeb } from "./MainScreenComponents/PaymentScreenWeb";
import { BookmarksScreen } from "./Screens/BookmarksScreen";
import { CommentsScreen } from "./Screens/CommentsScreen";
import { CustomPlanPaymentScreen } from "./Screens/CustomPlanPaymentScreen";
import { DisclaimerScreen } from "./Screens/DisclaimerScreen";
import { FullPlanPaymentScreen } from "./Screens/FullPlanPaymentScreen";
import { HomeScreen } from "./Screens/HomeScreen";
import { MenuScreen } from "./Screens/MenuScreen";
import { ImpressScreen } from "./Screens/MenuScreens.tsx/ImpressScreen";
import { PrivacyPolicyScreen } from "./Screens/MenuScreens.tsx/PrivacyPolicyScreen";
import { TermsOfUseScreen } from "./Screens/MenuScreens.tsx/TermsOfUseScreen";
import { NewsOverviewScreen } from "./Screens/NewsOverviewScreen";
import { NotesOverviewScreen } from "./Screens/NotesOverviewScreen";
import { NotesScreen } from "./Screens/NotesScreen";
import { TutorialLanding } from "./Screens/TutorialScreens/TutorialLanding";
import { PaymentScreen } from "./Screens/PaymentScreen";
import { PracticalHintsScreen } from "./Screens/PracticalHintsScreen";
import { ProfileScreen } from "./Screens/ProfileScreen";
import { SearchScreen } from "./Screens/SearchScreen";
import { SelectMedicalCategoryScreen } from "./Screens/SelectMedicalCategoryScreen";
import { WebBrowserScreen } from "./Screens/WebBrowserScreen";
import { useCallback, useEffect } from "react";
import { SelectUniversityScreen } from "./LoginScreens/SelectUniversityScreen";

export type RootStackParamList = {
  FirstLandingScreen: undefined;
  RegistrationScreen: undefined;
  HomeScreen: undefined;
  LoginScreen: undefined;
  ProfileScreen: undefined;
  WaitingForRegistrationScreen: undefined;
  DisclaimerScreen: undefined;
  ForgotPasswordScreen: undefined;
  MenuScreen: undefined;
  PaymentScreen: undefined;
  MobileLandingScreen: undefined;
  PWScreen: undefined;
  LaunchScreen: undefined;
  WebBrowserScreen: undefined;
  PracticalHintsScreen: undefined;
  BookmarksScreen: undefined;
  CommentsScreen: undefined;
  NotesScreen: undefined;
  TutorialLanding: undefined;
  NotesOverviewScreen: undefined;
  TermsOfUseScreen: undefined;
  NewsOverviewScreen: undefined;
  PrivacyPolicyScreen: undefined;
  PreLoginPrivacyPolicy: undefined;
  PreLoginDatenschutz: undefined;
  SelectMedicalCategoryScreen: undefined;
  FullPlanPaymentScreen: undefined;
  RoleSelectionScreen: undefined;
  SearchScreen: undefined;
  PaymentScreenWeb: undefined;
  ImpressScreen: undefined;
  SelectUniversityScreen: undefined;
  CustomPlanPaymentScreen: undefined;
};

export type NavigationProps = NativeStackScreenProps<RootStackParamList>;

const Stack = createStackNavigator<RootStackParamList>();
const width = Dimensions.get("window").width;

function Navigator() {
  const [loaded] = useFonts({
    HelveticaNeue: require("./assets/fonts/HelveticaNeue.ttf"),
  });

  useEffect(() => {
    console.log("loaded", loaded);
    if (loaded) {
      setCustomText({
        style: {
          fontFamily:
            Platform.OS === "ios" ? "Helvetica Neue" : "HelveticaNeue",
        },
      });
    }
  }, [loaded]);

  return (
    <Root>
      <NavigationContainer ref={navigationRef}>
        <StatusBar style="dark" />
        <Stack.Navigator
          initialRouteName={
            Platform.OS === "web" && width < 790
              ? "MobileLandingScreen"
              : Platform.OS === "web"
              ? "LaunchScreen"
              : "LaunchScreen"
          } //MobileFirstLandding
          screenOptions={{
            headerShown: false,
            headerLeft: undefined,
            headerTintColor: Colors.gold,
            gestureEnabled: false,
            cardOverlayEnabled: true,
            cardStyle: { backgroundColor: "transparent" },
          }}
        >
          <Stack.Screen
            name="MobileLandingScreen"
            component={MobileFirstLandingScreen}
            options={{
              title: "MEDICEO®",
            }}
          />
          <Stack.Screen
            name="PWScreen"
            component={PWScreen}
            options={{
              title: "MEDICEO®",
            }}
          />

          {/* <Stack.Screen
            name="TutorialLanding"
            component={TutorialLanding}
            options={{
              title: "MEDICEO®",
            }}
          /> */}

          <Stack.Screen
            name="LaunchScreen"
            component={LaunchScreen}
            options={{
              title: "MEDICEO®",
            }}
          />
          <Stack.Screen
            name="FirstLandingScreen"
            component={FirstLandingScreen}
            options={{
              title: "MEDICEO®",
              headerShown: false,
            }}
          />

          <Stack.Screen
            name="RegistrationScreen"
            component={RegistrationScreen}
            options={{
              title: "Registrierung",
              headerShown: true,
            }}
          />
          <Stack.Screen
            name="SelectUniversityScreen"
            component={SelectUniversityScreen}
            options={{
              title: "Uni auswählen",
              headerShown: true,
            }}
          />

          <Stack.Screen
            name="LoginScreen"
            component={LoginScreen}
            options={{
              title: "Login",
              headerShown: true,
            }}
          />
          <Stack.Screen
            name="WaitingForRegistrationScreen"
            component={WaitingForRegistrationScreen}
            options={{
              title: "MEDICEO® - Registrierung",
              headerShown: false,
              //headerShown: false,
              headerTintColor: "#000",
            }}
          />
          <Stack.Screen
            name="DisclaimerScreen"
            component={DisclaimerScreen}
            options={{
              title: "MEDICEO® - Disclaimer",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
              headerTintColor: "#000",
            }}
          />

          <Stack.Screen
            name="ForgotPasswordScreen"
            component={ForgotPasswordScreen}
            options={{
              title: "Passwort vergessen",

              headerShown: true,
            }}
          />

          <Stack.Screen
            name="WebBrowserScreen"
            component={WebBrowserScreen}
            options={{
              title: "MEDICEO®",
            }}
          />
          <Stack.Screen
            name="PaymentScreen"
            component={PaymentScreen}
            options={{
              title: "Abo",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
              headerTintColor: "#000",
            }}
          />
          <Stack.Screen
            name="HomeScreen"
            component={HomeScreen}
            options={({ navigation }: NavigationProps) => ({
              title: "MEDICEO®",
              headerLargeTitle: true,
              headerTintColor: "#000",
              headerBackVisible: false,
              headerShown: false,
              headerLeft: () => null,
            })}
          />
          <Stack.Screen
            name="ProfileScreen"
            component={ProfileScreen}
            options={{
              title: "MEDICEO®",

              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
              headerTintColor: "#000",
              cardOverlayEnabled: true,
            }}
          />
          <Stack.Screen
            name="MenuScreen"
            component={MenuScreen}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="PracticalHintsScreen"
            component={PracticalHintsScreen}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="BookmarksScreen"
            component={BookmarksScreen}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="CommentsScreen"
            component={CommentsScreen}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="NotesScreen"
            component={NotesScreen}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="NotesOverviewScreen"
            component={NotesOverviewScreen}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="TermsOfUseScreen"
            component={TermsOfUseScreen}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="NewsOverviewScreen"
            component={NewsOverviewScreen}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="PrivacyPolicyScreen"
            component={PrivacyPolicyScreen}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="PreLoginPrivacyPolicy"
            component={PreLoginPrivacyPolicy}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: true,
            }}
          />
          <Stack.Screen
            name="PreLoginDatenschutz"
            component={PreLoginDatenschutz}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: true,
            }}
          />
          <Stack.Screen
            name="SelectMedicalCategoryScreen"
            component={SelectMedicalCategoryScreen}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="FullPlanPaymentScreen"
            component={FullPlanPaymentScreen}
            options={{
              title: "MEDICEO®",
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="RoleSelectionScreen"
            component={RoleSelectionScreen}
            options={{
              title: "Registrierung",
              headerShown: true,
            }}
          />
          <Stack.Screen
            name="CustomPlanPaymentScreen"
            component={CustomPlanPaymentScreen}
            options={{
              title: "MEDICEO®",
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="ImpressScreen"
            component={ImpressScreen}
            options={{
              title: "MEDICEO®",
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="PaymentScreenWeb"
            component={PaymentScreenWeb}
            options={{
              title: "MEDICEO®",
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="SearchScreen"
            component={SearchScreen}
            options={{
              title: "MEDICEO®",
              ...TransitionPresets.ModalFadeTransition,
              headerShown: false,
            }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </Root>
  );
}

export default Navigator;
