import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { Image, Linking, Text, View, TouchableOpacity } from "react-native";
import arrow from "../assets/images/arrow_landing.png";
import continue_web from "../assets/images/ContinueWithBrowser.png";
import download_ios from "../assets/images/DownloadAppStore.png";
import download_android from "../assets/images/DownloadPlayStore.png";
import schwarzer_stab from "../assets/images/schwarzer_stab.png";
import { Colors } from "../Constants/Colors";

export function MobileFirstLandingScreen() {
  let _timesShown = 0;

  useEffect(() => {
    (async () => {
      const timesShown = await AsyncStorage.getItem("times_shown");
      if (timesShown !== null && timesShown !== undefined) {
        _timesShown = timesShown;

        if (Number(timesShown) > 15) {
          nav.navigate("LaunchScreen");
        }
      }
    })();
  }, []);

  const nav = useNavigation();
  return (
    <View
      style={{
        backgroundColor: "#000",
        height: "100%",
        overflow: "hidden",
        width: "100%",
        padding: 20,
        alignItems: "center",
      }}
    >
      <Image
        style={{
          width: "120%",
          height: "120%",
          position: "absolute",
          marginLeft: 24,
          top: 0,
          resizeMode: "contain",
          opacity: 0.35,
          zIndex: -1,
        }}
        source={schwarzer_stab}
      />
      <Text
        style={{
          color: "#fff",
          fontSize: 20,
          fontWeight: "bold",
          marginTop: 100,
        }}
      >
        KENNEN SIE SCHON DIE
      </Text>
      <Text style={{ color: Colors.gold, fontSize: 35, fontWeight: "bold" }}>
        <Text style={{ color: "#fff" }}>MEDICEO®</Text> APP
      </Text>
      <Image
        source={arrow}
        style={{
          position: "absolute",
          right: 20,
          top: 200,
          height: 120,
          width: 100,
          resizeMode: "contain",
        }}
      />
      <View
        style={{
          flex: 1,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "50%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            source={{
              uri: "https://medi.ceo/wp-content/uploads/2022/09/screen.png",
            }}
            style={{ width: "90%", height: "80%", resizeMode: "contain" }}
          />
        </View>
        <View
          style={{
            width: "50%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TouchableOpacity
            style={{ width: 150, height: 80 }}
            onPress={() => {
              Linking.openURL(
                "https://apps.apple.com/de/app/mediceo/id1629718911"
              );
            }}
          >
            <Image
              source={download_ios}
              style={{
                width: "100%",
                height: "100%",
                resizeMode: "contain",
              }}
            />
          </TouchableOpacity>
          <Image
            source={download_android}
            style={{
              width: 150,
              height: 80,
              resizeMode: "contain",
              opacity: 0.5,
            }}
          />
        </View>
      </View>

      <View
        style={{
          height: "20%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ color: "white", fontWeight: "bold", fontSize: 15 }}>
          WEITER IN DER WEBVERSION
        </Text>
        <TouchableOpacity
          style={{ width: 140, height: 100 }}
          onPress={() => {
            const inc = _timesShown + 1;
            AsyncStorage.setItem("times_shown", inc + "");
            nav.navigate("LaunchScreen");
          }}
        >
          <Image
            source={continue_web}
            style={{ width: 140, height: 100, resizeMode: "contain" }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}
