import { ContentHandler } from "../ContentHandler";

interface RootObject {
  metadata: Metadata;
  sys: Sys2;
  fields: Fields;
}

export interface Fields {
  title: string;
  articleText: ArticleText;
  articleImage: Space;
  author: string;
  category: string;
  date: string;
  quelle: ArticleText;
}

interface ArticleText {
  data: Data;
  content: Content4[];
  nodeType: string;
}

interface Content4 {
  data: Data;
  content: Content3[];
  nodeType: string;
}

interface Content3 {
  data: Data;
  marks?: any[];
  value?: string;
  nodeType: string;
  content?: Content2[];
}

interface Content2 {
  data: Data;
  content: Content[];
  nodeType: string;
}

interface Content {
  data: Data;
  marks: any[];
  value: string;
  nodeType: string;
}

interface Data {}

interface Sys2 {
  space: Space;
  id: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  environment: Space;
  revision: number;
  contentType: Space;
  locale: string;
}

interface Space {
  sys: Sys;
}

interface Sys {
  type: string;
  linkType: string;
  id: string;
}

interface Metadata {
  tags: any[];
}

export async function getArticle(id: string) {
  try {
    let response = await fetch(
      "https://cdn.contentful.com/spaces/aepv59ipfyvq/environments/master/entries/" +
        id +
        "?access_token=Fbi4zRIrPkEh0YEacG7RGkLuDaMvMpy1mmfi7M1qruw"
    );
    if (response.status !== 200) {
      return null;
    }
    let data: RootObject = await response.json();
    let assetURL = await ContentHandler.getInstance().getAssetURL(
      data.fields.articleImage.sys.id
    );

    return { data: data.fields, image: assetURL };
  } catch {
    return null;
  }
}
