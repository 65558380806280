import {
  View,
  StyleSheet,
  Text,
  Image,
  TouchableWithoutFeedback,
} from "react-native";
import { useState } from "react";
import { Colors } from "../../Constants/Colors";
import circle_unselected from "../../assets/images/circle_unselected.png";
import circle_selected from "../../assets/images/circle_selected.png";
import RenderHTML from "react-native-render-html";
import NavigationHandler from "../../Handlers/NavigationHandler";
import { Sizes } from "../../Constants/Sizes";

interface DecisionTreeProps {
  title: string;
  color: string;
}

export function TextBox(props: DecisionTreeProps) {
  return (
    <View style={{ width: "100%" }}>
      <View style={[styles.container, { borderWidth: 0 }]}>
        <Text style={[styles.content_text, { color: props.color }]}>
          {props.title}
        </Text>
      </View>
    </View>
  );
}

const contentHtml = {
  p: {
    color: "#000",
    opacity: 0.5,
    fontSize: 12,
    lineHeight: 15,
    fontWeight: "700",
    marginRight: 20,
  },
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    borderWidth: 1,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
  content_text: {
    fontSize: Sizes.boxText + 2,
    lineHeight: Sizes.lineHeightBoxText,
    fontWeight: "700",
    marginRight: 20,
  },
});
