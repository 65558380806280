import { useNavigation } from "@react-navigation/native";
import AnimatedGradient from "../Components/AnimatedLinearGradient/index";
import { useEffect, useState } from "react";
import {
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { ActivityIndicator, DefaultTheme } from "react-native-paper";
import arrow_left from "../assets/images/arrow_left.png";
import arrow_right from "../assets/images/arrow_right.png";
import logo_white_background from "../assets/images/logo_weiß.png";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";
import { showErrorMessage } from "../Handlers/ErrorHandler/ErrorHandler";
import { PaymentHandler } from "../Handlers/PaymentHandler";
import { navigate, navigationRef } from "../Handlers/RootNavigation";
import UserHandler from "../Handlers/UserHandler";

// Check login input in firebase -> if EMAIL_VERIFIED=0 -> Return email bestätigen
// Login mit SHA512 verschlüsselung
// Passwort Vergessen?

const advanteges = [
  "Unser 'Rundum Sorglos Premium Modell'!",
  "alle Funktion von MEDICEO® sind für Sie zugänglich",
  "Erweiterungen der Inhalte werden laufend integriert",
  "immer auf dem aktuellen Stand",
  "über intelligente Pfadsysteme in Sekunden zu den benötigten Informationen gelangen",
  "Praktische Hinweise mit Bewertungsscores, Standartwerten usw.",
];

export function FullPlanPaymentScreen() {
  const navigation = useNavigation();
  const [isYearly, setIsYearly] = useState(true);
  const [isWaiting, setIsWaiting] = useState(false);
  const isStudent =
    UserHandler.getInstance().getCurrentUser()!.role === "student"; // TODO CHANGE

  const yearlyData = {
    price: isStudent ? "4,20 €" : "9,99 €",
    subTitle: "im Monat \n(Abrechnung jährlich,\n7 Tage Trial)",
  };

  const monthlyData = {
    price: isStudent ? "7,99 €" : "12,99 €",
    subTitle: "im Monat\n(monatlich kündbar)",
  };

  useEffect(() => {
    AnalyticsHandler.getInstance().logScreenEvent("Full Plan Screen", "opened");
  });

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: Colors.black,
        paddingTop:
          Platform.OS === "android" + 20 ? StatusBar.currentHeight : 20,
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          backgroundColor: Colors.white,
        }}
      >
        <View
          style={{
            position: "absolute",
            overflow: "hidden",
            width: "100%",
            height: 170,
            bottom: -50,
            left: 0,
            backgroundColor: Colors.black,
            borderTopRightRadius: 50,
            borderTopLeftRadius: 50,
            zIndex: 0,
          }}
        >
          <AnimatedGradient
            customColors={["#000", "#282828", "#333232", "#000000"]}
            speed={1500}
          />
        </View>

        <View
          style={{
            position: "absolute",
            width: "100%",
            height: 300,
            top: -50,
            left: 0,
            backgroundColor: Colors.black,
          }}
        >
          <View
            style={{
              position: "absolute",
              backgroundColor: Colors.white,
              bottom: 0,
              left: 0,
              height: 100,
              width: "100%",
              borderTopLeftRadius: 300,
            }}
          />
        </View>
        {/* <Header
           isProfile={false}
           isDark={false}
            issuer={this}/>*/}
        <View
          style={{
            flex: 1,
            width: "90%",

            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "10%",
            }}
          >
            <TouchableOpacity
              style={{ padding: 12 }}
              onPress={() => navigation.goBack()}
            >
              <Image
                style={{
                  width: 22,
                  height: 20,
                  tintColor: "white",
                  resizeMode: "contain",
                }}
                source={arrow_left}
              />
            </TouchableOpacity>
            <Text
              style={{
                color: "#ffffff",
                flex: 1,
                fontSize: 18,
                fontWeight: "800",
                marginLeft: 12,
              }}
            >
              PREMIUM MODELL
            </Text>

            <Image
              source={logo_white_background}
              style={{
                width: 100,
                marginRight: -20,
                height: 75,
                zIndex: 5,
                resizeMode: "contain",
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              marginTop: 25,
              marginBottom: 12,
            }}
          >
            <TouchableOpacity
              activeOpacity={0.7}
              style={{ width: "47%" }}
              onPress={() => setIsYearly(true)}
            >
              <View
                style={
                  isYearly
                    ? styles.container_selected
                    : styles.container_unselected
                }
              >
                <Text
                  style={
                    isYearly ? styles.text_selected : styles.text_unselected
                  }
                >
                  JÄHRLICH
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              activeOpacity={0.7}
              style={{ width: "47%" }}
              onPress={() => setIsYearly(false)}
            >
              <View
                style={
                  isYearly
                    ? styles.container_unselected
                    : styles.container_selected
                }
              >
                <Text
                  style={
                    isYearly ? styles.text_unselected : styles.text_selected
                  }
                >
                  MONATLICH
                </Text>
              </View>
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: "space-between",
                paddingBottom: 80,
                paddingTop: 40,
                maxHeight: 450,
              }}
            >
              {advanteges.map((a) => {
                return (
                  <View
                    style={{
                      flexDirection: "row",
                      width: "90%",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        width: 20,
                        height: 20,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 10,
                        backgroundColor: Colors.gold,
                        marginRight: 12,
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 12,
                          fontWeight: "800",
                        }}
                      >
                        {advanteges.indexOf(a) + 1}
                      </Text>
                    </View>
                    <Text
                      style={{
                        color: Colors.black_opacity,
                        fontSize: 14,
                        fontWeight: "700",
                      }}
                    >
                      {a}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
        <View
          style={{
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <View
            style={{
              width: "85%",
              justifyContent: "center",
              flexDirection: "row",
              zIndex: 2,
            }}
          >
            <View
              style={{
                width: "95%",
                alignItems: "center",
                justifyContent: "space-between",
                zIndex: 2,
                flexDirection: "row",
              }}
            >
              {!isWaiting ? (
                <TouchableOpacity
                  style={{ alignItems: "center", justifyContent: "center" }}
                  onPress={async () => {
                    setIsWaiting(true);
                    await PaymentHandler.getInstance().makePayment(
                      isYearly
                        ? isStudent
                          ? "student.mediceo.year"
                          : "doc.mediceo.year"
                        : isStudent
                        ? "student.mediceo.month"
                        : "doc.mediceo.month",
                      function paymentFinished(success: boolean) {
                        AnalyticsHandler.getInstance().logScreenEvent(
                          "Payment Flow",
                          "ended",
                          success.toString()
                        );
                        if (success) {
                          UserHandler.getInstance().getCurrentUser().paymentPlan =
                            {
                              plan: "full",
                              categories: [],
                            };
                          UserHandler.getInstance().update();
                          UserHandler.getInstance().updateOfflineUser();
                          navigation.reset({
                            index: 0,
                            routes: [{ name: "SelectMedicalCategoryScreen" }],
                          });
                        } else {
                          setIsWaiting(false);
                        }
                      }
                    );
                  }}
                >
                  <View style={styles.container}>
                    <View
                      style={{
                        marginLeft: 8,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text style={styles.box_title}>Jetzt abschließen</Text>
                      <Image
                        style={{
                          width: 15,
                          height: 20,
                          marginLeft: 8,
                          marginRight: 8,
                          resizeMode: "contain",
                          opacity: 0.5,
                        }}
                        source={arrow_right}
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              ) : (
                <View
                  style={{
                    height: 44,
                    flex: 1,
                    marginTop: 30,
                    marginBottom: 30,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ActivityIndicator
                    theme={{
                      colors: {
                        ...DefaultTheme.colors,
                        primary: Colors.gold,
                      },
                    }}
                    size="small"
                  />
                </View>
              )}
              <View>
                <Text
                  style={{
                    color: "white",
                    fontSize: 20,
                    fontWeight: "800",
                    textAlign: "right",
                    marginBottom: 8,
                  }}
                >
                  {isYearly ? yearlyData.price : monthlyData.price}
                </Text>
                <Text
                  style={{
                    color: "white",
                    fontSize: 12,
                    fontWeight: "300",
                    textAlign: "right",
                  }}
                >
                  {isYearly ? yearlyData.subTitle : monthlyData.subTitle}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  text_selected: {
    color: "white",
    fontSize: 17,
    fontWeight: "800",
  },
  text_unselected: {
    color: Colors.black_opacity,
    fontSize: 15,
    fontWeight: "500",
  },
  container_selected: {
    width: "100%",
    height: 75,
    backgroundColor: Colors.gold,
    borderRadius: 12,
    elevation: 2,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
  },
  container_unselected: {
    width: "100%",
    height: 75,
    backgroundColor: Colors.white,
    borderRadius: 12,
    borderWidth: 0,
    borderColor: Colors.black_opacity,
    justifyContent: "center",
    alignItems: "center",
    elevation: 2,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
  },
  loginScreenButton: {
    marginRight: 40,
    marginLeft: 40,
    marginTop: 10,
    marginBottom: 10,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 50,
    borderWidth: 0,
  },
  loginText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 19,
    letterSpacing: 0.5,
    paddingLeft: 16,
    paddingRight: 16,
  },
  loginScreenButtonCustom: {
    marginRight: 40,
    marginLeft: 40,
    marginTop: 10,
    marginBottom: 10,
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 50,
    borderWidth: 2,
  },
  loginTextCustom: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    paddingLeft: 16,
    paddingRight: 16,
  },
  container: {
    marginTop: 30,
    marginBottom: 30,
    borderRadius: 50,
    padding: 12,
    elevation: 3,
    shadowColor: "#fff",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    backgroundColor: Colors.background,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 12,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    width: "80%",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  box_title: {
    fontSize: 12,
    fontWeight: "700",
    opacity: 0.5,
    marginLeft: 12,
  },
  welcomeText: {
    fontSize: 16,
    fontWeight: "700",
  },
  greetingText: {
    marginTop: 25,
    fontSize: 12,
    lineHeight: 18,
    paddingLeft: 12,
    paddingRight: 12,
    textAlign: "left",
    fontWeight: "400",
    color: "#8B8B8B",
    marginBottom: 42,
  },
  textField: {
    backgroundColor: "#fafafa",
    shadowColor: "#000",
    shadowOpacity: 0.12,
    borderRadius: 80,
    width: "85%",
    height: 50,
    padding: 12,
    elevation: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    marginBottom: 12,
    maxWidth: Sizes.containerWidth,
  },
  box: {
    backgroundColor: "#fff",
    borderRadius: 5,
    width: 126,
    height: 43,
    borderColor: "#c4c4c433",
    borderWidth: 1,
  },
  actionText: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 12,
    fontWeight: "700",
    opacity: 0.5,
    marginBottom: 20,
    marginTop: 12,
  },
});
