import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import arrow_left from "../assets/images/arrow_left_gold.png";
import serenge_image from "../assets/images/child.png";
import diabetic_image from "../assets/images/diabetic.png";
import ekg from "../assets/images/ekg.png";
import general from "../assets/images/general.png";
import glasses from "../assets/images/glass.png";
import liver_image from "../assets/images/heart_liver.png";
import old_image from "../assets/images/old.png";
import specific from "../assets/images/specific.png";
import { CategoryBox } from "../Components/CategoryBox";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import { getBoxWidth } from "../Handlers/LayoutHandler";
import NavigationHandler from "../Handlers/NavigationHandler";
import UserHandler from "../Handlers/UserHandler";
import { HomeScreen, MainScreenComponentProps } from "../Screens/HomeScreen";

export function SelectCategoryComponent(props: MainScreenComponentProps) {
  const navigation = useNavigation();
  const { width } = useWindowDimensions();

  return (
    <View
      style={{
        zIndex: 1,
        justifyContent: "flex-start",
        alignItems: "center",
        width: "90%",
        flex: 1,
        paddingTop: 20,
        maxWidth: Sizes.containerWidth,
      }}
    >
      <View
        style={{
          position: "absolute",
          top: 40,
          right: 0,
          zIndex: 10,
          overflow: "visible",
        }}
      >
        <TouchableOpacity
          onPress={() => navigation.navigate("SearchScreen")}
          style={{ overflow: "visible" }}
        >
          <View
            style={{
              padding: 12,
              elevation: 5,
              alignItems: "center",
              shadowColor: "#000",
              width: 44,
              height: 44,
              justifyContent: "center",
              shadowOpacity: 0.12,
              shadowOffset: { width: 0, height: 2 },
              shadowRadius: 4,
              backgroundColor: "white",
              borderRadius: 6,
              flexDirection: "row",
            }}
          >
            <Image
              style={{
                width: 35,
                height: 35,
                marginRight: 1,
                opacity: 0.5,
                resizeMode: "contain",
              }}
              source={glasses}
            />
          </View>
        </TouchableOpacity>
      </View>
      <View
        style={{
          alignItems: "flex-start",
          width: "100%",
          marginBottom: -12,
          marginTop: 20,
        }}
      >
        <Text style={styles.title}>
          {UserHandler.getInstance().getCurrentUser().role === "doc"
            ? "WÄHLEN SIE EINE KATEGORIE"
            : "WÄHLE EINE KATEGORIE"}
        </Text>
        <Text style={styles.sub_title}>
          {UserHandler.getInstance().getCurrentUser().role === "doc"
            ? "Und finden Sie sofort die passende\nBehandlung für Ihren Patienten"
            : "Und finde sofort die passende\nBehandlung für Deinen Patienten"}
        </Text>
      </View>
      <Selection issuer={props.issuer} width={width} />
      <View
        pointerEvents="auto"
        style={{
          position: "absolute",
          bottom: 12,
          left: 0,
          width: "100%",
          height: 30,
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "flex-end",
          overflow: "visible",
        }}
      >
        <View
          style={{
            overflow: "visible",
            padding: 20,
            position: "absolute",
            left: -20,
            bottom: -8,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              navigation.goBack(),
                (NavigationHandler.getInstance().currentSubject = "");
            }}
            style={{ overflow: "visible" }}
          >
            <View
              style={{
                padding: 12,
                elevation: 3,
                alignItems: "center",
                shadowColor: "#000",
                width: 100,
                justifyContent: "center",
                shadowOpacity: 0.12,
                shadowOffset: { width: 0, height: 2 },
                shadowRadius: 4,
                backgroundColor: "white",
                borderRadius: 6,
                flexDirection: "row",
              }}
            >
              <Image style={styles.arrowImage} source={arrow_left} />
              <Text
                style={{ fontSize: 12, fontWeight: "700", color: Colors.gold }}
              >
                {"zurück"}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <Text
          style={{
            width: "50%",
            textAlign: "right",
            fontSize: 10,
            paddingBottom: 4,
            opacity: 0.5,
            position: "absolute",
            right: 0,
            bottom: 18,
          }}
        >
          <Text style={{ fontWeight: "700" }}>Stand der Daten:{"\n"}</Text>
          {NavigationHandler.getInstance().getAccessTime()}
        </Text>
      </View>
    </View>
  );
}

function Selection(props: { issuer: HomeScreen; width: number }) {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  const { issuer, width } = props;
  const cs = NavigationHandler.getInstance().currentSubject;
  if (cs === "Anästhesiologie") {
    return (
      <View
        onLayout={(event) => {
          const { width, height } = event.nativeEvent.layout;
          setDimensions({ height: height, width: width });
        }}
        style={{
          alignItems: "center",
          justifyContent: "center",
          flex: 2,
          width: "100%",
          marginBottom: 100,
          maxWidth: Sizes.containerWidth,
          //...Platform.select({ web: { maxHeight: Sizes.containerWidth } }),
        }}
      >
        <View
          style={{
            flex: 1,
            maxWidth: width - 20,
            flexDirection: "row",
            alignItems: "flex-end",
            width: "100%",
            marginBottom: 12,
            justifyContent: "center",
            marginTop: 35,
          }}
        >
          <CategoryBox
            text={"Kinder"}
            width={getBoxWidth(dimensions.width, dimensions.height)}
            image={serenge_image}
            action={() => {
              NavigationHandler.getInstance().setCurrentTarget("Kinder");
              issuer.setState({ currentLevel: 1 });
            }}
          />

          <CategoryBox
            text={"Herz- & Nierenkranke"}
            width={getBoxWidth(dimensions.width, dimensions.height)}
            image={liver_image}
            action={() => {
              NavigationHandler.getInstance().setCurrentTarget(
                "Herz- & Nierenkranke"
              );
              issuer.setState({ currentLevel: 1 });
            }}
          />
        </View>
        <View
          style={{
            flex: 1,
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "center",
            maxWidth: width - 20,
            flexDirection: "row",
          }}
        >
          <CategoryBox
            text={"Diabetiker"}
            image={diabetic_image}
            width={getBoxWidth(dimensions.width, dimensions.height)}
            action={() => {
              NavigationHandler.getInstance().setCurrentTarget("Diabetiker");
              issuer.setState({ currentLevel: 1 });
            }}
          />

          <CategoryBox
            text={"Alte & Adipöse"}
            width={getBoxWidth(dimensions.width, dimensions.height)}
            image={old_image}
            action={() => {
              NavigationHandler.getInstance().setCurrentTarget(
                "Alte & Adipöse"
              );
              issuer.setState({ currentLevel: 1 });
            }}
          />
        </View>
      </View>
    );
  } else if (cs === "Perioperative Komplikationen") {
    return (
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          marginTop: 35,
          marginBottom: 100,
        }}
        onLayout={(event) => {
          const { width, height } = event.nativeEvent.layout;
          setDimensions({ height: height, width: width });
        }}
      >
        <CategoryBox
          text={"Allgemein"}
          image={general}
          width={getBoxWidth(dimensions.width, dimensions.height)}
          action={() => {
            NavigationHandler.getInstance().setCurrentTarget("Allgemein");
            issuer.setState({ currentLevel: 1 });
          }}
        />

        <CategoryBox
          text={"Spezifisch"}
          width={getBoxWidth(dimensions.width, dimensions.height)}
          image={specific}
          action={() => {
            NavigationHandler.getInstance().setCurrentTarget("Spezifisch");
            issuer.setState({ currentLevel: 1 });
          }}
        />
      </View>
    );
  } else {
    return (
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          marginTop: 35,
          marginBottom: 100,
        }}
        onLayout={(event) => {
          const { width, height } = event.nativeEvent.layout;
          setDimensions({ height: height, width: width });
        }}
      >
        {/* <CategoryBox
          text={"Gerinnungshemmung"}
          image={general}
          action={()=>{
              NavigationHandler.getInstance().setCurrentTarget("Gerinnungshemmung");
              issuer.setState({ currentLevel: 1});
          }}
        />  */}

        <CategoryBox
          text={"EKG - Tool"}
          image={ekg}
          width={getBoxWidth(dimensions.width, dimensions.height)}
          action={() => {
            NavigationHandler.getInstance().setCurrentTarget("EKG - Tool");
            issuer.setState({ currentLevel: 1 });
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 20,
    letterSpacing: 0.7,
    textAlign: "center",
    color: "#000000",
    zIndex: 1,
  },
  sub_title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 15,

    color: "#C4C4C4",
    marginTop: 5,
    zIndex: 1,
  },

  box_unselected: {
    borderWidth: 2,
    borderColor: "#c4c4c4",
    borderRadius: 10,
    padding: 16,
    flex: 0.5,
    aspectRatio: 1,
    margin: 8,
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#fff",
  },
  box_selected: {
    borderWidth: 2,
    borderColor: Colors.gold,
    borderRadius: 10,
    padding: 16,
    flex: 0.5,
    aspectRatio: 1,
    margin: 8,
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#fff",
  },
  category_text_unselected: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: Sizes.selectCategoryText,
    lineHeight: Sizes.selectCategoryTextLineHeight,
    textAlign: "center",
    color: "#C4C4C4",
  },
  category_text_selected: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: Sizes.selectCategoryText,
    lineHeight: Sizes.selectCategoryTextLineHeight,
    textAlign: "center",
    color: Colors.gold,
  },
  arrowImage: {
    width: 12,
    height: 12,
    marginRight: 8,
    resizeMode: "contain",
    tintColor: Colors.gold,
  },
});
