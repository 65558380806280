import { useState } from "react";
import {
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import calc from "../../assets/images/calc.png";
import { Colors } from "../../Constants/Colors";
import NavigationHandler from "../../Handlers/NavigationHandler";

interface AddWeightAgeBox {
  title: string;
  unit: string;
}

export function AddWeightAgeBox(props: AddWeightAgeBox) {
  const [weightText, setWeightText] = useState("0");
  const [buttonEnabled, setButtonEnabled] = useState(false);

  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
      }}
    >
      <View style={styles.result_container}>
        <View
          style={{
            paddingRight: 20,
            paddingLeft: 20,
            flex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <View style={{ flexDirection: "row", flex: 1 }}>
            <TextInput
              style={styles.result_text}
              placeholder={props.title}
              keyboardType={"numeric"}
              onChangeText={(text) => {
                if (text === "") {
                  setButtonEnabled(false);
                  if (props.title.includes("Alter")) {
                    NavigationHandler.getInstance().resultRef!.setState({
                      age: undefined,
                    });
                  } else if (props.title.includes("QT-Dauer")) {
                    NavigationHandler.getInstance().resultRef!.setState({
                      qt: undefined,
                    });
                  } else if (props.title.includes("Dauer")) {
                    NavigationHandler.getInstance().resultRef!.setState({
                      duration: undefined,
                    });
                  } else if (props.title.includes("Verluste")) {
                    NavigationHandler.getInstance().resultRef!.setState({
                      loss: undefined,
                    });
                  } else if (props.title.includes("Serum")) {
                    NavigationHandler.getInstance().resultRef!.setState({
                      serum: undefined,
                    });
                  } else if (props.title.includes("RR-Abstand")) {
                    NavigationHandler.getInstance().resultRef!.setState({
                      rr: undefined,
                    });
                  } else {
                    NavigationHandler.getInstance().resultRef!.setState({
                      amount: undefined,
                    });
                  }
                } else {
                  if (props.title.includes("Alter")) {
                    NavigationHandler.getInstance().resultRef!.setState({
                      age: parseFloat(text.split(",").join(".")),
                    });
                  } else if (props.title.includes("QT-Dauer")) {
                    NavigationHandler.getInstance().resultRef!.setState({
                      qt: parseFloat(text.split(",").join(".")),
                    });
                  } else if (props.title.includes("Dauer")) {
                    NavigationHandler.getInstance().resultRef!.setState({
                      duration: parseFloat(text.split(",").join(".")),
                    });
                  } else if (props.title.includes("Verluste")) {
                    NavigationHandler.getInstance().resultRef!.setState({
                      loss: parseFloat(text.split(",").join(".")),
                    });
                  } else if (props.title.includes("Serum")) {
                    NavigationHandler.getInstance().resultRef!.setState({
                      serum: parseFloat(text.split(",").join(".")),
                    });
                  } else if (props.title.includes("RR-Abstand")) {
                    NavigationHandler.getInstance().resultRef!.setState({
                      rr: parseFloat(text.split(",").join(".")),
                    });
                  } else {
                    NavigationHandler.getInstance().resultRef!.setState({
                      amount: parseFloat(text.split(",").join(".")),
                    });
                  }

                  setButtonEnabled(true);
                }
                setWeightText(text);
              }}
            />
          </View>
          <View
            style={{
              padding: 6,
              borderRadius: 4,
              borderWidth: 2,
              borderColor: Colors.gold,
            }}
          >
            <Text
              style={{ fontWeight: "bold", fontSize: 15, color: Colors.gold }}
            >
              {getCurrentUnit(props.title)}
            </Text>
          </View>
          <TouchableOpacity
            activeOpacity={0.4}
            disabled={!buttonEnabled}
            onPress={() => {
              NavigationHandler.getInstance().resultRef!.setState({
                amount: parseFloat(weightText),
              });
            }}
          >
            <Image
              source={calc}
              style={{
                width: 25,
                height: 32,
                resizeMode: "contain",
                marginLeft: 12,

                tintColor: buttonEnabled ? Colors.gold : Colors.black_opacity,
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  result_container: {
    borderRadius: 60,
    borderWidth: 2,
    borderColor: Colors.gold,
    flexDirection: "column",
    flex: 1,
    backgroundColor: Colors.white,
  },
  result_text: {
    fontWeight: "700",
    fontSize: 12,
    width: "90%",
    paddingTop: 20,
    paddingBottom: 20,
    color: Colors.gold,
  },
});

function getCurrentUnit(text: string) {
  if (text.includes("Alter")) {
    return "Jahre";
  } else if (text.includes("QT-Dauer")) {
    return "msec";
  } else if (text.includes("Dauer")) {
    return "h";
  } else if (text.includes("Verluste")) {
    return "ml";
  } else if (text.includes("Serum")) {
    return "mmol/L";
  } else if (text.includes("RR-Abstand")) {
    return "cm";
  } else {
    return "kg";
  }
}
