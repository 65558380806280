import Ionicons from "@expo/vector-icons/Ionicons";
import { StackActions, useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import {
  Image,
  Modal,
  Platform,
  SafeAreaView,
  ScrollView,
  Share,
  StatusBar,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import bookmark_large from "../assets/images/bolt_single.png";
import cross from "../assets/images/cross.png";
import gift from "../assets/images/gift.png";
import gradient from "../assets/images/gradient.jpg";
import info from "../assets/images/info.png";
import notes_icon from "../assets/images/notes.png";
import pen from "../assets/images/pen.png";
import profile from "../assets/images/profile.png";
import share from "../assets/images/share.png";
import { Header } from "../Components/Header";
import { NavBar } from "../Components/NavBar";
import { Colors } from "../Constants/Colors";
import UserHandler from "../Handlers/UserHandler";
// global event bus
import * as ImagePicker from "expo-image-picker"; // 1
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import trash from "../assets/images/trash.png";
import upload_placeholder from "../assets/images/upload_placeholder.png";
import { CustomButton } from "../Components/CustomButton";
import { db } from "../Components/db";

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "@firebase/storage";
import { ActivityIndicator, DefaultTheme } from "react-native-paper";
import { Sizes } from "../Constants/Sizes";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../Handlers/ErrorHandler/ErrorHandler";
import { PromocodeModal } from "./PromocodeModal";
import NavigationHandler from "../Handlers/NavigationHandler";
import { doc, setDoc } from "firebase/firestore";

async function getImageFromLibrary() {
  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    aspect: [4, 4],
    quality: 0,
  });

  if (!result.cancelled) {
    //   uploadImageToStorage(result.uri, UserHandler.getInstance().currentUser.uuid)
    return result.uri;
  }
}

const onShare = async () => {
  try {
    const result = await Share.share({
      message:
        "Mit diesem Code bekommst Du MEDICEO® für 21 Tage umsonst!\nEinfach unter https://app.medi.ceo einlösen.\n\n" +
        UserHandler.getInstance().currentUser!.user_specific_code,
    });
    if (result.action === Share.sharedAction) {
      if (result.activityType) {
        // shared with activity type of result.activityType
      } else {
        // shared
      }
    } else if (result.action === Share.dismissedAction) {
      // dismissed
    }
  } catch (error) {
    alert(error.message);
  }
};

const dateToString = (date: Date) => {
  if (date.nanoseconds !== undefined) {
    const d = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
    if (d) return d.toLocaleDateString("de-DE"); //d.toLocaleDateString("de-DE");
  }
  if (date) return date.toLocaleDateString("de-DE");
};

async function uplpoadImage(imageURI: string) {
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      reject(new TypeError("Network request failed"));
      return false;
    };
    xhr.responseType = "blob";
    xhr.open("GET", imageURI, true);
    xhr.send(null);
  });

  const fileRef = ref(
    getStorage(),
    UserHandler.getInstance().getCurrentUser().email
  );
  const result = await uploadBytes(fileRef, blob);

  //blob.close();

  const url = await getDownloadURL(fileRef);

  const docRef = doc(
    db,
    "users",
    UserHandler.getInstance().getCurrentUser().email.toLowerCase()
  );
  setDoc(
    docRef,
    {
      profile_picture: url,
    },
    { merge: true }
  );

  UserHandler.getInstance().getCurrentUser().profile_picture = url;

  return url;
}

export function ProfileScreen() {
  const navigation = useNavigation();
  const [modalVisible, setModalVisible] = useState(false);
  const [pwModalVisible, setPwModalVisible] = useState(false);
  const [isCodeModalVisble, setIsCodeModalVisible] = useState(false);
  const [infoModalVisible, setIsInfoModalVisible] = useState(false);
  const [deleteAccountModalVisible, setDeleteAccountModalVisible] =
    useState(false);
  const [pwVisible, setPwVisible] = useState(true);
  const [profileImage, setProfileImage] = useState(
    UserHandler.getInstance().getCurrentUser().profile_picture
  );
  const [hasPromo, setHasPromo] = useState(false);
  const [hasProfileImage, setHasProfileImage] = useState(
    UserHandler.getInstance().getCurrentUser().profile_picture !== ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [runningFirstName, setRunningFirstName] = useState(
    UserHandler.getInstance().currentUser!.firstname
  );
  const [runningLastName, setRunningLastName] = useState(
    UserHandler.getInstance().currentUser!.lastname
  );
  const [pw, setPw] = useState("");
  const [runningOccupation, setRunningOccuption] = useState(
    UserHandler.getInstance().currentUser.clinic
  );

  const getPromo = async () => {
    const response = await fetch("https://server.medi.ceo/has_pw");
    let data = await response.json();

    if (data == true) {
      setHasPromo(true);
    } else {
      setHasPromo(false);
    }
  };

  useEffect(() => {
    getPromo();
    AnalyticsHandler.getInstance().logScreenEvent(
      "Profile Screen",
      "opened",
      ""
    );
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        ...Platform.select({ web: { height: "100vh" } }),
        alignItems: "center",
        justifyContent: "center",
        ...Platform.select({ web: { maxHeight: "100vh" } }),
        backgroundColor: "#fff",
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        opacity: modalVisible ? 1 : pwModalVisible ? 1 : 1,
      }}
    >
      {modalVisible ||
      pwModalVisible ||
      isCodeModalVisble ||
      infoModalVisible ||
      deleteAccountModalVisible ? (
        <View
          style={{
            position: "absolute",
            width: "150%",
            height: "150%",
            backgroundColor: "#000",
            opacity: 0.75,
            zIndex: 10,
          }}
        />
      ) : null}
      <PromocodeModal
        isVisible={isCodeModalVisble}
        setIsVisible={setIsCodeModalVisible}
        successAction={() => {}}
      />
      <Modal
        animationType="slide"
        transparent={true}
        style={{ alignItems: "center", justifyContent: "center" }}
        visible={pwModalVisible}
        onRequestClose={() => {
          setPwModalVisible(!pwModalVisible);
        }}
      >
        <View style={[styles.centeredView]}>
          <KeyboardAwareScrollView
            contentContainerStyle={styles.centeredView}
            style={{ width: "100%", maxWidth: 450 }}
          >
            <View style={[styles.modalView]}>
              <View style={{ width: "100%", justifyContent: "center" }}>
                <View style={{ width: "100%", alignItems: "flex-end" }}>
                  <TouchableOpacity onPress={() => setPwModalVisible(false)}>
                    <Image
                      source={cross}
                      style={{ tintColor: Colors.gray, width: 22, height: 22 }}
                    />
                  </TouchableOpacity>
                </View>
                <Text style={{ color: Colors.black_opacity }}>
                  Neues Passwort eingeben:
                </Text>
                <View
                  style={{
                    width: "100%",
                    maxWidth: Sizes.containerWidth,
                    marginTop: 30,
                  }}
                >
                  <TextInput
                    placeholder="Passwort"
                    style={[
                      styles.textField,
                      { width: "100%" },
                      pw.length < 8
                        ? {
                            borderWidth: 1,
                            borderColor: "red",
                          }
                        : null,
                    ]}
                    secureTextEntry={pwVisible}
                    onChangeText={(text) => {
                      setPw(text);
                    }}
                  />
                  <TouchableOpacity
                    style={{ position: "absolute", right: 20, top: 11 }}
                    onPress={() => setPwVisible(!pwVisible)}
                  >
                    <Ionicons
                      name={pwVisible ? "eye-outline" : "eye-off-outline"}
                      size={24}
                      color={Colors.black_opacity}
                    />
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    width: "100%",
                    alignItems: "center",
                    marginTop: 12,
                    justifyContent: "center",
                  }}
                >
                  <CustomButton
                    text={"Ändern"}
                    backgroundColor={Colors.white}
                    foregroundColor={Colors.gold}
                    borderColor={Colors.gold}
                    enabled={false}
                    action={async () => {
                      let success =
                        await UserHandler.getInstance().sendChangedPwdInAppMail(
                          UserHandler.getInstance().getCurrentUser().email,
                          pw
                        );
                      if (!success) {
                        showErrorMessage({
                          title: "Passwort fehlgeschlagen!",
                          text: "Die Verbindung ist fehlgeschlagen. Sollte der Fehler weiterhin bestehen, kontaktieren Sie uns unter support@medi.ceo",
                        });
                        return false;
                      }

                      setPwModalVisible(false);
                    }}
                  />
                </View>
              </View>
            </View>
          </KeyboardAwareScrollView>
        </View>
      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        style={{ alignItems: "center", justifyContent: "center" }}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={[styles.centeredView]}>
          <KeyboardAwareScrollView
            contentContainerStyle={styles.centeredView}
            style={{ width: "100%", maxWidth: 450 }}
          >
            <View style={[styles.modalView]}>
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View style={{ width: "100%", alignItems: "flex-end" }}>
                  <TouchableOpacity onPress={() => setModalVisible(false)}>
                    <Image
                      source={cross}
                      style={{ tintColor: Colors.gray, width: 22, height: 22 }}
                    />
                  </TouchableOpacity>
                </View>
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <TouchableOpacity
                    style={{ marginBottom: 20 }}
                    onPress={async () => {
                      setIsLoading(true);
                      const image = await getImageFromLibrary();
                      if (image !== undefined) {
                        const up = await uplpoadImage(image);

                        setProfileImage(up);
                        setHasProfileImage(true);
                        setIsLoading(false);
                      }
                    }}
                  >
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          color: Colors.gold,
                          fontWeight: "700",
                          fontSize: 14,
                          marginBottom: 12,
                        }}
                      >
                        Profilbild hochladen
                      </Text>
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <View
                          style={{
                            borderRadius: 64,
                            shadowColor: "#000",
                            shadowOpacity: 0.12,
                            shadowRadius: 4,
                            shadowOffset: { width: 0, height: 2 },
                          }}
                        >
                          {_image(isLoading, hasProfileImage, profileImage)}
                        </View>
                        {hasProfileImage ? (
                          <TouchableOpacity
                            onPress={async () => {
                              let success =
                                await UserHandler.getInstance().deleteProfileImage();
                              if (!success) {
                                showErrorMessage({
                                  title: "Etwas hat nicht geklappt",
                                  text: "Versuchen Sie es erneut. Sollte der Fehler bestehen, kontaktieren Sie uns unter support@medi.ceo.",
                                });
                              } else {
                                setHasProfileImage(false);
                                setProfileImage("");
                              }
                            }}
                          >
                            <Image
                              source={trash}
                              style={{
                                width: 24,
                                height: 24,
                                tintColor: Colors.black_opacity,
                                opacity: 0.5,
                                marginLeft: 12,
                              }}
                            />
                          </TouchableOpacity>
                        ) : null}
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    color: Colors.gold,
                    fontWeight: "700",
                    fontSize: 14,
                    marginTop: 20,
                    textAlign: "center",
                  }}
                >
                  Namen und Krankenhaus eingeben
                </Text>
                <View
                  style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <View style={{ width: "90%", flexDirection: "row" }}>
                    <TextInput
                      onChangeText={(t) => setRunningFirstName(t)}
                      style={[
                        styles.container,
                        { fontSize: 12, padding: 12, flex: 1, marginRight: 8 },
                      ]}
                      value={runningFirstName}
                      placeholderTextColor={Colors.black_opacity}
                      placeholder="Vorname"
                    />
                    <TextInput
                      onChangeText={(t) => setRunningLastName(t)}
                      style={[
                        styles.container,
                        { fontSize: 12, padding: 12, flex: 1 },
                      ]}
                      value={runningLastName}
                      placeholderTextColor={Colors.black_opacity}
                      placeholder="Nachname"
                    />
                  </View>
                  <TextInput
                    onChangeText={(t) => setRunningOccuption(t)}
                    style={[
                      styles.container,
                      {
                        fontSize: 12,
                        padding: 12,
                        marginTop: 8,
                        width: "90%",
                      },
                    ]}
                    value={runningOccupation}
                    placeholderTextColor={Colors.black_opacity}
                    placeholder="Krankenhaus"
                  />
                </View>
                <View
                  style={{
                    width: "80%",
                    alignItems: "center",
                    marginTop: 30,
                    justifyContent: "center",
                  }}
                >
                  <CustomButton
                    text={"Bestätigen"}
                    backgroundColor={Colors.white}
                    foregroundColor={Colors.gold}
                    borderColor={Colors.gold}
                    enabled={false}
                    action={() => {
                      UserHandler.getInstance().getCurrentUser().firstname =
                        runningFirstName;
                      UserHandler.getInstance().getCurrentUser().lastname =
                        runningLastName;
                      UserHandler.getInstance().getCurrentUser().clinic =
                        runningOccupation;
                      UserHandler.getInstance().update();
                      NavigationHandler.getInstance().headerRefs.forEach((r) =>
                        r((v: any) => !v)
                      );
                      setModalVisible(false);
                    }}
                  />
                </View>
              </View>
            </View>
          </KeyboardAwareScrollView>
        </View>
      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        style={{ alignItems: "center", justifyContent: "center" }}
        visible={infoModalVisible}
        onRequestClose={() => {
          setModalVisible(!infoModalVisible);
        }}
      >
        <View style={[styles.centeredView]}>
          <View style={[styles.centeredView, { width: "100%", maxWidth: 450 }]}>
            <View style={[styles.modalView]}>
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <View style={{ width: "100%", alignItems: "flex-end" }}>
                  <TouchableOpacity
                    onPress={() => setIsInfoModalVisible(false)}
                  >
                    <Image
                      source={cross}
                      style={{ tintColor: Colors.gray, width: 22, height: 22 }}
                    />
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    color: Colors.black_opacity,
                    fontWeight: "bold",
                    fontSize: 15,
                    marginTop: -20,
                  }}
                >
                  Wie funktioniert der{"\n"}Empfehlungscode?
                </Text>

                <Text
                  style={{
                    color: Colors.black_opacity,
                    fontSize: 13,
                    marginTop: 20,
                  }}
                >
                  {UserHandler.getInstance().getCurrentUser().role === "doc"
                    ? "Teilen Sie Ihren persöhnlichen Empfehlungsode mit Kolleg:innen und erhalten Sie beide MEDICEO® für 21 Tage kostenfrei, sobald die andere Person den Code einlöst."
                    : "Teile Deinen persöhnlichen Empfehlungscode mit Freund:innen und und erhaltet beide MEDICEO® für 21 Tage kostenfrei, sobald die andere Person den Code einlöst!"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        style={{ alignItems: "center", justifyContent: "center" }}
        visible={deleteAccountModalVisible}
        onRequestClose={() => {
          setDeleteAccountModalVisible(!deleteAccountModalVisible);
        }}
      >
        <View style={[styles.centeredView]}>
          <View style={[styles.centeredView, { width: "100%", maxWidth: 450 }]}>
            <View style={[styles.modalView]}>
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <View style={{ width: "100%", alignItems: "flex-end" }}>
                  <TouchableOpacity
                    onPress={() => setDeleteAccountModalVisible(false)}
                  >
                    <Image
                      source={cross}
                      style={{ tintColor: Colors.gray, width: 22, height: 22 }}
                    />
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    color: Colors.black_opacity,
                    fontWeight: "bold",
                    fontSize: 15,
                    marginTop: -20,
                  }}
                >
                  Account wirklich löschen?
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    marginTop: 35,
                    justifyContent: "space-around",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setDeleteAccountModalVisible(false)}
                  >
                    <Text
                      style={{
                        color: Colors.gold,
                        fontSize: 13,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      Nein
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setDeleteAccountModalVisible(false);
                      showSuccessMessage({
                        title: "Erfolgreich",
                        text: "Um alle personenbezogenen Daten zu löschen benötigen wir bis zu 24h. Sie werden per Mail informiert, sobald die Löschung abgeschlossen ist. Ihr Abbonement läuft über die gewählte Laufzeit weiter, dieses muss außerdem über den AppStore gekündigt werden.",
                        time: 12000,
                      });
                      AnalyticsHandler.getInstance().logScreenEvent(
                        "Account Deletion",
                        "requested",
                        UserHandler.getInstance().getCurrentUser().email
                      );
                    }}
                  >
                    <Text
                      style={{
                        color: Colors.black_opacity,
                        fontSize: 13,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      Ja
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          width: "100%",
          alignItems: "center",
          backgroundColor: Colors.background,
        }}
      >
        <Image
          source={gradient}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />
        <Header isProfile={true} isDark={false} issuer={null} />
        <NavBar isHints={false} isNews={false} level={-1} issuer={null} />
        <View
          style={{
            marginTop: 50,
            width: "100%",
            alignItems: "center",
            flex: 1,
            marginBottom: 60,
            overflow: "visible",
            justifyContent: "center",
          }}
        >
          <ScrollView
            style={{ width: "100%", flex: 1 }}
            contentContainerStyle={{ alignItems: "center" }}
            horizontal={false}
          >
            <View
              style={{
                width: "90%",
                alignItems: "center",
                height: "100%",
                paddingTop: 30,
                maxWidth: Sizes.containerWidth,
              }}
            >
              <View style={styles.container}>
                <Image
                  style={{
                    width: 92,
                    height: 92,
                    resizeMode: "cover",
                    borderRadius: 46,
                  }}
                  source={hasProfileImage ? { uri: profileImage } : profile}
                />
                <View style={{ marginLeft: 20, flex: 1 }}>
                  <Text style={styles.profile_name}>
                    {UserHandler.getInstance().getCurrentUser().firstname +
                      " " +
                      UserHandler.getInstance().getCurrentUser().lastname}
                  </Text>
                  <Text style={styles.occupation_text}>
                    {UserHandler.getInstance().getCurrentUser().clinic}
                  </Text>
                </View>
                <TouchableOpacity onPress={() => setModalVisible(true)}>
                  <Image
                    style={{ width: 20, height: 20, marginRight: 20 }}
                    source={pen}
                  />
                </TouchableOpacity>
              </View>
              <View style={styles.container_two}>
                <View style={{ marginLeft: 20, flex: 1 }}>
                  <Text style={styles.box_title}>E-Mail:</Text>
                  <Text style={styles.box_text}>
                    {UserHandler.getInstance().getCurrentUser().email}
                  </Text>
                </View>
              </View>
              <View style={styles.container_two}>
                <View style={{ marginLeft: 20, flex: 1, flexDirection: "row" }}>
                  <Text style={styles.box_title}>Passwort: </Text>
                  <Text style={styles.box_text}>******</Text>
                </View>
                <TouchableOpacity
                  onPress={async () => {
                    setPwModalVisible(!pwModalVisible);
                  }}
                >
                  <Image
                    style={{ width: 20, height: 20, marginRight: 12 }}
                    source={pen}
                  />
                </TouchableOpacity>
              </View>
              {NavigationHandler.getInstance().currentSubject === "" ? null : (
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate("BookmarksScreen", {
                      isFromProfile: true,
                    })
                  }
                  style={{
                    width: "100%",
                    marginTop: 20,
                    ...Platform.select({ web: { marginTop: 35 } }),
                  }}
                >
                  <View style={styles.container_two}>
                    <View style={{ marginLeft: 20, flex: 1 }}>
                      <Text style={styles.box_title}>Shortcuts</Text>
                    </View>

                    <Image
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 12,
                        resizeMode: "contain",
                        opacity: 0.5,
                      }}
                      source={bookmark_large}
                    />
                  </View>
                </TouchableOpacity>
              )}

              {NavigationHandler.getInstance().currentSubject === "" ? null : (
                <TouchableOpacity
                  onPress={() => navigation.navigate("NotesOverviewScreen")}
                  style={{ width: "100%" }}
                >
                  <View style={styles.container_two}>
                    <View style={{ marginLeft: 20, flex: 1, width: "100%" }}>
                      <Text style={styles.box_title}>Meine Notizen</Text>
                    </View>
                    <Image
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 12,
                        resizeMode: "contain",
                        opacity: 0.5,
                      }}
                      source={notes_icon}
                    />
                  </View>
                </TouchableOpacity>
              )}

              {!hasPromo && !UserHandler.getInstance().isPayingUser() ? null : (
                <TouchableOpacity
                  style={{
                    width: "100%",
                    marginTop: 20,
                    ...Platform.select({ web: { marginTop: 35 } }),
                  }}
                >
                  <View style={styles.container_two}>
                    <View style={{ marginLeft: 20, flex: 1 }}>
                      <Text style={styles.box_title}>
                        MEDICEO® Abo - gültig bis{" "}
                        {dateToString(
                          UserHandler.getInstance().getCurrentUser()
                            .free_use_until
                        )}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              )}

              {!hasPromo ? null : (
                <TouchableOpacity
                  onPress={() => {
                    onShare();
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  <View style={styles.container_two}>
                    <View style={{ marginLeft: 20, flex: 1, marginRight: 20 }}>
                      <Text style={styles.box_title}>
                        {UserHandler.getInstance().getCurrentUser()!.role ===
                        "doc"
                          ? "Ihr "
                          : "Dein "}
                        Empfehlungscode:{"  "}
                        <Text style={{ opacity: 0.5 }}>
                          {
                            UserHandler.getInstance().currentUser!
                              .user_specific_code
                          }
                        </Text>
                      </Text>
                    </View>
                    <Image
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 20,
                        ...Platform.select({ web: { marginRight: 20 } }),
                        resizeMode: "contain",
                      }}
                      source={share}
                    />
                    <TouchableOpacity
                      style={{ paddingLeft: 12, paddingRight: 12 }}
                      onPress={() => setIsInfoModalVisible(true)}
                    >
                      <Image
                        style={{
                          width: 20,
                          height: 20,

                          resizeMode: "contain",
                          opacity: 0.5,
                        }}
                        source={info}
                      />
                    </TouchableOpacity>
                  </View>
                </TouchableOpacity>
              )}

              {!hasPromo ? null : (
                <TouchableOpacity
                  onPress={() => setIsCodeModalVisible(true)}
                  style={{ width: "100%" }}
                >
                  <View style={styles.container_two}>
                    <View style={{ marginLeft: 20, flex: 1 }}>
                      <Text style={styles.box_title}>Promocode hinzufügen</Text>
                    </View>

                    <Image
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 12,
                        resizeMode: "contain",
                      }}
                      source={gift}
                    />
                  </View>
                </TouchableOpacity>
              )}
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  style={{ marginTop: 35, marginBottom: 50, marginRight: 20 }}
                  onPress={() => {
                    UserHandler.getInstance().logout();
                    navigation.dispatch(StackActions.popToTop());
                    navigation.navigate("FirstLandingScreen");
                  }}
                >
                  <Text
                    style={{ fontSize: 13, color: "#fff", fontWeight: "bold" }}
                  >
                    Abmelden
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ marginTop: 35, marginBottom: 50 }}
                  onPress={() => {
                    setDeleteAccountModalVisible(true);
                  }}
                >
                  <Text
                    style={{ fontSize: 13, color: "#fff", fontWeight: "bold" }}
                  >
                    Account löschen
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </SafeAreaView>
  );
}

function _image(
  isLoading: boolean,
  hasProfileImage: boolean,
  profileImage: string
) {
  if (!isLoading) {
    return (
      <View>
        <Image
          style={{
            borderWidth: 1,
            borderRadius: 32,
            borderColor: Colors.gold,
            width: 64,
            height: 64,
            ...Platform.select({
              android: { borderRadius: 64 },
              ios: { borderRadius: 32 },
            }),
          }}
          source={hasProfileImage ? { uri: profileImage } : upload_placeholder}
        />
        {hasProfileImage ? (
          <Image
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              borderRadius: 32,
              width: 64,
              height: 64,
              opacity: 0.5,
            }}
            source={upload_placeholder}
          />
        ) : null}
      </View>
    );
  } else {
    return (
      <ActivityIndicator
        theme={{
          colors: {
            ...DefaultTheme.colors,
            primary: Colors.gold,
          },
        }}
        size="small"
      />
    );
  }
}

const styles = StyleSheet.create({
  textField: {
    backgroundColor: "#fafafa",
    shadowColor: "#000",
    shadowOpacity: 0.12,
    borderRadius: 80,
    width: "85%",
    height: 50,
    padding: 12,
    elevation: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    marginBottom: 12,
    maxWidth: Sizes.containerWidth,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 12,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 30,
    width: "80%",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  title: {
    fontWeight: "700",
    fontSize: 16,
    color: "#000",
  },
  container: {
    marginTop: 30,
    borderRadius: 50,
    padding: 2,
    marginBottom: 20,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    backgroundColor: Colors.background,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  container_two: {
    marginTop: 15,
    borderRadius: 10,
    padding: 12,
    paddingTop: 20,
    paddingBottom: 20,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    backgroundColor: Colors.background,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  profile_name: {
    fontSize: 14,
    fontWeight: "700",
  },
  occupation_text: {
    fontSize: 12,
    fontWeight: "400",
    color: "#8B8B8B",
  },
  box_title: {
    fontSize: 14,
    fontWeight: "700",
    opacity: 0.5,
  },
  box_text: {
    fontSize: 14,
    fontWeight: "400",
    opacity: 0.5,
  },
});
