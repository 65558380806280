import { useEffect, useState } from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import RenderHtml from "react-native-render-html";
import { Colors } from "../../Constants/Colors";
import { Sizes } from "../../Constants/Sizes";
import { ContentHandler } from "../../Handlers/ContentHandler";
import NavigationHandler from "../../Handlers/NavigationHandler";
import { BoxTextComponent } from "./BoxTextComponent";

export interface Annotation {
  title: string;
  type: number;
  content: string;
}

interface FirstResultProps {
  title: string;
  content: string;
  weight: number;
  age: number;
  annotation?: Annotation;
}

export function DosingBoxHTML(props: FirstResultProps) {
  const [boxWidth, setBoxWidth] = useState(0);
  const [secondBoxWidth, setSecondBoxWidth] = useState(0);

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    ContentHandler.getInstance().addToRefreshBoxes(setRefresh);
  });
  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
        overflow: "visible",
      }}
    >
      <View style={styles.result_container}>
        {getAnnotationComponent(props.annotation)}
        <View
          style={{ flexDirection: "row", width: "100%" }}
          onLayout={(event) => {
            var { x, y, width, height } = event.nativeEvent.layout;

            if (boxWidth > 0 && secondBoxWidth === 0) {
              setSecondBoxWidth(width - boxWidth);
            } else {
              setBoxWidth(width);
            }
          }}
        >
          <View
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 16,
              paddingBottom: 16,
              backgroundColor: NavigationHandler.getInstance().isPH
                ? Colors.background
                : Colors.gold,
              borderRightColor: NavigationHandler.getInstance().isPH
                ? Colors.black_opacity
                : Colors.gold,
              borderRightWidth: NavigationHandler.getInstance().isPH ? 1 : 0,
              minWidth: "33%",
              maxWidth: "33%",
              justifyContent: "center",
              alignItems: "center",
            }}
            onLayout={(event) => {
              var { x, y, width, height } = event.nativeEvent.layout;
              if (boxWidth > 0 && secondBoxWidth === 0) {
                setSecondBoxWidth(boxWidth - width);
              } else {
                setBoxWidth(width);
              }
            }}
          >
            <View>
              <RenderHtml
                contentWidth={boxWidth - secondBoxWidth}
                source={{ html: props.title }}
                tagsStyles={{
                  p: {
                    fontWeight: "700",
                    fontSize: Sizes.boxText,
                    lineHeight: Sizes.lineHeightBoxText,
                    textAlign: "center",
                    color: NavigationHandler.getInstance().isPH
                      ? Colors.black_opacity
                      : "#ffffff",
                  },
                  ul: {
                    fontSize: Sizes.boxText,
                    lineHeight: Sizes.lineHeightBoxText,
                    color: Colors.black_opacity,
                    opacity: 0.5,
                  },
                  li: {
                    marginLeft: 6,
                    marginBottom: Sizes.liMarginBottom,
                  },
                }}
              />
            </View>
          </View>
          <View
            style={{
              alignItems: "flex-start",
              justifyContent: "center",
              width: secondBoxWidth,
            }}
          >
            <View
              style={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: secondBoxWidth - 24,
                overflow: "hidden",
                marginLeft: 12,
              }}
            >
              {getText(
                secondBoxWidth,
                props.content,
                setRefresh,
                boxWidth,
                setSecondBoxWidth
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

function getText(
  secondBoxWidth: number,
  content: string,
  refresh: React.Dispatch<React.SetStateAction<boolean>>,
  full: number,
  setSecondBoxWidth: React.Dispatch<React.SetStateAction<number>>
) {
  if (secondBoxWidth > 0) {
    return (
      <BoxTextComponent
        content={content}
        style={{
          body: {
            width: secondBoxWidth - 24,
            overflow: "hidden",
          },
          p: {
            fontSize: Sizes.boxText,
            lineHeight: Sizes.lineHeightBoxText,
            color: Colors.black_opacity,
          },
          ul: {
            fontSize: Sizes.boxText,
            lineHeight: Sizes.lineHeightBoxText,
            color: Colors.black_opacity,
            marginBottom: 2,
            marginLeft: -12,
          },
          li: {
            marginLeft: 6,
            marginBottom: Sizes.liMarginBottom,
          },
        }}
      />
    );

    // <RenderHtml  contentWidth={secondBoxWidth}
    //     source={{html: content}} tagsStyles={{
    //        body: {
    //        width: secondBoxWidth - 24,
    //           overflow: "hidden"
    //        },
    //        p: {
    //            fontSize: Sizes.boxText,
    //            lineHeight: Sizes.lineHeightBoxText,
    //            color: Colors.black_opacity,

    //        },
    //        ul: {
    //            fontSize: Sizes.boxText,
    //            lineHeight: Sizes.lineHeightBoxText,
    //            color: Colors.black_opacity,

    //            marginLeft: -12,

    //        },
    //        li : {
    //            marginLeft: 6,
    //            marginBottom: Sizes.liMarginBottom,
    //        }
    //     }}/>)
  } else {
    if (full > 0) {
      if (Platform.OS === "web") {
        setSecondBoxWidth(full * 2);
        refresh(true);
      }
    }
  }
}

function getAnnotationComponent(an: Annotation | undefined) {
  if (an !== undefined) {
    let color = Colors.red;
    if (an.type == 0) {
      color = Colors.light_green;
    }
    return (
      <View
        style={{
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
          borderBottomColor: Colors.gold,
          borderBottomWidth: 2,
        }}
      >
        <View
          style={{
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            borderBottomColor: color,
            backgroundColor: color,
          }}
        >
          <RenderHtml
            tagsStyles={{
              p: {
                fontSize: Sizes.boxText,
                color: "#fff",
                fontWeight: "700",
                padding: 5,
                marginTop: -0,
                marginBottom: -0,
              },
            }}
            source={{ html: an.title }}
          ></RenderHtml>
        </View>
        <View style={{ width: "100%", paddingLeft: 8, paddingRight: 8 }}>
          <Text
            style={{
              textAlign: "center",
              fontSize: Sizes.boxText,
              lineHeight: Sizes.lineHeightBoxText,
              color: Colors.black_opacity,
              marginTop: 12,
              marginBottom: 12,
            }}
          >
            {an.content}
          </Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  result_container: {
    borderRadius: 10,
    borderWidth: 2,
    borderColor: Colors.gold,
    backgroundColor: "#fff",
    overflow: "hidden",
  },
  result_text: {
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 18,
    textAlign: "center",
    color: Colors.white,
  },
  content_text: {
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 18,
    color: Colors.black,
    opacity: 0.5,
  },
});
