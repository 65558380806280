import { useEffect, useState } from "react";
import {
  Image,
  View,
  Text,
  StyleSheet,
  ImageBackgroundComponent,
  Platform,
} from "react-native";
import { Colors } from "../../Constants/Colors";
import Lightbox from "react-native-lightbox-v2";
import { TextComponent } from "./TextComponent";
import { Table, TableComponent, TableProps } from "./TableComponent";
import RenderHTML from "react-native-render-html";
import NavigationHandler from "../../Handlers/NavigationHandler";
import { Sizes } from "../../Constants/Sizes";
import { BoxTextComponent } from "./BoxTextComponent";
import { ContentHandler } from "../../Handlers/ContentHandler";
import { InlineImage } from "./HintBoxHTML";

export interface Annotation {
  title: string;
  type: number;
  content: string;
}

interface FirstResultProps {
  title: string;
  content: string;
  weight: number;
  age: number;
  annotation?: Annotation;
  inlineImage: InlineImage | undefined;
}

export function LargeDosingBoxHTML(props: FirstResultProps) {
  const [width, setWidth] = useState(300);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    ContentHandler.getInstance().addToRefreshBoxes(setRefresh);
  }, []);

  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
        overflow: "hidden",
      }}
    >
      <View style={styles.result_container}>
        {getAnnotationComponent(props.annotation)}
        <View
          style={{
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <View
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 16,
              paddingBottom: 16,
              backgroundColor: NavigationHandler.getInstance().isPH
                ? Colors.background
                : Colors.gold,
              width: "100%",
              borderBottomColor: NavigationHandler.getInstance().isPH
                ? Colors.black_opacity
                : Colors.gold,
              borderBottomWidth: NavigationHandler.getInstance().isPH ? 1 : 0,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RenderHTML
              contentWidth={300}
              tagsStyles={
                NavigationHandler.getInstance().isPH
                  ? htmlStylesTitleBlack
                  : htmlStylesTitle
              }
              source={{ html: props.title }}
            ></RenderHTML>
          </View>
          <View
            style={{
              padding: 12,

              alignItems: "flex-start",
              justifyContent: "center",
              overflow: "hidden",
              width: "90%",
            }}
            onLayout={(event) => {
              var { x, y, width, height } = event.nativeEvent.layout;
              setWidth(width);
            }}
          >
            <BoxTextComponent
              style={
                props.content.includes("<th>")
                  ? {
                      body: {
                        width: width,
                      },
                      table: {
                        width: width - 10,
                      },
                      tr: {},
                      td: {
                        color: Colors.black_opacity,
                        borderBottomColor: "#ddd",
                        borderBottomWidth: 1,
                        // borderRightColor: "#ddd",
                        // borderRightWidth: 1,
                      },
                      th: {
                        color: Colors.gold,
                        borderBottomColor: Colors.gold,
                        borderBottomWidth: 1,
                      },
                      p: {
                        fontSize: Sizes.boxText,
                        lineHeight: Sizes.lineHeightBoxText,
                        textAlign: "flex-start",
                      },
                    }
                  : {
                      p: {
                        fontSize: Sizes.boxText,
                        lineHeight: Sizes.lineHeightBoxText,
                        color: Colors.black_opacity,
                      },
                      body: {
                        width: width,
                      },
                      ul: {
                        fontSize: Sizes.boxText,
                        lineHeight: Sizes.lineHeightBoxText,
                        color: Colors.black_opacity,
                        paddingRight: 8,
                        marginLeft: -12,
                        marginBottom: 4,
                      },
                      ol: {
                        fontSize: Sizes.boxText,
                        lineHeight: Sizes.lineHeightBoxText,
                        color: Colors.black_opacity,
                        paddingRight: 8,
                        marginLeft: -12,
                        marginBottom: 4,
                      },
                      li: {
                        marginLeft: 6,
                        marginBottom: 8,
                      },
                    }
              }
              content={props.content}
            ></BoxTextComponent>
          </View>
          {props.inlineImage !== undefined ? (
            //<Lightbox style={{ height: 200 }}>
            <Image
              style={{
                aspectRatio: props.inlineImage.aspectRatio,
                width: "90%",
                marginBottom: 12,
                marginTop: -12,
                ...Platform.select({ web: { maxHeight: "50vh" } }),
              }}
              source={{ uri: "https://" + props.inlineImage.url }}
            />
          ) : //</Lightbox>
          null}
        </View>
      </View>
    </View>
  );
}

const htmlStylesTitle = {
  p: {
    fontWeight: "700",
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginVertical: -1,
    textAlign: "center",
    color: "#fff",
  },
};

const htmlStylesTitleBlack = {
  p: {
    fontWeight: "700",
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginVertical: -1,
    textAlign: "center",
    color: Colors.black_opacity,
  },
};

const htmlStylesContent = {
  table: {
    width: 100,
  },
  tr: {},
  td: {
    color: Colors.black_opacity,
  },
  th: {
    color: Colors.gold,
    borderBottomColor: Colors.gold,
    borderBottomWidth: 1,
  },
  p: {
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    textAlign: "center",
  },
  ul: {
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    color: Colors.black_opacity,
    paddingRight: 12,
    marginLeft: -5,
  },
  li: {
    marginLeft: 6,
    marginBottom: 8,
  },
};

function getAnnotationComponent(an: Annotation | undefined) {
  if (an !== undefined) {
    let color = Colors.red;
    if (an.type == 0) {
      color = Colors.light_green;
    }
    return (
      <View
        style={{
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
          borderBottomColor: Colors.gold,
          borderBottomWidth: 2,
        }}
      >
        <View
          style={{
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            borderBottomColor: color,
            backgroundColor: color,
          }}
        >
          <RenderHTML
            tagsStyles={{
              p: {
                fontSize: Sizes.boxText,
                color: "#fff",
                fontWeight: "700",
                padding: 5,
                marginTop: -0,
                marginBottom: -0,
              },
            }}
            source={{ html: an.title }}
          ></RenderHTML>
        </View>
        <View style={{ width: "100%", paddingLeft: 8, paddingRight: 8 }}>
          <Text
            style={{
              textAlign: "center",
              fontSize: Sizes.boxText,
              lineHeight: Sizes.lineHeightBoxText,
              color: Colors.black_opacity,
              marginTop: 12,
              marginBottom: 12,
            }}
          >
            {an.content}
          </Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  result_container: {
    borderRadius: 10,
    borderWidth: 2,
    borderColor: Colors.gold,
    backgroundColor: Colors.white,
    overflow: "hidden",
  },
  result_text: {
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 18,
    color: Colors.white,
  },
  content_text: {
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 18,
    color: Colors.black,
    opacity: 0.5,
  },
});
