import {
  LayoutAnimation,
  Platform,
  StyleSheet,
  Text,
  UIManager,
  View,
} from "react-native";
import { DecisionTreeOption } from "../Components/DecisionTreeOption";
import { ProgressContainer } from "../Components/ProgressContainer";
import { Colors } from "../Constants/Colors";
import NavigationHandler, { Node } from "../Handlers/NavigationHandler";

import { Component } from "react";
import { CustomButton } from "../Components/CustomButton";
import { DecisionTreeOptionMultiple } from "../Components/DecisionTreeOptionMultiple";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";
import { HomeScreen } from "../Screens/HomeScreen";
import { NewsContentHandler } from "../Handlers/NewsContent/NewsContentHandler";

interface DecisionTreeProps {
  issuer: HomeScreen;
}

export class DecisionTreeComponent extends Component<DecisionTreeProps> {
  componentDidMount() {
    if (
      Platform.OS === "android" &&
      UIManager.setLayoutAnimationEnabledExperimental
    ) {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
  }

  title = "";

  state = {
    refresh: false,
    multipleNodes: [],
  };

  n: Node[] = [];
  isMultiple = false;

  render() {
    const nodes = NavigationHandler.getInstance().getCurrentNodes(
      this.props.issuer.state.currentID
    );

    if (nodes == undefined) {
      return null;
    }
    if (nodes[0] == undefined) {
    } else {
      if (nodes[0].id === 0) {
        this.title = nodes[0].title;
      } else {
        this.title = NavigationHandler.getInstance().getCurrentTitle(
          nodes[0].title
        );
      }
      if (nodes[0].isMultiple) {
        this.isMultiple = true;
      } else {
        this.isMultiple = false;
      }
    }

    return (
      <View
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "90%",
          // flex: 1,
          overflow: "visible",
          minHeight: "100%",
        }}
      >
        <View
          style={{
            paddingBottom: 12,
            overflow: "hidden",
            width: "100%",
            marginBottom: 12,
            marginTop: 12,
          }}
        >
          <ProgressContainer
            steps={NavigationHandler.getInstance().getCurrentMaxSteps()}
            current_step={this.props.issuer.state.currentLevel}
          />
        </View>
        <Text style={styles.sub_title}>
          {NavigationHandler.getInstance()
            .getPath()
            .map((p) => {
              if (
                NavigationHandler.getInstance().getPath().indexOf(p) !==
                NavigationHandler.getInstance().getPath().length - 1
              ) {
                return p + " | ";
              } else {
                return p;
              }
            })}
        </Text>
        <Text style={styles.title}>{this.title}</Text>
        <View
          style={{
            marginTop: 30,
            marginBottom: 55,
            width: "100%",
            flex: 1,
            justifyContent: "center",
          }}
        >
          {CurrentNodes(this)}
        </View>
      </View>
    );
  }
}

const CurrentNodes = (ref: DecisionTreeComponent) => {
  if (ref.props.issuer.state.currentID === undefined) {
    ref.props.issuer.setState({ currentLevel: 1, refresh: false });

    return <View></View>;
  }
  if (
    NavigationHandler.getInstance().getCurrentNodes(
      ref.props.issuer.state.currentID
    ) === undefined
  ) {
    ref.props.issuer.setState({ currentLevel: 1, refresh: false });

    return <View></View>;
  }
  return NavigationHandler.getInstance()
    .getCurrentNodes(ref.props.issuer.state.currentID)
    .map((node) => {
      return getDecisionTreeWithoutMultiple(node, ref.props);
    });
};

interface ContinueButtonProps {
  node: Node | undefined;
  props: DecisionTreeProps;
  multipleNodes: Node[] | undefined;
  issuer: HomeScreen | undefined;
}

function _continue(props: ContinueButtonProps) {
  const navHandler = NavigationHandler.getInstance();
  if (!navHandler.shouldShowResult()) {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }
  if (props.node !== undefined) {
    if (props.node.inline_var != undefined) {
      navHandler.calculationVar += props.node.inline_var;
    }
    if (navHandler.isCustomResult) {
      navHandler.customResult.push(props.node.node_title);
    }

    let nn_id = props.node.id;
    if (props.node.result != undefined) {
      navHandler.setCurrentResultID(props.node.result);
      navHandler.setShouldShowResult(true);
    }
    const prev = props.props.issuer.state.currentID;
    let prevNodes: string[] = props.props.issuer.state.previousNodes;
    if (prevNodes === undefined || typeof prevNodes === "number")
      prevNodes = [];
    prevNodes.push(prev.toString());

    if (props.node.isCustom) navHandler.isCustomResult = true;

    props.props.issuer.setState({
      currentID: nn_id,
      currentLevel: props.props.issuer.state.currentLevel + 1,
      previousNodes: prevNodes,
    });
  }
}

function getDecisionTreeWithoutMultiple(node: Node, props: DecisionTreeProps) {
  return (
    <View key={Math.random()}>
      <DecisionTreeOption
        text={node.node_title}
        action={() => {
          AnalyticsHandler.getInstance().logDecisionTreeComponent(
            node.node_title
          );
          NavigationHandler.getInstance().addToPath(node.node_title);
          _continue({
            node: node,
            props: props,
            multipleNodes: undefined,
            issuer: undefined,
          });
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 20,
    letterSpacing: 0.7,
    textAlign: "left",
    color: "#000000",
  },
  sub_title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 15,
    textAlign: "left",
    color: Colors.gold,
    marginBottom: 5,
    textTransform: "uppercase",
  },

  box_unselected: {
    borderWidth: 2,
    borderColor: "#c4c4c4",
    borderRadius: 10,
    padding: 16,
    flex: 0.5,
    aspectRatio: 1,
    margin: 8,
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#fff",
  },
  box_selected: {
    borderWidth: 2,
    borderColor: Colors.gold,
    borderRadius: 10,
    padding: 16,
    flex: 0.5,
    aspectRatio: 1,
    margin: 8,
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#fff",
  },
  category_text_unselected: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 12,
    textAlign: "center",
    color: "#C4C4C4",
  },
  category_text_selected: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 12,
    textAlign: "center",
    color: Colors.gold,
  },
});
