import { ContentHandler } from "../ContentHandler";


interface RootObject {
  metadata: Metadata;
  sys: Sys2;
  fields: Fields;
}

export interface Fields {
  fachbereich: string;
  titelArtikel: Space;
  andereArtikel: Space[];
}

interface Sys2 {
  space: Space;
  id: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  environment: Space;
  revision: number;
  contentType: Space;
  locale: string;
}

interface Space {
  sys: Sys;
}

interface Sys {
  type: string;
  linkType: string;
  id: string;
}

interface Metadata {
  tags: any[];
}


export class NewsContentHandler {

    static _instance: undefined | NewsContentHandler = undefined;

    _allArticleOverviews: RootObject[] = [];

    ids = ["41giexYPqmpfyYWng9QHTA", "62BZn9WYSRtLGcIyrXnELp", "35g583L8xKCJl9HZEvXo6y"]

    static getInstance() {
        if (this._instance === undefined)
            this._instance = new NewsContentHandler();
        return this._instance;
    }

    getArticlesToOverview(fb: string) {
      let f = this._allArticleOverviews.filter(a => {
        return a.fields.fachbereich === fb
      })
      if (f.length === 0) {
        return null
      }

      return f[0].fields;
    }

    async getArticleOverview() {
      for (let i of this.ids) {
      try {
          let response = await fetch("https://cdn.contentful.com/spaces/aepv59ipfyvq/environments/master/entries/" + i + "?access_token=Fbi4zRIrPkEh0YEacG7RGkLuDaMvMpy1mmfi7M1qruw")
          if (response.status !== 200) {
              return null
          }
          let data: RootObject = await response.json();
          this._allArticleOverviews.push(data)
      } catch {
          return null;
      }
    }
  }
}