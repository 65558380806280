import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { useNavigation } from "@react-navigation/native";
import SkeletonLoader from "expo-skeleton-loader";
import { useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import RenderHTML from "react-native-render-html";
import arrow_left from "../assets/images/arrow_left.png";
import black_gradient from "../assets/images/black_gradient.png";
import { Header } from "../Components/Header";
import { NavBar } from "../Components/NavBar";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";
import { ContentHandler } from "../Handlers/ContentHandler";
import NavigationHandler from "../Handlers/NavigationHandler";
import { Fields, getArticle } from "../Handlers/NewsContent/ArticleHandler";
import { NewsContentHandler } from "../Handlers/NewsContent/NewsContentHandler";

interface DatatypeArticle {
  data: Fields;
  image: { url: string; aspectRatio: number; isTitle: boolean };
}

const { width, height } = Dimensions.get("window");

export function NewsOverviewScreen() {
  const [shouldShowArticle, setShouldShowArticle] = useState(false);
  const navigation = useNavigation();

  const [articles, setArticles] = useState<DatatypeArticle[]>([]);
  const [titleArticle, setTitleArticle] = useState<DatatypeArticle | undefined>(
    undefined
  );

  useEffect(() => {
    AnalyticsHandler.getInstance().logScreenEvent("News Screen", "opened", "");
    (async () => {
      let articles = NewsContentHandler.getInstance().getArticlesToOverview(
        NavigationHandler.getInstance().currentSubject
      );
      if (articles === null) return;

      let title = await getArticle(articles.titelArtikel.sys.id);
      if (title !== null) setTitleArticle(title);
      const ra: DatatypeArticle[] = [];
      for (const a of articles.andereArtikel) {
        const ar = await getArticle(a.sys.id);
        if (ar !== null) ra.push(ar);
      }
      setArticles(ra);
    })();
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        ...Platform.select({ web: { maxHeight: "100vh" } }),
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          width: "100%",
          alignItems: "center",
          ...Platform.select({ web: { maxHeight: "100vh" } }),
          backgroundColor: Colors.background,
        }}
      >
        <Header isProfile={false} isDark={false} issuer={this} />
        <NavBar isHints={false} level={-10} isNews={true} issuer={this} />
        <View
          style={{
            marginTop: 75,
            width: "100%",
            alignItems: "center",
            flex: 1,
            marginBottom: 60,
            overflow: "hidden",
            maxWidth: Sizes.containerWidth,
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          <TouchableOpacity
            style={{ marginBottom: -45, paddingBottom: 20, zIndex: 10 }}
            onPress={() => navigation.goBack()}
          >
            <View
              style={{
                backgroundColor: Colors.gray,
                padding: 8,
                paddingLeft: 20,
                paddingRight: 20,
                borderBottomEndRadius: 10,
                borderBottomLeftRadius: 10,
                marginBottom: -8,
                zIndex: 10,
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontWeight: "700",
                  fontSize: 11,
                  zIndex: 10,
                }}
              >
                {NavigationHandler.getInstance().currentSubject}
              </Text>
            </View>
          </TouchableOpacity>
          <ScrollView
            style={{
              width: "100%",
              flex: 1,
              ...Platform.select({ ios: { overflow: "visible" } }),
              zIndex: 1,
            }}
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: "center",
              zIndex: 1,
              paddingBottom: 70,
            }}
            horizontal={false}
          >
            <DisplayedComponent
              hasArticle={shouldShowArticle}
              setShouldShowArticle={setShouldShowArticle}
              titleArticle={titleArticle}
              articles={articles}
            />
          </ScrollView>
        </View>
      </View>
    </SafeAreaView>
  );
}

const AvatarLayout = ({
  size = 100,
  style,
}: {
  size?: number;
  style?: ViewStyle;
}) => (
  <SkeletonLoader>
    <SkeletonLoader.Container
      style={[{ flex: 1, flexDirection: "row" }, style]}
    >
      <SkeletonLoader.Item
        style={{
          width: size,
          height: size,
          borderRadius: size / 2,
          marginRight: 20,
        }}
      />
      <SkeletonLoader.Container style={{ paddingVertical: 10 }}>
        <SkeletonLoader.Item
          style={{ width: 220, height: 20, marginBottom: 5 }}
        />
        <SkeletonLoader.Item style={{ width: 150, height: 20 }} />
      </SkeletonLoader.Container>
    </SkeletonLoader.Container>
  </SkeletonLoader>
);

function DisplayedComponent({ titleArticle, articles }) {
  const [selectedArticle, setSelectedArticle] = useState<DatatypeArticle>();
  const [hasArticle, setShouldShowArticle] = useState(false);
  const [w, setW] = useState(0);

  const isEmpty = titleArticle === undefined;

  useEffect(() => {
    if (selectedArticle !== undefined) {
      AnalyticsHandler.getInstance().logScreenEvent(
        "News Screen",
        "Article opened",
        selectedArticle.data.title
      );
    }
  }, [selectedArticle]);

  if (hasArticle && selectedArticle !== undefined) {
    return (
      <View style={{ width: "90%", maxWidth: 500, zIndex: 1, paddingTop: 20 }}>
        <TouchableOpacity
          containerStyle={{ width: 90, marginBottom: 12 }}
          onPress={() => {
            setShouldShowArticle(false);
          }}
        >
          <View style={styles.container}>
            <Image
              style={{
                width: 12,
                height: 12,
                marginRight: 8,
                opacity: 0.5,
                resizeMode: "contain",
              }}
              source={arrow_left}
            />
            <Text style={styles.back_button}>zurück</Text>
          </View>
        </TouchableOpacity>

        <Text style={{ fontSize: 20, marginBottom: 12, fontWeight: "bold" }}>
          {selectedArticle.data.title}
        </Text>
        <Text
          style={{
            marginBottom: 30,
            color: Colors.gold,
            fontSize: 12,
            fontWeight: "bold",
          }}
        >
          {parseDate(selectedArticle.data.date)}
        </Text>
        <View
          style={{
            marginBottom: 30,
            height: 250,
            width: "100%",
            shadowColor: "#000",
            shadowOpacity: 0,
            shadowOffset: { width: 0, height: 2 },
            shadowRadius: 4,
            backgroundColor: "white",
          }}
        >
          <Image
            source={{
              uri: "https://" + selectedArticle.image.url,
            }}
            style={{ width: "100%", height: "100%", resizeMode: "cover" }}
          />
        </View>

        <RenderHTML
          source={{
            html: ContentHandler.getInstance().parser(
              documentToHtmlString(selectedArticle.data.articleText)
            ),
          }}
          tagsStyles={{
            ul: { fontSize: 14, lineHeight: 22 },
            p: {
              fontSize: 14,
              textAlign: "justify",
              paddingVertical: 12,
              lineHeight: 22,
            },
            h5: {
              fontSize: 15,
              lineHeight: 27,
            },
          }}
        />

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <Text style={{ fontSize: 12, color: Colors.gold, marginRight: 7 }}>
            {selectedArticle.data.category}
          </Text>
          <Text
            style={{
              fontSize: 10,
              color: Colors.black_opacity,
              maxWidth: "60%",
            }}
          >
            {selectedArticle.data.author}
          </Text>
        </View>
        <View>
          <Text
            style={{
              color: Colors.black_opacity,
              fontSize: 12,
              fontWeight: "bold",
              marginTop: 20,
            }}
          >
            Quellen:
          </Text>
          <RenderHTML
            source={{
              html: ContentHandler.getInstance().parser(
                documentToHtmlString(selectedArticle.data.quelle)
              ),
            }}
            tagsStyles={{
              p: {
                fontSize: 10,
                color: Colors.black_opacity,
                paddingVertical: 12,
                lineHeight: 22,
              },
            }}
          />
        </View>
      </View>
    );
  } else {
    return (
      <View
        style={{
          width: "90%",
          alignItems: "center",
          flex: 1,
          marginTop: 50,
          maxWidth: 500,
          zIndex: 1,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
          onLayout={(e) => {
            var { x, y, width, height } = e.nativeEvent.layout;
            setW(width);
          }}
        >
          <Text
            style={{ fontSize: 22, color: Colors.black, fontWeight: "700" }}
          >
            News Section
          </Text>
          {/* <Image source={glasses} style={{ width: 35, height: 30 }} /> */}
        </View>

        {isEmpty ? (
          <SkeletonLoader style={{ marginVertical: 20, width: w }}>
            <SkeletonLoader.Item
              style={{ height: 250, width: w, borderRadius: 20, opacity: 0.35 }}
            />
          </SkeletonLoader>
        ) : (
          <TouchableOpacity
            containerStyle={{
              overflow: "visible",
              height: 250,
              width: "100%",
              marginTop: 20,
            }}
            onPress={() => {
              setSelectedArticle(titleArticle);
              setShouldShowArticle(true);
            }}
          >
            <View
              style={{
                height: 250,
                width: "100%",
                shadowColor: "#000",
                shadowOpacity: 0.12,
                shadowOffset: { width: 0, height: 2 },
                shadowRadius: 4,
                backgroundColor: "white",
                borderRadius: 20,
              }}
            >
              <Image
                source={{
                  uri: "https://" + titleArticle?.image.url,
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  resizeMode: "cover",
                  borderRadius: 20,
                }}
              />
              <Image
                source={black_gradient}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  zIndex: 0,
                  top: 0,
                  left: 0,
                  borderRadius: 20,
                }}
              />
              <Text
                style={{
                  fontSize: 17,
                  zIndex: 1,
                  width: "90%",
                  fontWeight: "700",
                  color: "white",
                  position: "absolute",
                  bottom: 20,
                  left: 20,
                }}
              >
                {titleArticle?.data.title}
              </Text>
            </View>
          </TouchableOpacity>
        )}
        <View
          style={{
            marginTop: 40,
            flexDirection: "row",
            width: "100%",
            alignContent: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{ color: Colors.black, fontWeight: "700", fontSize: 15 }}
          >
            Letzte Artikel
          </Text>
          {/* <Text
            style={{
              color: Colors.black_opacity,
              fontWeight: "500",
              fontSize: 11,
            }}
          >
            Mehr ansehen
          </Text> */}
        </View>
        {articles.length > 0 ? (
          articles.map((a) => {
            return (
              <TouchableOpacity
                key={Math.random()}
                style={{ width: "100%" }}
                onPress={() => {
                  setSelectedArticle(a);
                  setShouldShowArticle(true);
                }}
              >
                <InlineNewsComponent key={Math.random()} article={a} />
              </TouchableOpacity>
            );
          })
        ) : (
          <Text
            style={{ fontSize: 12, color: Colors.black_opacity, marginTop: 30 }}
          >
            Noch keine weiteren Artikel vorhanden
          </Text>
        )}
      </View>
    );
  }
}

function InlineNewsComponent({ article }) {
  return (
    <View
      style={{
        width: "100%",
        marginTop: 20,
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        minHeight: 100,
      }}
    >
      <View
        style={{
          height: 100,
          width: 100,
          shadowOpacity: 0.12,
          shadowOffset: { width: 0, height: 2 },
          shadowRadius: 4,
          backgroundColor: "white",
          borderRadius: 20,
        }}
      >
        <Image
          source={{ uri: "https://" + article.image.url }}
          style={{
            width: 100,
            height: 100,
            resizeMode: "cover",
            borderRadius: 20,
          }}
        />
      </View>

      <View
        style={{
          justifyContent: "space-between",
          padding: 16,
          alignItems: "flex-start",
          flex: 1,
          alignSelf: "stretch",
        }}
      >
        <Text style={{ fontSize: 12, fontWeight: "500", flex: 1 }}>
          {article.data.title}
        </Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 12, color: Colors.gold, marginRight: 7 }}>
            {article.data.category}
          </Text>
          <Text style={{ fontSize: 10, color: Colors.black_opacity }}>
            | {parseDate(article.data.date)}
          </Text>
        </View>
      </View>
    </View>
  );
}

const parseDate = (date: string) => {
  let year = date.substring(0, 4);
  let month = date.substring(5, 7);
  let day = date.substring(8, 10);

  return day + "." + month + "." + year;
};

const styles = StyleSheet.create({
  back_button: {
    fontSize: 12,
    color: Colors.black_opacity,
    fontWeight: "700",
  },
  sub_title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 15,
    textAlign: "left",
    color: Colors.light_green,
    marginBottom: 5,
    textTransform: "uppercase",
  },
  container: {
    marginTop: 10,
    marginBottom: 15,
    borderRadius: 50,
    padding: 12,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    backgroundColor: Colors.background,

    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
