import { FlatGrid } from "react-native-super-grid";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from "react-native";
import anasthesie from "../assets/images/anasthesie.png";
import cardio from "../assets/images/cardio.png";
import ce from "../assets/images/ce.png";
import complications from "../assets/images/hospital_bed.png";
import { Header } from "../Components/Header";
import { MedicalCategoryBox } from "../Components/MedicalCategoryBox";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import { AnalyticsHandler } from "../Handlers/AnalyticsHandler";
import NavigationHandler from "../Handlers/NavigationHandler";
import { NewsContentHandler } from "../Handlers/NewsContent/NewsContentHandler";
import UserHandler from "../Handlers/UserHandler";
import { getBoxWidth } from "../Handlers/LayoutHandler";
import { showSuccessMessage } from "../Handlers/ErrorHandler/ErrorHandler";
import { VERSION } from "../Constants/Versioning";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { isIPhoneWithMonobrow } from "react-native-status-bar-height";

export function SelectMedicalCategoryScreen() {
  const navigation = useNavigation();
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  const [loaded, setLoaded] = useState(false);

  const [activeCategories, setActiveCategories] = useState<
    { title: string; image: any }[]
  >([]);

  const dataToCategory = [
    {
      title: "Anästhesiologie",
      image: anasthesie,
    },
    {
      title: "Perioperative Komplikationen",
      image: complications,
    },
    { title: "Kardiologie", image: cardio },
  ];

  const insets = useSafeAreaInsets();
  NavigationHandler.getInstance().bottomInset = insets.bottom;

  useEffect(() => {
    if (NavigationHandler.getInstance().firstRoute)
      showSuccessMessage({
        title: "Willkommen bei MEDICEO",
        time: 7500,
        text:
          UserHandler.getInstance().getCurrentUser().role === "doc"
            ? "Entdecken Sie die zahlreichen Funktionen und Möglichkeiten in der Testphase.\nViel Spaß!"
            : "Entdecke die zahlreichen Funktionen und Möglichkeiten in der Testphase.\nViel Spaß!",
      });
    NavigationHandler.getInstance().firstRoute = false;
    (async () => {
      let success = await NavigationHandler.getInstance().getResPages();
      setLoaded(success);
    })();
    NewsContentHandler.getInstance().getArticleOverview();
    UserHandler.getInstance().setLogged(true);

    let plan = UserHandler.getInstance().getCurrentUser().paymentPlan;
    // plan = {
    //   plan: "full",
    //   categories: ["Perioperative Komplikationen", "Kardiologie"],
    // };
    console.log("plan", plan);
    if (plan === undefined) {
      setActiveCategories(dataToCategory);
    } else if (plan.plan === "full" || plan.plan === "") {
      console.log("filterd", "none");
      setActiveCategories(dataToCategory);
    } else {
      let filtered = dataToCategory.filter((f) =>
        plan.categories.includes(f.title)
      );
      console.log("filterd", filtered);
      setActiveCategories(filtered);
    }
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <View
        style={{
          backgroundColor: Colors.background,
          height: "20%",
          width: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
      />
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          width: "100%",
          alignItems: "center",
          backgroundColor: Colors.background,
        }}
      >
        <Header isProfile={false} isDark={false} issuer={undefined} />
        <View
          style={{
            marginTop: 75,
            width: "100%",
            alignItems: "center",
            flex: 1,
            overflow: "visible",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              flex: 1,
              paddingTop: 50,
            }}
          >
            <Text style={styles.title}>
              {UserHandler.getInstance().getCurrentUser().role === "doc"
                ? "WÄHLEN SIE EINEN THEMENBEREICH"
                : "WÄHLE EINEN THEMENBEREICH"}
            </Text>
            <Text style={styles.sub_title}>
              {UserHandler.getInstance().getCurrentUser().role === "doc"
                ? "Und finden Sie sofort die passende\nBehandlung für Ihren Patienten"
                : "Und finde sofort die passende\nBehandlung für Deinen Patienten"}
            </Text>

            <View
              onLayout={(event) => {
                const { width, height } = event.nativeEvent.layout;
                setDimensions({ height: height, width: width });
              }}
              style={{
                flex: 1,
                marginTop: 30,
                width: "100%",
                maxWidth: Sizes.containerWidth,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  width: "100%",
                }}
              >
                <FlatGrid
                  data={dimensions.height === 0 ? [] : activeCategories}
                  itemDimension={getBoxWidth(
                    dimensions.width,
                    dimensions.height
                  )}
                  renderItem={({ item }) => {
                    return (
                      <MedicalCategoryBox
                        text={item.title}
                        isActive={loaded}
                        image={item.image}
                        width={getBoxWidth(dimensions.width, dimensions.height)}
                        action={() => {
                          NavigationHandler.getInstance().currentSubject =
                            item.title;
                          AnalyticsHandler.getInstance().logSelectMedicalCategory(
                            item.title
                          );
                          resetPH();
                          navigation.navigate("HomeScreen");
                        }}
                      />
                    );
                  }}
                  adjustGridToStyles={true}
                  additionalRowStyle={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  contentContainerStyle={{
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                  itemContainerStyle={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></FlatGrid>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            width: "100%",
            alignItems: "center",
            marginTop: 50,
            ...Platform.select({ web: { paddingBottom: 12 } }),
          }}
        >
          <View
            style={{
              height: 1,
              width: "90%",
              marginBottom: 12,
              backgroundColor: Colors.black_opacity,
            }}
          />
          <View
            style={{
              flexDirection: "row",
              maxWidth: Sizes.containerWidth,
              justifyContent: "space-between",
              alignItems: "center",
              width: "80%",
              marginBottom:
                Platform.OS === "android"
                  ? 12
                  : isIPhoneWithMonobrow()
                  ? 0
                  : 12,
            }}
          >
            <Image
              source={ce}
              style={{
                width: 24,
                height: 24,
                resizeMode: "contain",
                marginRight: 20,
              }}
            />
            <Text
              style={{
                color: Colors.black_opacity,
                padding: 8,
                fontSize: 12,
                fontWeight: "700",
              }}
            >
              Version {VERSION}
            </Text>
            <Text
              style={{
                color: Colors.black_opacity,
                padding: 8,
                fontSize: 12,
                fontWeight: "700",
              }}
            >
              © 2022 MEDICEO GmbH
            </Text>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}

const resetPH = () => {
  NavigationHandler.getInstance().currentPHResult = "";
  if (NavigationHandler.getInstance().phRef !== undefined) {
    NavigationHandler.getInstance().phRef.setState({ phID: "" });
  }
};

const styles = StyleSheet.create({
  title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 20,
    letterSpacing: 0.7,
    textAlign: "center",
    color: "#000000",
  },
  sub_title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: Sizes.selectCategoryText,
    lineHeight: Sizes.selectCategoryTextLineHeight,
    textAlign: "center",
    color: "#C4C4C4",
    marginTop: 5,
  },
});
