import {
  View,
  Text,
  StyleSheet,
  Image,
  LayoutAnimation,
  TouchableOpacity,
} from "react-native";
import { Colors } from "../../Constants/Colors";
import exclamation from "../../assets/images/exclamation.png";
import chevron_down from "../../assets/images/chevron_down.png";
import chevron_up from "../../assets/images/chevron_up.png";
import { useEffect, useState } from "react";
import { CustomExpandsionAnimation } from "../../Animations/Animations";
import { Table, TableComponent, TableProps } from "./TableComponent";
import { TextComponent } from "./TextComponent";
import RenderHtml from "react-native-render-html";
import { Sizes } from "../../Constants/Sizes";
import { BoxTextComponent } from "./BoxTextComponent";
import { ContentHandler } from "../../Handlers/ContentHandler";

interface FirstResultProps {
  title: string;
  expandableContent: string;
  table: Table;
  weight: number;
  age: number;
  assetUrl: string | undefined;
}

export function WarningBoxHTML(props: FirstResultProps) {
  const [isExpanded, setExpanded] = useState(false);
  const isMultilie = props.expandableContent !== "";

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    ContentHandler.getInstance().addToRefreshBoxes(setRefresh);
  });

  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
      }}
    >
      <TouchableOpacity
        onPress={() => {
          LayoutAnimation.configureNext(CustomExpandsionAnimation);
          setExpanded(!isExpanded);
        }}
        disabled={!isMultilie}
        activeOpacity={0.7}
      >
        <View style={styles.result_container}>
          <View
            style={{
              padding: 12,
              paddingLeft: 20,
              paddingRight: 20,
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                width: "100%",
                alignItems: "center",
              }}
            >
              <View style={{ flex: 1 }}>
                {props.assetUrl === undefined ? (
                  <BoxTextComponent
                    content={props.title}
                    style={htmlStylesTitle}
                  />
                ) : (
                  <Image
                    source={{ uri: "http://" + props.assetUrl }}
                    style={{
                      width: "100%",
                      height: 200,
                      resizeMode: "contain",
                    }}
                  />
                )}
              </View>
              <Image
                source={exclamation}
                style={{
                  width: 24,
                  height: 18,
                  resizeMode: "contain",
                  marginLeft: 18,
                  tintColor: Colors.red,
                }}
              />
            </View>

            <Image
              source={isExpanded ? chevron_up : chevron_down}
              style={{
                width: isMultilie ? 22 : 0,
                height: isMultilie ? 12 : 0,
                resizeMode: "contain",
                tintColor: Colors.red,
                marginLeft: 12,
              }}
            />
          </View>
          <View
            style={{
              width: "93%",
              padding: isExpanded ? 12 : 0,
              height: isExpanded ? undefined : 0,
              opacity: isExpanded ? 1 : 0,
              marginBottom: isExpanded ? -12 : 0,
            }}
          >
            <BoxTextComponent
              content={props.expandableContent}
              style={htmlStylesContent}
            />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const htmlStylesTitle = {
  p: {
    fontWeight: "700",
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    color: Colors.red,
  },
  ul: {
    marginLeft: -12,
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    color: Colors.red,
    display: "inline-block",
  },
  ol: {
    marginLeft: -12,
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    color: Colors.red,
    display: "inline-block",
  },
  li: {
    marginBottom: Sizes.liMarginBottom,
    marginLeft: 6,
  },
};

const htmlStylesContent = {
  p: {
    color: Colors.black_opacity,
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginHorizontal: 20,
  },
  ul: {
    marginLeft: -12,
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    color: Colors.black_opacity,
    display: "inline-block",
  },
  li: {
    marginBottom: Sizes.liMarginBottom,
    marginLeft: 6,
  },
};

const styles = StyleSheet.create({
  result_container: {
    borderRadius: 10,
    borderWidth: 2,
    borderColor: Colors.red,
    flexDirection: "column",
    backgroundColor: Colors.white,
  },
  result_text: {
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 15,
    flex: 0.5,
    color: Colors.red,
  },
  content_text: {
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 15,
    color: Colors.red,
    opacity: 0.5,
  },
});
