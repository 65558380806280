import { useState } from "react";
import {
  Image,
  Keyboard,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { NavigationProps } from "../App";
import logo from "../assets/images/logo.png";
import { CustomButton } from "../Components/CustomButton";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import { showErrorMessage } from "../Handlers/ErrorHandler/ErrorHandler";
import UserHandler from "../Handlers/UserHandler";

export function ForgotPasswordScreen({ navigation }: NavigationProps) {
  const [didSend, setDidSend] = useState(false);
  const [mail, setMail] = useState("");

  return (
    <TouchableWithoutFeedback
      style={{ backgroundColor: "#fff" }}
      onPress={() =>
        Platform.select({ ios: Keyboard.dismiss, android: Keyboard.dismiss })
      }
      accessible={false}
    >
      <SafeAreaView
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "flex-start",
          paddingTop: 20,
          backgroundColor: "#fff",
        }}
      >
        <KeyboardAwareScrollView style={{ width: "100%" }}>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Image
              source={logo}
              style={{
                width: "90%",
                height: 280,
                resizeMode: "contain",
                ...Platform.select({
                  web: { height: "20vh", marginBottom: 50 },
                }),
              }}
            />

            <Text style={styles.welcomeText}>PASSWORT ZURÜCKSETZEN</Text>
            <Text style={styles.greetingText}>
              E-Mail eingeben und Passwort zurücksetzen.
            </Text>

            <TextInput
              placeholder="E-Mail"
              keyboardType="email-address"
              style={styles.textField}
              onChangeText={(t) => setMail(t)}
            />
          </View>
          <View
            style={{
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                width: "85%",
                alignItems: "center",
                marginTop: 50,
              }}
            >
              <CustomButton
                backgroundColor="#fff"
                foregroundColor={didSend ? Colors.gray : Colors.gold}
                text={didSend ? "E-MAIL VERSENDET" : "ZURÜCKSETZEN"}
                action={async () => {
                  let success =
                    await UserHandler.getInstance().sendForgotPwMail(mail);
                  if (success) {
                    setDidSend(true);
                  } else {
                    showErrorMessage({
                      title: "Senden fehlgeschlagen!",
                      text: "Das senden der E-Mail ist fehlgeschlagen. Bitte probieren Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie support@medi.ceo.",
                    });
                  }
                }}
                enabled={didSend}
                borderColor={didSend ? Colors.gray : Colors.gold}
              />
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("LoginScreen");
                  }}
                >
                  <Text style={styles.actionText}>Jetzt anmelden?</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  welcomeText: {
    fontSize: 20,
    fontWeight: "700",
  },
  greetingText: {
    marginTop: 3,
    fontSize: 14,
    fontWeight: "700",
    color: "#C4C4C4",
    marginBottom: 30,
  },
  textField: {
    backgroundColor: "#fafafa",
    shadowColor: "#000",
    shadowOpacity: 0.12,
    borderRadius: 80,
    width: "85%",
    height: 50,
    padding: 12,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    marginBottom: 12,
    maxWidth: Sizes.containerWidth,
  },
  box: {
    backgroundColor: "#fff",
    borderRadius: 5,
    width: 126,
    height: 43,
    borderColor: "#c4c4c433",
    borderWidth: 1,
  },
  actionText: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 12,
    fontWeight: "700",
    opacity: 0.5,
    marginBottom: 20,
    marginTop: 12,
  },
});
