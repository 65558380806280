import { useState } from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { ActivityIndicator, DefaultTheme } from "react-native-paper";
import { Colors } from "../Constants/Colors";
import { getBoxWidth } from "../Handlers/LayoutHandler";

interface CategoryProps {
  text: string;
  image: ImageSourcePropType;
  action: () => void;
  isActive: boolean;
  width?: number;
}

export function MedicalCategoryBox(props: CategoryProps) {
  const [selected, setSelected] = useState(false);

  return (
    <TouchableWithoutFeedback
      onPressIn={() => setSelected(true)}
      onPressOut={() => setSelected(false)}
      onPress={props.action}
      disabled={!props.isActive}
    >
      <View
        style={[
          selected ? styles.box_selected : styles.box_unselected,
          {
            height: props.width,
            aspectRatio: 1,
            width: props.width,
            justifyContent: !props.isActive ? "center" : "space-around",
          },
        ]}
      >
        {props.isActive ? null : (
          <ActivityIndicator
            theme={{
              colors: {
                ...DefaultTheme.colors,
                primary: Colors.gold,
              },
            }}
            size={Platform.OS === "web" ? "large" : "small"}
            style={{ position: "absolute", zIndex: 10, opacity: 100 }}
          />
        )}
        <Text
          style={[
            selected
              ? styles.category_text_selected
              : styles.category_text_unselected,
            { opacity: props.isActive ? 1 : 0.05 },
          ]}
        >
          {props.text}
        </Text>
        <View
          style={{
            height: "75%",
            width: "75%",
            marginBottom: -10,
            opacity: props.isActive ? 1 : 0.05,
          }}
        >
          <Image
            style={{
              width: "100%",
              height: "100%",
              resizeMode: "contain",
              tintColor: selected ? Colors.gold : "#000000",
            }}
            source={props.image}
          />
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  box_unselected: {
    borderWidth: 4,
    color: Colors.gold,
    borderColor: Colors.gold,
    opacity: 1,
    borderRadius: 20,
    padding: 16,
    flex: 0.5,
    backgroundColor: "white",
    aspectRatio: 1,
    margin: 8,
    alignItems: "center",
    ...Platform.select({ web: { cursor: "pointer" } }),
    justifyContent: "space-around",
  },
  box_selected: {
    borderWidth: 4,
    borderColor: Colors.gold,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 16,
    flex: 0.5,
    aspectRatio: 1,
    margin: 8,
    alignItems: "center",
    justifyContent: "space-around",
  },
  category_text_unselected: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: 15,
    paddingTop: 2,
    textAlign: "center",
    color: "#000",
  },
  category_text_selected: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: 15,
    paddingTop: 2,
    overflow: "visible",
    textAlign: "center",
    color: Colors.gold,
  },
});
