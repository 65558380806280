import { Image, StyleSheet, Text, View } from "react-native";
import arrow_down from "../../assets/images/down-arrow.png";

interface DecisionTreeProps {
  title: string;
  color: string;
}

export function ArrowBox(props: DecisionTreeProps) {
  return (
    <View
      style={{
        width: "100%%",
        marginTop: 10,
        alignItems: "center",
        justifyContent: "center",
        marginBottom: -10,
      }}
    >
      <View style={styles.container}>
        <Image
          source={arrow_down}
          style={{
            height: 24,
            width: 24,
            resizeMode: "contain",
            tintColor: props.color,
          }}
        />
        {props.title === undefined || props.title === "" ? null : (
          <Text
            style={[
              styles.content_text,
              { color: props.color, marginLeft: 12 },
            ]}
          >
            {props.title}
          </Text>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 0,

    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: 75,
  },
  content_text: {
    fontSize: 12,
    lineHeight: 15,
    fontWeight: "700",
  },
});
