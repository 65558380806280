import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import { Colors } from "../Constants/Colors";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { ActionRecommendationBoxHTML } from "../MainScreenComponents/ResultBoxComponents/ActionRecommendationBoxHTML";
import { AddWeightAgeBox } from "../MainScreenComponents/ResultBoxComponents/AddWeightAgeBox";
import { ArrowBox } from "../MainScreenComponents/ResultBoxComponents/ArrowBox";
import { DosingBoxHTML } from "../MainScreenComponents/ResultBoxComponents/DosingBoxHTML";
import { FurtherActionHTML } from "../MainScreenComponents/ResultBoxComponents/FurtherActionHTML";
import { HintBoxHTML } from "../MainScreenComponents/ResultBoxComponents/HintBoxHTML";
import { InfoBoxHTML } from "../MainScreenComponents/ResultBoxComponents/InfoBoxHTML";
import { LargeDosingBoxHTML } from "../MainScreenComponents/ResultBoxComponents/LargeDosingBoxHTML";
import { MultipleChoiceActionBoxHTML } from "../MainScreenComponents/ResultBoxComponents/MultipleChoiceActionBoxHTML";
import { TextBox } from "../MainScreenComponents/ResultBoxComponents/TextBoxHTML";
import { WarningBoxHTML } from "../MainScreenComponents/ResultBoxComponents/WarningBoxHTML";
import { showErrorMessage } from "./ErrorHandler/ErrorHandler";
import NavigationHandler from "./NavigationHandler";
import { EasyReadingDetailBox } from "../MainScreenComponents/ResultBoxComponents/EasyReadingDetailBox";

// INTERFACE RESULT

export class ContentHandler {
  static instance;

  _isResult = false;

  hasMulitpleChoiceBoxes = false;
  /**
   * @returns {ContentHandler}
   */
  static getInstance() {
    if (this.instance == null) {
      this.instance = new ContentHandler();
    }
    return this.instance;
  }

  getIsResult() {
    return this._isResult;
  }

  async getContent() {}

  _localResultStorage = {};

  async request(id) {
    this.hasMulitpleChoiceBoxes = false;
    return await this.handleData(await this.getBoxData(id));
  }

  async handleData(data) {
    let r = [];
    this._boxesRefresh = [];

    const title = data.fields.title;
    const resID = data.fields.pageId;
    let sources = data.fields.sources;

    if (sources !== undefined) {
      sources = documentToHtmlString(sources);
      sources = this.parser(sources);
    }

    if (data.fields.actionRecommendationBox !== undefined) {
      this._isResult = true;
      if (data.fields.filter !== undefined) {
        if (data.fields.filter.length !== 0) {
          var filters = data.fields.filters;
        }
      }
      for (const f of Object.entries(data.fields.actionRecommendationBox)) {
        let c = this.getBoxData(f[1].sys.id);
        r.push(await this.handleBox(c, true));
      }
    } else if (data.fields.boxes !== undefined) {
      if (data.fields.nextPage !== undefined) {
        var nextPage = data.fields.nextPage;
      }
      this._isResult = false;
      for (const f of Object.entries(data.fields.boxes)) {
        let c = this.getBoxData(f[1].sys.id);
        r.push(await this.handleBox(c, true));
      }
    }

    return {
      title: title,
      boxes: r,
      pageId: resID,
      sources: sources,
      filters: filters,
      nextPage: nextPage,
    };
  }

  async handleBox(b, shouldHaveAsset) {
    const bType = b.sys.contentType.sys.id;
    if (bType === "actionRecommendationBox") {
      var image = undefined;
      if (shouldHaveAsset) {
        if (b.fields.title.content[0].data?.target?.sys?.id !== undefined) {
          image = await this.getAssetURL(
            b.fields.title.content[0].data?.target?.sys?.id,
            true
          );
        } else if (
          b.fields.title.content[1]?.data?.target?.sys?.id !== undefined
        ) {
          if (b.fields.title.content[1].data?.target?.sys?.id !== undefined) {
            image = await this.getAssetURL(
              b.fields.title.content[1].data?.target?.sys?.id,
              true
            );
          }
        }
      }

      const t = documentToHtmlString(b.fields.title);
      const ex = documentToHtmlString(b.fields.expandableContent);

      var b = {
        title: this.parser(t, false),
        expandableContent: this.parser(ex, true),
      };

      var box = (
        <ActionRecommendationBoxHTML
          key={Math.random()}
          title={b.title}
          expandableContent={b.expandableContent}
          inlineImage={image}
        />
      );
    } else if (bType === "warningBox") {
      var image = undefined;
      if (shouldHaveAsset) {
        if (b.fields.title.content[0].data?.target?.sys?.id !== undefined) {
          image = await this.getAssetURL(
            b.fields.title.content[0].data?.target?.sys?.id,
            true
          );
        }
      }

      const t = documentToHtmlString(b.fields.title);
      const ex = documentToHtmlString(b.fields.expandableContent);

      var b = {
        title: this.parser(t, false),
        expandableContent: this.parser(ex, true),
      };

      var box = (
        <WarningBoxHTML
          key={Math.random()}
          title={b.title}
          expandableContent={b.expandableContent}
          inlineImage={image}
        />
      );
    } else if (bType === "hintBox") {
      var image = undefined;
      if (shouldHaveAsset) {
        if (b.fields.title.content[0].data?.target?.sys?.id !== undefined) {
          image = await this.getAssetURL(
            b.fields.title.content[0].data?.target?.sys?.id,
            true
          );
        } else if (
          b.fields.expandableContent?.content[0]?.data?.target?.sys?.id !==
          undefined
        ) {
          image = await this.getAssetURL(
            b.fields.expandableContent.content[0].data?.target?.sys?.id,
            false
          );
        }
      }

      const t = documentToHtmlString(b.fields.title);
      const ex = documentToHtmlString(b.fields.expandableContent);

      var b = {
        title: this.parser(t, false),
        expandableContent: this.parser(ex, true),
      };

      var box = (
        <HintBoxHTML
          key={Math.random()}
          title={b.title}
          expandableContent={b.expandableContent}
          inlineImage={image}
        />
      );
    } else if (bType === "dosingRecommendationBox") {
      const t = documentToHtmlString(b.fields.title);
      const ex = documentToHtmlString(b.fields.content);

      if (b.fields.annotation !== undefined) {
        const a = this.getAnnotationData(b.fields.annotation.sys.id);
        const title = documentToHtmlString(a.fields.title);

        if (a.fields.content !== undefined) {
          var content = a.fields.content;
        }
        let type = 0;
        if (a.fields.isAWarning !== undefined) {
          if (a.fields.isAWarning === true) type = 1;
        }

        var annotation = {
          title: this.parser(title, false),
          content: content,
          type: type,
        };
      }

      var b = {
        title: this.parser(t, false),
        content: this.parser(ex, false),
      };

      var box = (
        <DosingBoxHTML
          key={Math.random()}
          title={b.title}
          content={b.content}
          annotation={annotation}
        />
      );
    } else if (bType === "largeContentBox") {
      const t = documentToHtmlString(b.fields.title);
      //const ex = documentToHtmlString(b.fields.content);

      var image = undefined;

      if (b.fields.content?.content[0].data?.target?.sys?.id !== undefined) {
        if (shouldHaveAsset) {
          image = await this.getAssetURL(
            b.fields.content?.content[0].data?.target?.sys?.id,
            true
          );
        }
        if (b.fields.content?.content.length > 1) {
          var ex = documentToHtmlString(b.fields.content);
        }
      } else if (
        b.fields.content?.content[1]?.data?.target?.sys?.id !== undefined
      ) {
        if (shouldHaveAsset) {
          image = await this.getAssetURL(
            b.fields.content?.content[1].data?.target?.sys?.id,
            true
          );
        }
        if (b.fields.content?.content.length > 1) {
          var ex = documentToHtmlString(b.fields.content);
        }
      } else {
        var ex = documentToHtmlString(b.fields.content);
      }

      if (b.fields.annotation !== undefined) {
        const a = this.getAnnotationData(b.fields.annotation.sys.id);
        const title = documentToHtmlString(a.fields.title);
        let type = 1;
        if (b.fields.isWarning !== undefined) {
          type = 0;
        }
        var annotation = {
          title: this.parser(title, false),
          type: type,
        };
      }

      var b = {
        title: this.parser(t, false),
        content: this.parser(ex, false),
      };

      var box = (
        <LargeDosingBoxHTML
          key={Math.random()}
          title={b.title}
          content={b.content}
          annotation={annotation}
          inlineImage={image}
        />
      );
    } else if (bType === "infoBox") {
      const t = b.fields.title;
      const c = b.fields.color;
      const i = b.fields.resultId;

      var b = {
        title: t,
        color: this.colorParser(c),
        id: i,
      };

      var box = (
        <InfoBoxHTML
          key={Math.random()}
          title={b.title}
          color={b.color}
          id={b.id}
        />
      );
    } else if (bType === "furtherResultPageBox") {
      var image = undefined;

      if (b.fields.title?.content[0].data?.target?.sys?.id !== undefined) {
        if (shouldHaveAsset) {
          image = await this.getAssetURL(
            b.fields.title?.content[0].data?.target?.sys?.id,
            true
          );
        }
        if (b.fields.title?.content.length > 1) {
          var t = documentToHtmlString(b.fields.title);
        }
      } else if (
        b.fields.title?.content[1]?.data?.target?.sys?.id !== undefined
      ) {
        if (shouldHaveAsset) {
          image = await this.getAssetURL(
            b.fields.title?.content[1].data?.target?.sys?.id,
            true
          );
        }
        if (b.fields.title?.content.length > 1) {
          var t = documentToHtmlString(b.fields.title);
        }
      } else {
        var t = documentToHtmlString(b.fields.title);
      }

      if (b.fields.filterId !== undefined) {
        var filter = b.fields.filterId;
      }

      const i = b.fields.resultOrNodeId;

      var b = {
        title: t,
        id: i,
      };

      var box = (
        <FurtherActionHTML
          key={Math.random()}
          title={b.title}
          id={b.id}
          inlineImage={image}
          filter={filter}
        />
      );
    } else if (bType === "textBox") {
      const t = b.fields.title;
      const c = b.fields.color;

      var b = {
        title: t,
        color: this.colorParser(c),
      };

      var box = <TextBox key={Math.random()} title={b.title} color={b.color} />;
    } else if (bType === "arrowBox") {
      const t = b.fields.title;
      const c = b.fields.color;

      var b = {
        title: t,
        color: this.colorParser(c),
      };

      var box = (
        <ArrowBox key={Math.random()} title={b.title} color={b.color} />
      );
    } else if (bType === "calculatorBox") {
      const t = b.fields.title;
      const unit = b.fields.unit;
      this._currentUnit = unit;

      var box = <AddWeightAgeBox key={Math.random()} unit={unit} title={t} />;
    } else if (bType === "multipleChoiceActionBox") {
      const t = b.fields.title;
      const filter = b.fields.filterId;
      const nextPage = b.fields.nextPage;
      this.hasMulitpleChoiceBoxes = true;
      var box = (
        <MultipleChoiceActionBoxHTML
          title={t}
          filterId={filter}
          nextPage={nextPage}
        />
      );
    } else if (bType === "easyReadingInfoBox") {
      const t = b.fields.titel;
      const dt = b.fields.detailTitle;
      console.log(t, dt);
      let ex = documentToHtmlString(b.fields.expandableContent);
      ex = this.parser(ex, true);
      var box = (
        <EasyReadingDetailBox
          title={"<p>" + t + "</p>"}
          detailTitle={"<p>" + dt + "</p>"}
          expandableContent={ex}
        />
      );
    }
    return box;
  }

  colorParser(c) {
    if (c === "red") {
      return Colors.red;
    } else if (c === "green") {
      return Colors.light_green;
    } else if (c === "gray") {
      return Colors.black_opacity;
    } else if (c === "gold") {
      return Colors.gold;
    }
  }

  parser(i, isExpandable) {
    let o = i;

    if (o.includes("&lt;br&gt;")) {
      o = o.replace(new RegExp("&lt;br&gt;", "g"), "<br>");
    }

    if (o.includes("<li><p>")) {
      o = o.replace(new RegExp("<li><p>", "g"), "<li>");
    }
    if (o.includes("</p></li>")) {
      o = o.replace(new RegExp("</p></li>", "g"), "</li>");
    }
    if (o.includes("</p></ul>")) {
      o = o.replace(new RegExp("</p></ul>", "g"), "</ul>");
    }
    if (o.includes("</p><ul>")) {
      o = o.replace(new RegExp("</p><ul>", "g"), "<ul>");
    }
    if (o.includes("<p></p>")) {
      o = o.replace(new RegExp("<p></p>", "g"), "");
    }

    if (o.replace(new RegExp("(\r\n|\n|\r)", "gm"), "").endsWith("<p></p>")) {
      o = o.replace(new RegExp("(\r\n|\n|\r)", "gm"), "");
      o = o.slice(0, -8);
    }

    if (
      o.substr(o.length - 4) !== "</ul>" &&
      isExpandable &&
      o.replace(/\s/g, "") !== ""
    ) {
      o += "<br>";
    }

    return o;
  }

  _allData = [];
  allNodePages = [];
  allFurtherActionBoxes = [];
  allResultPages = [];

  async loadAllData() {
    let success = await this.get(0, 4300);
    this.getAllNodePages();
    this.getAllFurtherActionBoxes();
    this.getAllResultPages();

    return success;
  }

  async get(skip, all) {
    if (skip > all) {
      NavigationHandler.getInstance().setLastUpdateTime();
      AsyncStorage.setItem("storedData", JSON.stringify(this._allData));
      return true;
    }
    const url = `https://cdn.contentful.com/spaces/aepv59ipfyvq/environments/master/entries?access_token=Fbi4zRIrPkEh0YEacG7RGkLuDaMvMpy1mmfi7M1qruw&limit=500&skip=${skip}`;
    try {
      let r = await fetch(url);
      let d = await r.json();

      this._allData = this._allData.concat(d.items);

      return await this.get(skip + 500, 4300);
    } catch {
      try {
        let data = await AsyncStorage.getItem("storedData");
        if (data !== null) {
          this._allData = JSON.parse(data);
        }
        NavigationHandler.getInstance().setOldAccessTime();
        return true;
      } catch {
        showErrorMessage({
          title: "Keine Offline Daten vorhanden",
          text: "Loggen Sie sich einmal mit einer aktiven Internetverbindung ein. Danach sind alle Inhalte auch offline einsehbar.",
        });
        return false;
      }
    }
  }

  customLinkParser(source) {
    if (!source.includes("https://")) {
      return source;
    }
  }

  getAnnotationData(id) {
    const a = this.getBoxData(id);
    return a;
  }

  issueSearch(query) {
    let tier_one = [];
    let tier_three = [];
    let tier_two = [];
    let tier_four = [];

    for (let f of this._allData) {
      if (
        f.fields.title?.toString().toLowerCase().includes(query.toLowerCase())
      ) {
        tier_one.push(f);
      }

      let c = this.getBoxData(f?.sys?.id);
      if (c.fields?.title !== undefined) {
        var doc = documentToHtmlString(c.fields?.title);

        if (doc.toLowerCase().includes(query.toLowerCase()))
          tier_two.push({ id: f.sys.id, text: this.parser(doc) });
      }
      if (c.fields?.content !== undefined) {
        var doc = documentToHtmlString(c.fields?.content);

        if (doc.toLowerCase().includes(query.toLowerCase()))
          tier_three.push({ id: f?.sys?.id, text: this.parser(doc) });
      } else if (c.fields?.expandableContent !== undefined) {
        var doc = documentToHtmlString(c.fields?.expandableContent);

        if (doc.toLowerCase().includes(query.toLowerCase()))
          tier_three.push({ id: f?.sys?.id, text: this.parser(doc) });
      }

      if (f.fields.pageId !== undefined) {
        if (f.fields.pageId?.toString().toLowerCase() === query.toLowerCase())
          tier_four.push(f);
      }
    }

    const res = {
      tierOne: tier_one.map((m) => {
        return { title: m.fields?.title, id: m.sys.id };
      }),
      tierTwo: tier_two.map((m) => {
        let c = this.getResPageTitleFromBoxID(m.id);
        return {
          title: c.title,
          text: m.text,
          id: c.id,
        };
      }),
      tierThree: tier_three.map((m) => {
        let c = this.getResPageTitleFromBoxID(m.id);
        return {
          title: c.title,
          text: m.text,
          id: c.id,
        };
      }),
      tierFour: tier_four.map((m) => {
        return { title: m.fields?.title, id: m.sys.id };
      }),
    };

    // INSERT SUBJECT FILTER

    return res;
  }

  getResPageTitleFromBoxID(id) {
    let res = this._allData.filter((f) => {
      if (f.fields.actionRecommendationBox !== undefined) {
        let r = false;
        for (const _f of Object.entries(f.fields.actionRecommendationBox)) {
          if (_f[1].sys.id === id) {
            r = true;
          }
        }
        return r;
      }
      return false;
    });
    if (res !== []) {
      return { title: res[0]?.fields?.title, id: res[0]?.sys?.id };
    } else {
      return null;
    }
  }

  getAllNodePages() {
    let np = this._allData.filter((f) => {
      if (f.fields.boxes !== undefined) {
        return true;
      }
      return false;
    });
    this.allNodePages = np;
  }

  getAllFurtherActionBoxes() {
    let np = this._allData.filter((f) => {
      if (f.sys.contentType.sys.id === "furtherResultPageBox") {
        return true;
      }
      return false;
    });
    this.allFurtherActionBoxes = np;
  }

  getDataFromLocal(id) {
    const d = this._allData.items.filter((f) => {
      f.sys?.id === id;
    });
    if (d !== undefined) {
      let data = d[0];
      return data;
    }
  }

  getAllResultPages() {
    let np = this._allData.filter((f) => {
      if (f.sys.contentType.sys.id === "resultPage") {
        return true;
      }
      return false;
    });
    this.allResultPages = np;
  }

  getBoxData(id) {
    const d = this._allData.filter((f) => {
      return f.sys.id === id;
    });
    if (d !== undefined && d !== []) {
      let data = d[0];
      return data;
    } else {
      showErrorMessage({
        title: "Verbindungsprobleme",
        text: "Versuchen Sie die Seite neu zu laden. Sollte der Fehler weiterhin bestehen, kontaktieren Sie uns unter support@medi.ceo",
      });
    }

    // const url =
    //   "https://cdn.contentful.com/spaces/aepv59ipfyvq/environments/master/entries/2Pay5k6eAdX8xh1aFUxO4M?access_token=Fbi4zRIrPkEh0YEacG7RGkLuDaMvMpy1mmfi7M1qruw

    // try {
    //   let r = await fetch(url);
    //   let d = r.json();
    //   return d;
    // } catch {
    //   showErrorMessage({
    //     title: "Verbindungsprobleme",
    //     text: "Versuchen Sie die Seite neu zu laden. Sollte der Fehler weiterhin bestehen, kontaktieren Sie uns unter support@medi.ceo",
    //   });
    //   return undefined;
    // }
  }

  _boxesRefresh = [];
  _currentUnit = "";

  refreshBoxes() {
    for (let f of this._boxesRefresh) {
      f((v) => {
        return !v;
      });
    }
  }

  addToRefreshBoxes(f) {
    if (!this._boxesRefresh.includes(f)) this._boxesRefresh.push(f);
  }

  removeFromRefreshBoxes(f) {
    this._boxesRefresh = this._boxesRefresh.filter((_f) => _f !== f);
  }

  async getAssetURL(id, isTitle) {
    let content = await this.getContent(id);

    const url = content.fields.file.url.slice(2);
    const aspect =
      content.fields.file.details.image.width /
      content.fields.file.details.image.height;

    return {
      url: url,
      aspectRatio: aspect,
      isTitle: isTitle,
    };
  }

  _offlineData = [];

  addBookmarkToOffline(result) {
    // let boxes = [];
    // boxes = result.boxes.map(b => {
    //     console.log(b);
    //     return {
    //         id: String(b.type).split("(props)")[0].split("function")[1],
    //         props: b.props
    //     }
    // });
    this._offlineData.push({
      id: result.id,
      //boxes: boxes,
      title: result.title,
    });
    //console.log(boxes);
    AsyncStorage.setItem(
      "offlineData",
      JSON.stringify({ data: this._offlineData })
    );
  }

  removeBookmarkFromOffline(result) {
    this._offlineData = this._offlineData.filter((r) => r.id !== result.id);
    AsyncStorage.setItem(
      "offlineData",
      JSON.stringify({ data: this._offlineData })
    );
  }

  getResultFromOffline(id) {
    let d = this._offlineData.filter((i) => i.id === id);
    if (d !== undefined) {
      return d[0];
    }
    return {
      id: "",
      title: "",
      boxes: [],
    };
  }

  async getContent(id) {
    const url =
      "https://cdn.contentful.com/spaces/aepv59ipfyvq/environments/master/assets/" +
      id +
      "?access_token=iDWxjVOcLDA9APcu1N3TGI3GHkXfa5Z6doDlhH1X6-o";
    try {
      let r = await fetch(url);
      let d = r.json();
      return d;
    } catch {
      showErrorMessage({
        title: "Verbindungsprobleme",
        text: "Versuchen Sie die Seite neu zu laden. Sollte der Fehler weiterhin bestehen, kontaktieren Sie uns unter support@medi.ceo",
      });
      return undefined;
    }
  }

  storageDataToElement(result) {
    // console.log(result);
    let elements = result.boxes.map((b) => {
      if (b.id.includes("ActionRecommendationBox")) {
        return (
          <ActionRecommendationBoxHTML
            title={b.props.title}
            expandableContent={b.props.expandableContent}
            inlineImage={b.props.inlineImage}
          />
        );
      }
    });
    return {
      id: result.id,
      boxes: elements,
      title: result.title,
    };
  }
}
