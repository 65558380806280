import { useNavigation } from "@react-navigation/native";
import { default as Lottie, default as LottieView } from "lottie-react-native";
import { useRef, useState } from "react";
import {
  Image,
  Platform,
  Text,
  Vibration,
  View,
  TouchableOpacity,
} from "react-native";
import ReactNativeHapticFeedback from "react-native-haptic-feedback";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import squares from "../assets/images/back_arrow.png";
import bookmark_large from "../assets/images/bolt_single.png";
import cross from "../assets/images/cross.png";
import doc_icon from "../assets/images/doc_icon.png";
import hints_icon from "../assets/images/hints_icon.png";
import { Colors } from "../Constants/Colors";
import LocalBackHandler from "../Handlers/BackHandler";
import NavigationHandler from "../Handlers/NavigationHandler";

interface NavBarProps {
  level: number;
  issuer: any;
  isHints: boolean;
  isNews: boolean;
  isTutorial?: boolean;
}

function category_to_image(category: string) {
  if (NavigationHandler.getInstance().currentSubject === "Anästhesiologie") {
    if (category == "Kinder") {
      return squares;
    } else if (category == "Diabetiker") {
      return squares;
    } else if (category == "Alte & Adipöse") {
      return squares;
    } else {
      return squares;
    }
  } else {
    if (category === "Allgemein") {
      return squares;
    } else {
      return squares;
    }
  }
}

export function NavBar(props: NavBarProps) {
  const navigation = useNavigation();
  const options = {
    enableVibrateFallback: true,
    ignoreAndroidSystemSettings: false,
  };
  const [isLoading, setIsLoading] = useState(false);
  const animationRef = useRef<Lottie>(null);

  let timer: NodeJS.Timeout;
  let clickCount = 0;
  // 0 is main, 1 is hints, 2 is news
  const [currentSelection, setCurrentSelection] = useState(
    props.isHints ? 1 : 0
  );

  return (
    <View
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        flex: 3,
        alignItems: "flex-end",
        height: 60,
        backgroundColor: props.isTutorial ? "transparent" : "#fff",
        borderWidth: 1,
        borderColor: props.isTutorial ? "transparent" : "#c4c4c4",
        borderBottomColor: "#ffffff00",
        borderLeftColor: "#ffffff00",
        borderRightColor: "#ffffff00",
        flexDirection: "row",
        shadowColor: "#000",
        zIndex: 10,
        shadowOffset: { width: 0, height: -4 },
        shadowRadius: 3,
        shadowOpacity: 0.1,
      }}
    >
      {!props.isTutorial ? null : (
        <View
          style={{
            position: "absolute",
            bottom: 130,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              borderRadius: 12,
              maxWidth: "80%",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Text style={{ padding: 12, flex: 1 }}>
              {Platform.OS === "web"
                ? "Einmal Klicken, um eine Ebene zurück zu gelangen. Doppelklick, um zum Anfang der Auswahl zu gelangen."
                : "Einmal Klicken, um eine Ebene zurück zu gelangen. Gedrückt halten, um zum Anfang der Auswahl zu gelangen."}
            </Text>
            <TouchableOpacity
              style={{ padding: 12 }}
              onPress={() => {
                NavigationHandler.getInstance().homeRef!.setState({
                  isTutorial: false,
                });
              }}
            >
              <Text style={{ color: Colors.gold_darker }}>OK</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
      {NavigationHandler.getInstance().currentSubject === "" ? (
        <View style={{ flex: 1 }} />
      ) : (
        <TouchableOpacity
          onPress={() => {
            if (!props.isHints) {
              navigation.navigate("PracticalHintsScreen");
            } else {
              navigation.navigate("HomeScreen");
            }
          }}
          style={{ flex: 1 }}
        >
          <View
            style={{
              paddingBottom: 12,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              source={hints_icon}
              style={{
                width: 21,
                height: 33,
                opacity: props.isHints ? 1 : 0.3,
                tintColor: props.isHints ? Colors.gold : Colors.black,
                resizeMode: "contain",
              }}
            />
          </View>
        </TouchableOpacity>
      )}
      <View>
        <View
          style={{
            backgroundColor: props.isTutorial ? "transparent" : "#fff",
            width: 100,
            height: 58,
            position: "absolute",
            bottom: 0,
            zIndex: 2,
          }}
        />
        <View
          style={{
            width: 100,
            height: 100,
            borderColor: "#c4c4c4",
            borderRadius: 50,
            borderWidth: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
          }}
        />

        <View
          style={{
            width: 77,
            height: 77,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 3,
            bottom: 11.5,
            left: 11.5,
            position: "absolute",
          }}
        >
          <TouchableOpacity
            activeOpacity={0.6}
            onLongPress={() => {
              LocalBackHandler.getInstance().multipleBack(
                props.isHints,
                props.isNews,
                props.level
              );
              ReactNativeHapticFeedback.trigger("impactMedium", options);
            }}
            onPress={() => {
              if (props.isTutorial) {
                NavigationHandler.getInstance().homeRef!.setState({
                  isTutorial: false,
                });
              }
              if (Platform.OS === "web") {
                clickCount++;
                if (clickCount == 2) {
                  LocalBackHandler.getInstance().multipleBack(
                    props.isHints,
                    props.isNews,
                    props.level
                  );
                  ReactNativeHapticFeedback.trigger("impactMedium", options);
                  clearTimeout(timer);
                  clickCount = 0;
                } else {
                  timer = setTimeout(() => {
                    LocalBackHandler.getInstance().singleBack(
                      props.isHints,
                      props.isNews,
                      props.level
                    );
                    clickCount = 0;
                  }, 150);
                }
              } else {
                LocalBackHandler.getInstance().singleBack(
                  props.isHints,
                  props.isNews,
                  props.level
                );
              }
            }}
            onPressIn={() => {
              if (Platform.OS === "web") return;
              timer = setTimeout(() => {
                setIsLoading(true);
                animationRef.current?.play(0, 60);
              }, 150);
            }}
            onPressOut={() => {
              if (Platform.OS === "web") return;
              clearTimeout(timer);
              setIsLoading(false);
            }}
          >
            <View
              style={{
                width: 77,
                height: 77,
                borderColor: Colors.gold,
                borderRadius: 38.5,
                borderWidth: isLoading ? 0 : 3,
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LottieView
                ref={animationRef}
                style={{
                  width: isLoading ? 98 : 0,
                  height: isLoading ? 98 : 0,
                  position: "absolute",
                  borderRadius: 38.5,
                }}
                speed={2}
                source={require("../assets/animations/loading.json")}
                autoPlay={false}
                loop={false}
                onAnimationFinish={() => {
                  animationRef.current?.pause();
                  setIsLoading(false);
                }}
              />
              {getIconToLevel(
                props.level === -1
                  ? -1
                  : props.isHints &&
                    NavigationHandler.getInstance().homeRef!.state
                      .currentLevel === 0
                  ? 1
                  : NavigationHandler.getInstance().homeRef!.state.currentLevel,
                props.isHints,
                props.isNews
              )}
            </View>
          </TouchableOpacity>
        </View>
      </View>

      {NavigationHandler.getInstance().currentSubject === "" ? (
        <View style={{ flex: 1 }} />
      ) : (
        <TouchableOpacity
          onPress={() => {
            if (!props.isNews) {
              navigation.navigate("NewsOverviewScreen");
            } else {
              navigation.navigate("HomeScreen");
            }
          }}
          style={{ flex: 1 }}
        >
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: 12,
            }}
          >
            <Image
              source={doc_icon}
              style={{
                width: 24,
                height: 32,
                opacity: props.isNews ? 1 : 0.3,
                resizeMode: "contain",
                tintColor: props.isNews ? Colors.gold : Colors.black,
              }}
            />
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
}

function getIconToLevel(level: number, isHints: boolean, isNews: boolean) {
  isHints = isHints || isNews;

  if (level === 0) {
    return (
      <Image
        source={bookmark_large}
        style={{
          width: 27,
          height: 42,
          opacity: isHints ? 0.3 : 1,
          resizeMode: "contain",
          tintColor: isHints ? Colors.black : Colors.gold,
        }}
      />
    );
  } else if (level === 1) {
    return (
      <Image
        source={squares}
        style={{
          width: 36,
          opacity: isHints ? 0.3 : 1,
          height: 36,
          resizeMode: "contain",
          tintColor: isHints ? Colors.black : Colors.gold,
        }}
      />
    );
  } else if (level === 2) {
    return (
      <Image
        source={category_to_image(
          NavigationHandler.getInstance().getCurrentTarget()
        )}
        style={{
          width: 36,
          marginRight: 2,
          height: 36,
          opacity: isHints ? 0.3 : 1,
          resizeMode: "contain",
          tintColor: isHints ? Colors.black : Colors.gold,
        }}
      />
    );
  } else if (level === -1) {
    return (
      <Image
        source={cross}
        style={{
          width: 32,
          height: 25,
          opacity: isHints ? 0.3 : 1,
          resizeMode: "contain",
          tintColor: isHints ? Colors.black : Colors.gold,
        }}
      />
    );
  } else {
    return (
      <Image
        source={squares}
        style={{
          width: 36,
          height: 36,
          opacity: isHints ? 0.3 : 1,
          resizeMode: "contain",
          tintColor: isHints ? Colors.black : Colors.gold,
        }}
      />
    );
  }
}
