import { FlatList } from "react-native-gesture-handler";
import { Image, Text, TouchableOpacity, View } from "react-native";
import arrow_right_search from "../assets/images/arrow_right_search.png";
import { SafeAreaView } from "react-native-safe-area-context";
import { Colors } from "../Constants/Colors";
import { universities } from "./ConstRegistryFiles";
import UserHandler from "../Handlers/UserHandler";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Searchbar } from "react-native-paper";

export function SelectUniversityScreen() {
  const navigation = useNavigation();
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState([]);

  const onChangeSearch = (query: string) => {
    setSearchQuery(query);
    if (query === "") {
      setSearchData(data);
    }
    if (searchQuery.length > 2) {
      let searchData = data.filter((d) =>
        d.value.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSearchData(searchData);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        let response = await fetch("https://api.medi.ceo/uni_list");
        let uni_data = await response.json();
        setData(uni_data);
        setSearchData(uni_data);
      } catch {
        return;
      }
    })();
  }, []);

  return (
    <SafeAreaView style={{ height: "100%", width: "100%" }}>
      <Searchbar
        style={{
          borderColor: Colors.black_opacity,
          borderWidth: 1,
          margin: 12,
        }}
        onChangeText={onChangeSearch}
        value={searchQuery}
        inputStyle={{ fontSize: 15 }}
        on
        onSubmitEditing={() => {
          if (searchQuery.length > 2) {
            let searchData = data.filter((d) =>
              d.value.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setSearchData(searchData);
          }
          if (searchQuery === "") {
            setSearchData(data);
          }
        }}
      />
      <FlatList
        data={searchData}
        renderItem={({ item }: { item: any }) => {
          return (
            <TouchableOpacity
              style={{ flex: 1, width: "100%", backgroundColor: "white" }}
              onPress={() => {
                UserHandler.getInstance()._setClinic(item.value);
                navigation.goBack();
              }}
            >
              <View
                style={{
                  width: "100%",
                  padding: 20,
                  borderBottomColor: "#ddd",
                  borderBottomWidth: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flex: 1,
                }}
              >
                <Text>{item.value}</Text>
                <Image
                  source={arrow_right_search}
                  style={{
                    marginLeft: 20,
                    width: 10,
                    height: 20,
                    tintColor: Colors.black_opacity,
                    resizeMode: "contain",
                  }}
                />
              </View>
            </TouchableOpacity>
          );
        }}
      ></FlatList>
    </SafeAreaView>
  );
}
