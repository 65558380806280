import { LayoutAnimation } from "react-native";
import NavigationHandler, { Result } from "./NavigationHandler";
import { navigationRef } from "./RootNavigation";

export default class LocalBackHandler {
  static _instance: undefined | LocalBackHandler;

  isFromBack = false;
  static getInstance() {
    if (this._instance === undefined) this._instance = new LocalBackHandler();

    return this._instance;
  }

  singleBack(isHints: boolean, isNews: boolean, level: number) {
    const navInstance = NavigationHandler.getInstance();

    if (isHints || isNews) {
      if (navInstance.isFromInfo) navInstance.setCurrentPHResult("");

      navInstance.isPH = false;
      navigationRef.goBack();
      return;
    }

    if (navInstance.homeRef === undefined) {
      navigationRef.goBack();
      return;
    }

    if (level === 0) {
      navigationRef.navigate("BookmarksScreen", {
        isFromProfile: false,
      });
      navInstance.homeRef.setState({ currentLevel: 0 });
      return;
    } else if (level === -1) {
      navigationRef.goBack();
      return;
    }

    if (
      (navInstance.homeRef.state.previousNodes.length > 0 ||
        navInstance.homeRef.state.previousResults.length > 0) &&
      !NavigationHandler.getInstance().isCustomResult &&
      navInstance.homeRef.state.currentLevel > 2
    ) {
      if (
        !navInstance.shouldShowResult() ||
        (navInstance.shouldShowResult() &&
          navInstance.homeRef!.state.previousResults.length === 0)
      ) {
        let prevNodes = navInstance.homeRef!.state.previousNodes;
        const prev = prevNodes[prevNodes.length - 1];
        if (!navInstance.shouldShowResult()) prevNodes.splice(-1);
        if (
          navInstance.homeRef!.state.previousResults.length === 0 &&
          navInstance.shouldShowResult()
        )
          prevNodes.splice(-1);
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        navInstance.setShouldShowResult(false);
        navInstance.removeLastFromPath();
        navInstance.homeRef!.setState({
          currentID: prev,
          currentLevel: navInstance.homeRef!.state.currentLevel - 1,
          previousNodes: prevNodes,
        });
        if (navInstance.isCustomResult) {
          navInstance.customResult.pop();
        }
      } else {
        this.isFromBack = true;

        let prevRes = navInstance.homeRef!.state.previousResults;
        const prev = prevRes[prevRes.length - 1];
        prevRes.splice(-1);
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        navInstance.setCurrentResultID(prev);
        navInstance.removeLastFromPath();
        navInstance.homeRef!.setState({
          currentLevel: navInstance.homeRef!.state.currentLevel - 1,
          resID: navInstance.homeRef!.state.resID - 1,
          previousResults: prevRes,
        });
      }
    } else {
      this.multipleBack(false, false, navInstance.homeRef.state.currentLevel);
    }
  }

  multipleBack(isHints: boolean, isNews: boolean, level: number) {
    const navInstance = NavigationHandler.getInstance();
    if (navInstance.homeRef === undefined) {
      navigationRef.goBack();
      return;
    }
    if (navInstance.homeRef!.state.isFromSearch) {
      navInstance.setShouldShowResult(false);
      navInstance.removeLastFromPath();
      navInstance.getCurrentTargetRef()!.setState({
        currentLevel: 0,
        currentID: 0,
        isFromSearch: false,
      });
      return;
    }
    //if (!isHints && !isNews) {
    navInstance.reset();

    if (level === 1) {
      navInstance.setShouldShowResult(false);
      navInstance.removeLastFromPath();
      navInstance
        .getCurrentTargetRef()!
        .setState({ currentLevel: 0, currentID: 0 });
      navInstance.getCurrentTargetRef()!.setState({
        previousResults: [],
        previousNodes: [],
      });
    } else if (level === 2) {
      navInstance.resetPathToSubject();
      navInstance.setShouldShowResult(false);
      navInstance.getCurrentTargetRef()!.setState({
        previousResults: [],
        previousNodes: [],
      });
      navInstance
        .getCurrentTargetRef()!
        .setState({ currentLevel: 1, currentID: 0 });
    } else if (level === 0) {
      navigationRef.navigate("BookmarksScreen", {
        isFromProfile: false,
      });
    } else if (level === -1) {
      navigationRef.goBack();
    } else {
      navInstance.resetPathToSubject();
      navInstance.getCurrentTargetRef()!.setState({
        currentLevel: 1,
        currentID: 0,
        resID: navInstance.getCurrentTargetRef()!.state.resID + 1,
      });
    }
    // } else {
    //   if (navInstance.isFromInfo) navInstance.setCurrentPHResult("");

    //   navInstance.isPH = false;
    //   navigationRef.goBack();
    // }
  }

  handleBack(res: Result) {
    this.isFromBack = false;

    const allPageFilters = getAllPageFilters(res);
    console.log("called", allPageFilters);

    if (allPageFilters.length > 0) {
      NavigationHandler.getInstance().currentFilters =
        NavigationHandler.getInstance().currentFilters.filter(
          (filter) => !allPageFilters.includes(filter)
        );
    }

    function getAllPageFilters(res: Result) {
      let ra = [];
      for (const box of res.boxes) {
        if (box.props?.filter !== undefined) ra.push(box.props.filter);
      }
      return ra;
    }
  }
}
