import React, { Component } from "react";
import ImageView from "react-native-image-view";

import PropTypes from "prop-types";
import cross from "../../assets/images/cross.png";
import Toast from "./Toast";
import { TouchableOpacity } from "react-native-gesture-handler";
import {
  Text,
  View,
  useWindowDimensions,
  Image,
  Animated,
  Platform,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { PinchGestureHandler, State } from "react-native-gesture-handler";

let IMAGE;
let REF;

class Root extends Component {
  state = { reload: false };

  init() {
    REF = this;
  }

  render() {
    this.init();
    return (
      <>
        {this.props.children}

        {IMAGE !== undefined ? (
          <View
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "black",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
            }}
          >
            <View
              style={{
                position: "absolute",
                top: 50,
                right: 20,
              }}
            >
              {/* <TouchableOpacity
                style={{ padding: 8 }}
                onPress={() => (
                  
                )}
              >
                <Image
                  source={cross}
                  style={{
                    width: 24,
                    height: 24,
                  }}
                />
              </TouchableOpacity> */}
            </View>
            {Platform.OS === "web" ? (
              <Image
                source={{ uri: "http://" + IMAGE.uri }}
                style={{ width: "100%", height: 400, resizeMode: "contain" }}
              />
            ) : (
              <PinchableBox image={IMAGE} />
            )}
          </View>
        ) : null}
        <Toast
          ref={(c) => {
            if (c) {
              Toast.toastInstance = c;
            }
          }}
        />
      </>
    );
  }
}

const PinchableBox = ({ image }) => {
  const dimension = useWindowDimensions();
  return (
    <ImageView
      images={[
        {
          source: {
            uri: "http://" + image.uri,
          },
          title: "",
          width: dimension.width,
          height: dimension.width / image.aspect,
        },
      ]}
      onClose={() => {
        (IMAGE = undefined), REF.setState({ reload: !REF.state.reload });
      }}
      imageIndex={0}
      isVisible={IMAGE !== undefined}
      renderFooter={(currentImage) => <View></View>}
    />
  );
};

Root.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export function showLightboxImage(image) {
  IMAGE = image;
  REF.setState({ reload: !REF.state.reload });
}

export default Root;
