import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Text, TextInput, TouchableOpacity, View } from "react-native";
import { Colors } from "../Constants/Colors";

export function PWScreen() {
  const [pw, setPw] = useState("");
  const nav = useNavigation();

  useEffect(() => {
    (async () => {
      const hasPw = await AsyncStorage.getItem("hasPW");

      if (hasPw !== null) {
        if (hasPw === "true") {
          nav.navigate("LaunchScreen");
        }
      }
    })();
  }, []);

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TextInput
        placeholder={"Passwort"}
        placeholderTextColor={"gray"}
        style={{
          maxWidth: 500,
          width: "80%",
          borderRadius: 20,
          borderColor: "black",
          padding: 12,
          borderWidth: 1,
        }}
        onChangeText={(t) => setPw(t)}
      />
      <TouchableOpacity
        onPress={() => {
          if (pw === "MEDICEO_2022#") {
            AsyncStorage.setItem("hasPW", "true");
            nav.navigate("LaunchScreen");
          }
        }}
      >
        <View
          style={{
            padding: 12,
            backgroundColor: Colors.gold,
            borderRadius: 12,
            marginTop: 20,
          }}
        >
          <Text style={{ fontSize: 15, fontWeight: "700", color: "white" }}>
            Überprüfen
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}
