import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { initializeFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
let config = {
  // apiKey: "AIzaSyBLmhMclUq9WpBApZ9XQ-hoggfSUGkIR7U",
  // authDomain: "medify-826de.firebaseapp.com",
  // databaseURL:
  //   "https://medify-826de-default-rtdb.europe-west1.firebasedatabase.app",
  // projectId: "medify-826de",
  // storageBucket: "medify-826de.appspot.com",
  // messagingSenderId: "7305114246",
  // appId: "1:7305114246:web:d1fb6402a4ea6b660f1707",
  // measurementId: "G-PKXGYMHVXC",
  apiKey: "AIzaSyBLmhMclUq9WpBApZ9XQ-hoggfSUGkIR7U",
  authDomain: "medify-826de.firebaseapp.com",
  databaseURL:
    "https://medify-826de-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "medify-826de",
  storageBucket: "medify-826de.appspot.com",
  messagingSenderId: "7305114246",
  appId: "1:7305114246:web:d1fb6402a4ea6b660f1707",
  measurementId: "G-PKXGYMHVXC",
};

let app;

if (firebase.apps.length === 0) {
  app = initializeApp(config);
} else {
  app = firebase.app();
}

const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
});

export { db };
