import { useState } from "react";
import {
  Image,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import Lightbox from "react-native-lightbox-v2";
import RenderHTML, { MixedStyleRecord } from "react-native-render-html";
import circle_selected from "../../assets/images/circle_selected.png";
import circle_unselected from "../../assets/images/circle_unselected.png";
import { Colors } from "../../Constants/Colors";
import { Sizes } from "../../Constants/Sizes";
import { showLightboxImage } from "../../Handlers/ErrorHandler/Root";
import NavigationHandler from "../../Handlers/NavigationHandler";
import { InlineImage } from "./HintBoxHTML";

interface DecisionTreeProps {
  title: string;
  id: string;
  inlineImage: InlineImage | undefined;
  filter: string | undefined;
}

export function FurtherActionHTML(props: DecisionTreeProps) {
  const [selected, setSelected] = useState(false);

  return (
    <View
      style={{
        width: "100%",
        ...Platform.select({ web: { cursor: "pointer" } }),
      }}
    >
      <TouchableWithoutFeedback
        style={{ width: "100%" }}
        onPress={() => {
          if (props.filter !== undefined) {
            NavigationHandler.getInstance().currentFilters.push(props.filter);
          }

          if (props.id?.includes("node:")) {
            const node = Number(props.id.split("node:")[1]);
            NavigationHandler.getInstance().setShouldShowResult(false);
            NavigationHandler.getInstance().addToPath(props.title);
            NavigationHandler.getInstance()
              .getCurrentTargetRef()!
              .setState({
                currentID: node,
                currentLevel:
                  NavigationHandler.getInstance().getCurrentTargetRef()!.state
                    .currentLevel + 1,
              });
          } else {
            NavigationHandler.getInstance().addToPath(props.title);
            NavigationHandler.getInstance().navigateToNextPage(props.id);
          }
        }}
        onLongPress={() => {
          showLightboxImage({uri: props.inlineImage.url, aspect: props.inlineImage?.aspectRatio});
        }}
        onPressIn={() => setSelected(true)}
        onPressOut={() => setSelected(false)}
      >
        <View
          style={[
            styles.container,
            {
              borderColor: selected ? Colors.gold : Colors.white,
              backgroundColor: selected
                ? props.inlineImage !== undefined
                  ? "#fff"
                  : "#FFF8DA"
                : Colors.white,
            },
          ]}
        >
          <View style={{ width: "90%" }}>
            {props.title !== undefined ? (
              <RenderHTML
                contentWidth={Sizes.containerWidth}
                tagsStyles={contentHtml}
                source={{ html: props.title }}
              />
            ) : null}
            {props.inlineImage !== undefined ? (
              <Image
                style={{
                  aspectRatio: props.inlineImage.aspectRatio,
                  width: "100%",
                  marginTop: 5,
                  ...Platform.select({ web: { maxHeight: "20vh" } }),
                  resizeMode: "contain",
                }}
                source={{ uri: "http://" + props.inlineImage.url }}
              />
            ) : null}
          </View>
          <Image
            source={selected ? circle_selected : circle_unselected}
            style={{
              width: 20,
              height: 20,
              resizeMode: "contain",
            }}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
}

const contentHtml = {
  p: {
    color: "#000",
    opacity: 0.5,
    fontSize: 12,
    lineHeight: Sizes.lineHeightBoxText,
    fontWeight: "700",
    marginRight: 20,
  },
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    borderWidth: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.12,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
  content_text: {
    color: "#000",
    opacity: 0.5,
    fontSize: 12,
    lineHeight: 15,
    fontWeight: "700",
    marginRight: 20,
  },
});
