import { useState } from "react";
import {
  Image,
  LayoutAnimation,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import Lightbox from "react-native-lightbox-v2";
import RenderHtml from "react-native-render-html";
import { CustomExpandsionAnimation } from "../../Animations/Animations";
import chevron_down from "../../assets/images/chevron_down.png";
import chevron_up from "../../assets/images/chevron_up.png";
import { Colors } from "../../Constants/Colors";
import { Sizes } from "../../Constants/Sizes";

export interface InlineImage {
  url: string;
  aspectRatio: number;
  isTitle: boolean;
}

interface FirstResultProps {
  title: string;
  expandableContent: string;
  weight: number;
  age: number;
  inlineImage: InlineImage | undefined;
}

export function HintBoxHTML(props: FirstResultProps) {
  const [width, setWidth] = useState(300);
  const [isExpanded, setExpanded] = useState(false);
  const isMultilie =
    props.expandableContent != "" ||
    (!props.inlineImage?.isTitle && props.inlineImage !== undefined);

  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
      }}
    >
      <TouchableOpacity
        onPress={() => {
          LayoutAnimation.configureNext(CustomExpandsionAnimation);
          setExpanded(!isExpanded);
        }}
        disabled={!isMultilie}
        activeOpacity={0.7}
      >
        <View style={styles.result_container}>
          <View
            style={{
              padding: 12,
              width: "100%",
              justifyContent: "space-between",
              marginLeft: 12,
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <View style={{ flexDirection: "row", flex: 1, overflow: "hidden" }}>
              <View style={{ flex: 1 }}>
                {props.inlineImage === undefined ||
                !props.inlineImage.isTitle ? (
                  <RenderHtml
                    source={{ html: props.title }}
                    tagsStyles={htmlStylesTitle}
                  />
                ) : (
                  <Lightbox>
                    <Image
                      source={{ uri: "http://" + props.inlineImage.url }}
                      style={{
                        width: "100%",
                        aspectRatio: props.inlineImage.aspectRatio,
                        resizeMode: "contain",
                        borderRadius: 10,
                      }}
                    />
                  </Lightbox>
                )}
              </View>
            </View>

            <Image
              source={isExpanded ? chevron_up : chevron_down}
              style={{
                width: isMultilie ? 22 : 0,
                height: isMultilie ? 12 : 0,
                resizeMode: "contain",
                marginLeft: 12,
                marginRight: 12,
                opacity: 0.5,
              }}
            />
          </View>
          <View
            style={{
              width: "93%",
              height: isExpanded ? undefined : 0,
              opacity: isExpanded ? 1 : 0,
              marginBottom: isExpanded ? -12 : 0,
              overflow: "hidden",
            }}
            onLayout={(event) => {
              var { x, y, width, height } = event.nativeEvent.layout;
              setWidth(width);
            }}
          >
            {props.inlineImage === undefined || props.inlineImage.isTitle ? (
              <RenderHtml
                source={{ html: props.expandableContent }}
                tagsStyles={{
                  table: {
                    width: width,
                  },
                  tr: {},
                  td: {
                    color: Colors.black_opacity,
                  },
                  th: {
                    color: Colors.gold,
                    borderBottomColor: Colors.gold,
                    borderBottomWidth: 1,
                  },
                  p: {
                    color: Colors.black_opacity,
                    fontSize: Sizes.boxText,
                    lineHeight: Sizes.lineHeightBoxText,
                    marginHorizontal: 20,
                  },
                  ul: {
                    fontSize: Sizes.boxText,
                    lineHeight: Sizes.lineHeightBoxText,
                    marginRight: 8,
                    color: Colors.black_opacity,
                  },
                  ol: {
                    fontSize: Sizes.boxText,
                    lineHeight: Sizes.lineHeightBoxText,
                    marginRight: 8,
                    color: Colors.black_opacity,
                  },
                  li: {
                    marginBottom: Sizes.liMarginBottom,
                    marginLeft: 6,
                  },
                }}
              />
            ) : (
              <Lightbox>
                <Image
                  source={{ uri: "http://" + props.inlineImage.url }}
                  style={{
                    width: "100%",
                    aspectRatio: props.inlineImage.aspectRatio,
                    resizeMode: "contain",
                    marginBottom: 28,
                    marginLeft: 18,
                  }}
                />
              </Lightbox>
            )}
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const htmlStylesTitle = {
  p: {
    fontWeight: "700",
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    color: Colors.black_opacity,
  },
  ul: {
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginRight: 12,

    marginLeft: -12,
    color: Colors.black_opacity,
    display: "inline-block",
  },
  ol: {
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginRight: 12,
    marginLeft: -12,
    color: Colors.black_opacity,
    display: "inline-block",
  },
  li: {
    marginBottom: 8,
    marginLeft: 6,
  },
};

const htmlStylesContent = {
  p: {
    color: Colors.black_opacity,
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginHorizontal: 20,
  },
  ul: {
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginRight: 8,
    color: Colors.black_opacity,
    display: "inline-block",
  },
  li: {
    marginBottom: 8,
    marginLeft: 6,
  },
};

const styles = StyleSheet.create({
  result_container: {
    borderRadius: 10,
    borderWidth: 2,
    borderColor: Colors.black_opacity,
    flexDirection: "column",
    backgroundColor: Colors.white,
    alignItems: "center",
  },
  result_text: {
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 15,
    flex: 0.5,
    color: Colors.black_opacity,
  },
  content_text: {
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 15,
    color: Colors.black_opacity,
    opacity: 0.5,
  },
});
