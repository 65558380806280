import { Colors } from "../../Constants/Colors";
import Toast from "./Toast";

interface ErrorMessageProps {
  title: string;
  text: string;
  time?: number | undefined;
}

export function showErrorMessage(props: ErrorMessageProps) {
  const { title, text } = props;

  Toast.show({
    title: title,
    text: text,
    color: Colors.red,
    backgroundColor: Colors.red,
    timeColor: Colors.black_opacity,
    timing: 5000,
    position: "bottom",
  });
}

export function showSuccessMessage(props: ErrorMessageProps) {
  let { title, text, time } = props;
  if (time === undefined) time = 5000;
  Toast.show({
    title: title,
    text: text,
    color: Colors.gold_darker,
    backgroundColor: Colors.gold_darker,
    timeColor: Colors.black_opacity,
    timing: time,
    position: "bottom",
  });
}
