export const Sizes = {
  boxText: 13,
  lineHeightBoxText: 18,
  liMarginBottom: 12,
  decisionText: 13,
  decisionTextLineHeight: 17,
  selectCategoryText: 13,
  selectCategoryTextLineHeight: 17,
  containerWidth: 800,
};
