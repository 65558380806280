
import CryptoJS from "react-native-crypto-js";
import { Sizes } from '../Constants/Sizes';
import * as sha512 from 'js-sha512';

export class EncryptionHandler {

    static instance;

    /**
     * @returns {EncryptionHandler}
     */
    static getInstance() {
        if (this.instance == null) {
            this.instance = new EncryptionHandler();
        }
        return this.instance;
    }


    getAuth() {
        const date = new Date();
        const month = "mediceo" + ("0" + (date.getMonth() + 1)).slice(-2);
        return sha512.sha512(month);
    }
    
    getEncryptedUser(user) {
        const key = CryptoJS.enc.Utf8.parse('3246837857201409')
        const iv = CryptoJS.enc.Utf8.parse('hWpUKavY4ww1FwOb')
        
       

       // console.log(iv_key);
        const cipher = CryptoJS.AES.encrypt(user, key, {
            iv: iv, // parse the IV 
            mode: CryptoJS.mode.CBC
        })
        
        // e.g. B6AeMHPHkEe7/KHsZ6TW/Q==
        return cipher.toString()
    }

}