import { useEffect, useState } from "react";
import {
  Image,
  LayoutAnimation,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import Lightbox from "react-native-lightbox-v2";
import { CustomExpandsionAnimation } from "../../Animations/Animations";
import chevron_down from "../../assets/images/chevron_down.png";
import chevron_up from "../../assets/images/chevron_up.png";
import { Colors } from "../../Constants/Colors";
import { Sizes } from "../../Constants/Sizes";
import { ContentHandler } from "../../Handlers/ContentHandler";
import { BoxTextComponent } from "./BoxTextComponent";
import { InlineImage } from "./HintBoxHTML";

interface FirstResultProps {
  title: string;
  weight: number | undefined;
  age: number | undefined;
  expandableContent: string;
  inlineImage: InlineImage | undefined;
}

export function ActionRecommendationBoxHTML(props: FirstResultProps) {
  const [isExpanded, setExpanded] = useState(false);
  const isMultilie = props.expandableContent != "";

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    ContentHandler.getInstance().addToRefreshBoxes(setRefresh);
  });

  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
      }}
    >
      <TouchableOpacity
        onPress={() => {
          LayoutAnimation.configureNext(CustomExpandsionAnimation);
          setExpanded(!isExpanded);
        }}
        disabled={!isMultilie}
        activeOpacity={0.7}
      >
        <View style={styles.result_container}>
          <View
            style={{
              padding: 12,
              paddingLeft: 20,
              paddingRight: 20,
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <View style={{ flexDirection: "row", flex: 1, overflow: "hidden" }}>
              <View style={{ width: "100%" }}>
                <BoxTextComponent
                  content={props.title}
                  style={htmlStylesTitle}
                />
                {props.inlineImage === undefined ||
                !props.inlineImage.isTitle ? null : (
                  <Lightbox
                    style={{
                      ...Platform.select({
                        web: { width: "100%", height: "100%" },
                      }),
                    }}
                  >
                    <Image
                      source={{ uri: "http://" + props.inlineImage.url }}
                      style={{
                        width: "100%",
                        aspectRatio: props.inlineImage.aspectRatio,
                        ...Platform.select({ web: { maxHeight: "50vh" } }),
                        resizeMode: "contain",
                      }}
                    />
                  </Lightbox>
                )}
              </View>
            </View>

            <Image
              source={isExpanded ? chevron_up : chevron_down}
              style={{
                width: isMultilie ? 22 : 0,
                height: isMultilie ? 12 : 0,
                resizeMode: "contain",
                marginLeft: 12,
                tintColor: Colors.light_green,
              }}
            />
          </View>
          <View
            style={{
              width: "93%",
              padding: isExpanded ? 0 : 0,
              height: isExpanded ? undefined : 0,
              opacity: isExpanded ? 1 : 0,
              marginBottom: isExpanded ? 0 : 0,
            }}
          >
            <BoxTextComponent
              content={props.expandableContent}
              style={htmlStylesContent}
            />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const htmlStylesTitle = {
  p: {
    fontWeight: "600",
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    color: Colors.light_green,
  },
  ul: {
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginRight: 8,
    color: Colors.light_green,
    display: "inline-block",
    marginBottom: 2,
  },
  li: {
    marginLeft: 6,
    marginBottom: Sizes.liMarginBottom,
  },
};

const htmlStylesContent = {
  p: {
    color: Colors.black_opacity,
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginHorizontal: 20,
  },

  ul: {
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginRight: 8,
    color: Colors.black_opacity,
    display: "inline-block",
  },
  li: {
    marginBottom: Sizes.liMarginBottom,
    marginLeft: 6,
  },
};

const styles = StyleSheet.create({
  result_container: {
    borderRadius: 10,
    borderWidth: 2,
    // paddingLeft: 16,
    // paddingRight: 16,
    // paddingBottom: 16,
    // paddingTop: 16,
    backgroundColor: Colors.white,
    borderColor: Colors.light_green,
  },
  result_text: {
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 15,
    color: Colors.light_green,
  },
});
