import { Platform, StyleSheet, Text, UIManager, View } from "react-native";
import { SubCategoryOption } from "../Components/SubCategoryOption";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";
import NavigationHandler from "../Handlers/NavigationHandler";
import { HomeScreen } from "../Screens/HomeScreen";

interface DecisionTreeProps {
  issuer: HomeScreen;
}

export function SelectSubCategoryComponent(props: DecisionTreeProps) {
  if (
    Platform.OS === "android" &&
    UIManager.setLayoutAnimationEnabledExperimental
  ) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  if (props.issuer.state.previousNodes.length > 0) {
    props.issuer.setState({ previousNodes: [] });
  }

  return (
    <View
      style={{
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "90%",
        minHeight: "100%",
        overflow: "visible",
      }}
    >
      <Text style={styles.sub_title}>
        {NavigationHandler.getInstance().getCurrentTarget()}
      </Text>
      <Text style={styles.title}>THEMA WÄHLEN</Text>
      <View
        style={{
          marginTop: 30,
          marginBottom: 30,
          width: "100%",
          flex: 1,
          justifyContent: "center",
        }}
      >
        {getComponents(props.issuer)}
      </View>
    </View>
  );
}

function getComponents(issuer: HomeScreen) {
  return NavigationHandler.getInstance().currentTopics.map((topic) => {
    return (
      <SubCategoryOption
        key={Math.random()}
        text={topic}
        action={() => {
          NavigationHandler.getInstance().setCurrentSubTarget(topic);
          NavigationHandler.getInstance().addToPath(topic);
          issuer.setState({ currentLevel: 2 });
        }}
      />
    );
  });
}

const styles = StyleSheet.create({
  title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 20,
    letterSpacing: 0.7,
    textAlign: "left",
    color: "#000000",
  },
  sub_title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 15,
    textAlign: "left",
    color: Colors.gold,
    marginBottom: 5,
    textTransform: "uppercase",
  },

  box_unselected: {
    borderWidth: 2,
    borderColor: "#c4c4c4",
    borderRadius: 10,
    padding: 16,
    flex: 0.5,
    aspectRatio: 1,
    margin: 8,
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#fff",
  },
  box_selected: {
    borderWidth: 2,
    borderColor: Colors.gold,
    borderRadius: 10,
    padding: 16,
    flex: 0.5,
    aspectRatio: 1,
    margin: 8,
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#fff",
  },
  category_text_unselected: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: Sizes.selectCategoryText,
    lineHeight: Sizes.selectCategoryTextLineHeight,
    textAlign: "center",
    color: "#C4C4C4",
  },
  category_text_selected: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: Sizes.selectCategoryText,
    lineHeight: Sizes.selectCategoryTextLineHeight,
    textAlign: "center",
    color: Colors.gold,
  },
});
