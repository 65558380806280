import { default as Lottie, default as LottieView } from "lottie-react-native";
import { useRef, useState } from "react";
import {
  Image,
  Modal,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import cross from "../assets/images/cross.png";
import { CustomButton } from "../Components/CustomButton";
import { Colors } from "../Constants/Colors";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../Handlers/ErrorHandler/ErrorHandler";
import UserHandler from "../Handlers/UserHandler";

interface ModalProps {
  isVisible: any;
  setIsVisible: any;
  successAction: () => void;
}

const parseDate = (date: string) => {
  let year = date.substring(0, 4);
  let month = date.substring(5, 7);
  let day = date.substring(8, 10);

  return day + "." + month + "." + year;
};

export function PromocodeModal(props: ModalProps) {
  const { isVisible, setIsVisible, successAction } = props;
  const [isWaiting, setIsWaiting] = useState(false);
  const [code, setCode] = useState("");

  const animationRef = useRef<Lottie>(null);

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={() => {
        setIsVisible(false);
      }}
      style={{ alignItems: "center", justifyContent: "center" }}
    >
      <View style={[styles.centeredView]}>
        <KeyboardAwareScrollView
          contentContainerStyle={styles.centeredView}
          style={{ width: "100%", maxWidth: 500 }}
        >
          <View
            style={[
              styles.modalView,
              {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: Colors.gold,
                maxWidth: 500,
              },
            ]}
          >
            <View style={{ width: "100%", justifyContent: "center" }}>
              <View style={{ width: "100%", alignItems: "flex-end" }}>
                <TouchableOpacity onPress={() => setIsVisible(false)}>
                  <Image
                    source={cross}
                    style={{
                      tintColor: Colors.gray,
                      width: 22,
                      height: 22,
                    }}
                  />
                </TouchableOpacity>
              </View>
              <View style={{ flexDirection: "column", alignItems: "center" }}>
                {Platform.OS !== "web" ? (
                  <LottieView
                    ref={animationRef}
                    style={{
                      width: 140,
                      height: 140,
                      marginTop: -12,
                      marginBottom: -12,
                    }}
                    source={require("../assets/animations/present.json")}
                    autoPlay={false}
                    loop={false}
                    onAnimationFinish={() => {
                      setIsVisible(false);
                      successAction();
                    }}
                  />
                ) : null}
              </View>
              <Text
                style={{
                  color: Colors.gold,
                  fontWeight: "700",
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                Promocode einlösen
              </Text>
              <TextInput
                onChangeText={(t) => setCode(t)}
                style={[styles.container, { fontSize: 12, padding: 12 }]}
                placeholderTextColor={Colors.black_opacity}
                placeholder="CODE"
              />
              <View
                style={{
                  width: "100%",
                  alignItems: "center",
                  marginTop: 0,
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <CustomButton
                  text={"Einlösen"}
                  backgroundColor={Colors.white}
                  foregroundColor={
                    isWaiting ? Colors.black_opacity : Colors.gold
                  }
                  borderColor={isWaiting ? Colors.black_opacity : Colors.gold}
                  enabled={isWaiting}
                  action={async () => {
                    setIsWaiting(true);
                    try {
                      const response =
                        await UserHandler.getInstance().checkPromocode(code);

                      if (!response.status) {
                        setIsWaiting(false);
                        if (response.message === undefined) {
                          showErrorMessage({
                            title: "Verbindung fehlgeschlagen!",
                            text: "Die Verbindung ist fehlgeschlagen, probieren Sie es erneut. Falls der Fehler bestehen bleibt kotaktieren Sie uns unter support@medi.ceo",
                          });
                        } else {
                          showErrorMessage({
                            title: "Fehler!",
                            text: response.message,
                          });
                        }
                        return;
                      }

                      showSuccessMessage({
                        title: "Erfolgreich angewendet!",
                        text:
                          response.message +
                            "\n" +
                            UserHandler.getInstance().getCurrentUser()!.role ===
                          "doc"
                            ? "Sie können MEDICEO nun"
                            : "Du kannst MEDICEO nun" +
                              " bis zum " +
                              parseDate(response.expiry_date) +
                              " kostenlos verwenden.",
                      });

                      // TODO REMOVE ARTIFACT PAYMENT
                      UserHandler.getInstance()._isPayingUser = true;

                      if (Platform.OS !== "web") {
                        animationRef.current?.play();
                      } else {
                        setIsVisible(false);
                        successAction();
                      }
                    } catch {
                      showErrorMessage({
                        title: "Verbindung fehlgeschlagen!",
                        text: "Die Verbindung ist fehlgeschlagen, probieren Sie es erneut. Falls der Fehler bestehen bleibt kotaktieren Sie uns unter support@medi.ceo",
                      });
                    }
                  }}
                />
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  loginScreenButton: {
    marginRight: 40,
    marginLeft: 40,
    marginTop: 10,
    marginBottom: 10,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 50,
    borderWidth: 0,
  },
  loginText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 19,
    letterSpacing: 0.5,
    paddingLeft: 16,
    paddingRight: 16,
  },
  loginScreenButtonCustom: {
    marginRight: 40,
    marginLeft: 40,
    marginTop: 10,
    marginBottom: 10,
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 50,
    borderWidth: 2,
  },
  loginTextCustom: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    paddingLeft: 16,
    paddingRight: 16,
  },
  container: {
    marginTop: 30,
    marginBottom: 30,
    borderRadius: 50,
    padding: 12,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    backgroundColor: Colors.background,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 12,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    width: "80%",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  box_title: {
    fontSize: 14,
    fontWeight: "700",
    opacity: 0.5,
    marginLeft: 12,
  },
  welcomeText: {
    fontSize: 16,
    fontWeight: "700",
  },
  greetingText: {
    marginTop: 3,
    fontSize: 12,
    lineHeight: 18,
    paddingLeft: 12,
    paddingRight: 12,
    textAlign: "left",
    fontWeight: "400",
    color: "#8B8B8B",
    marginBottom: 42,
  },
  textField: {
    backgroundColor: "#fafafa",
    shadowColor: "#000",
    shadowOpacity: 0.12,
    borderRadius: 80,
    width: "85%",
    height: 50,
    padding: 12,
    elevation: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    marginBottom: 12,
  },
  box: {
    backgroundColor: "#fff",
    borderRadius: 5,
    width: 126,
    height: 43,
    borderColor: "#c4c4c433",
    borderWidth: 1,
  },
  actionText: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 12,
    fontWeight: "700",
    opacity: 0.5,
    marginBottom: 20,
    marginTop: 12,
  },
});
