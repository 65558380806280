import { useState } from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { Colors } from "../Constants/Colors";
import { Sizes } from "../Constants/Sizes";

interface DecisionTreeProps {
  text: string;
  action: () => void;
}

export function SubCategoryOption(props: DecisionTreeProps) {
  const [selected, setSelected] = useState(false);

  return (
    <View style={{ width: "100%", marginBottom: 20 }}>
      <TouchableWithoutFeedback
        style={{ width: "100%" }}
        onPress={props.action}
        onPressIn={() => setSelected(true)}
        onPressOut={() => setSelected(false)}
      >
        <View
          style={[
            styles.container,
            {
              borderColor: selected ? Colors.gold : Colors.white,
              backgroundColor: selected ? "#FFF8DA" : Colors.white,
            },
          ]}
        >
          <Text style={styles.content_text}>{props.text}</Text>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    borderWidth: 1,
    padding: 20,
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 3,
    width: "100%",
    flexDirection: "row",
    ...Platform.select({ web: { cursor: "pointer" } }),
    alignItems: "center",
    justifyContent: "space-between",
  },
  content_text: {
    color: "#000",
    opacity: 0.5,
    fontSize: Sizes.selectCategoryText,
    lineHeight: Sizes.selectCategoryTextLineHeight,
    fontWeight: "700",
    marginRight: 20,
  },
});
