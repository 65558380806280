//import * as StoreReview from "expo-store-review";
import content_kinder from "../Content/Kinder/content_kinder.json";
//import results_kinder from "../Content/Kinder/results_kinder.json"
//import results_hl from "../Content/Herz_Leber/results_hl.json"
//import results_hints from '../Content/results_hints.json'
import inline_content_hl from "../Content/Herz_Leber/inline_content_hl.json";
import inline_content_kinder from "../Content/Kinder/inline_content_kinder.json";
import { Table } from "../MainScreenComponents/ResultBoxComponents/TableComponent";
//import inline_content_d from '../Content/Diabetes/inline_content_d.json'
import { Annotation } from "../MainScreenComponents/ResultBoxComponents/DetailBox";
//import themen_kinder from '../Content/Kinder/themen_kinder.json'
//import themen_hl from '../Content/Herz_Leber/themen_hl.json'

import inline_content_old from "../Content/Alt/inline_content_old.json";
import content_d from "../Content/Diabetes/content_d.json";
import content_hl from "../Content/Herz_Leber/content_hl.json";
//import hints_content from '../Content/hints_content.json'

import content_old from "../Content/Alt/content_old.json";
//import results_old from '../Content/Alt/results_old.json'
//import results_d from '../Content/Diabetes/results_d.json'
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Alert } from "react-native";
import nodes_cardio_ekg from "../Content/nodes_cardio_ekg.json";
import nodes_cardio_gerinnung from "../Content/nodes_cardio_gerinnung.json";
import nodes_complications_general from "../Content/nodes_complications_general.json";
import nodes_complications_specific from "../Content/nodes_complications_specific.json";
import { get_tree_size } from "../HelperFunctions/GetTreeSize";
import { ResultComponent } from "../MainScreenComponents/ResultComponent";
import { HomeScreen } from "../Screens/HomeScreen";
import { PracticalHintsScreen } from "../Screens/PracticalHintsScreen";
import { AnalyticsHandler } from "./AnalyticsHandler";
import { ContentHandler } from "./ContentHandler";
import { Dispatch, SetStateAction } from "react";
import { TermsOfUseScreen } from "../Screens/MenuScreens.tsx/TermsOfUseScreen";
import UserHandler from "./UserHandler";

export interface Node {
  id: number;
  title: string;
  node_title: string;
  children: number[];
  result: string;
  inline_var?: number;
  isMultiple?: boolean;
  isCustom: boolean;
}

export interface Result {
  id: string;
  title: string;
  boxes: Box[];
  sources: "";
  filters: string[];
  nextPage: string;
}

interface InlineContent {
  pageId: string;
  id: string;
}

export interface Box {
  type: number;
  title: string;
  text: string;
  table?: Table;
  annotation?: Annotation;
  target_id?: number;
}

export default class NavigationHandler {
  topicsComplications = {
    general: {
      topics: [
        "Intraoperative Blutungen",
        "Flüssigkeits-/Volumentherapie - Hämodynamisches Monitoring",
        "Allgemeine Empfehlung zur perioperativen Körpertemperaturmessung",
        "Wärmeumverteilung während der Anästhesie",
        "ASA-Klassifikationsschema",
      ],
    },
    specific: {
      topics: [
        "Kardiovaskuläre Komplikationen",
        "Komplikationen der Atemwege",
        "Schäden durch Operationspersonal",
        "Maligne Hyperthermie",
        "Allergien",
        "Intraoperative Wachheit",
        "Shivering",
        "GI-Störungen",
      ],
    },
  };

  topicsCardio = {
    gerinnung: {
      topics: ["DAPT", "NOAC"],
    },
    ekg: {
      topics: ["EKG-Befund in 6 Schritten", "Top 6 EKG Befunde"],
    },
  };

  topicsAnaestesy = {
    diabetic: {
      topics: ["Diabetes Typ-I", "Diabetes Typ-II"],
    },
    old: {
      topics: [
        "Präoperative Maßnahmen",
        "Medikationen",
        "Berechnung Narkosemedikation",
        "Pharmakokinetik und Pharmakodynamik",
        "Flüssigkeitsersatz (präoperatives Defizit + perioperative Versorgung)",
        "Standard postoperative Schmerztherapie",
        "Notfallmedikation",
      ],
    },
    hearth_liver: {
      topics: [
        "Präoperative Maßnahmen",
        "Medikationen",
        "Berechnung Narkosemedikation",
        "Flüssigkeitsersatz (präoperatives Defizit)",
        "Notfallmedikamente",
        "Standard postoperative Schmerztherapie",
      ],
    },
    children: {
      topics: [
        "Präoperative Maßnahmen",
        "Risikofaktoren",
        "Berechnung Narkosemedikation",
        "Flüssigkeitsersatz (präoperatives Defizit)",
        "Notfallmedikation",
        "Postoperative analgetische Therapie",
        "Schnelle Hilfen",
      ],
    },
  };

  phRef: PracticalHintsScreen | undefined;
  resultRef: ResultComponent | undefined;
  isPH = false;
  currentSubject = "";
  static instance: NavigationHandler;
  currentTarget = "";
  currrentSubTarget = "";
  currentResultID = "";
  all_nodes: Node[] = [];
  all_results: Result[] = [];
  should_show_result = false;
  currentMaxSteps = 0;
  isCustomResult = false;
  customResult: string[] = [];
  inline_content: InlineContent[] = [];
  currentTopics: string[] = [];
  homeRef: HomeScreen | undefined;
  calculationVar = 0;
  firstRoute = false;
  hasMultipleNodes = false;
  nultipleNodes: Node[] = [];
  currentPHResult = "";
  currentLevel = 0;
  nextMultiplePages: string[] = [];
  bottomInset = 0;

  headerRefs: Dispatch<SetStateAction<boolean>>[] = [];

  currentFilters: string[] = [];

  hintsContent: Result[] = [];

  isFromInfo = false;

  getAccessTime() {
    return this._accessTime;
  }

  _accessTime = "";

  setLastUpdateTime() {
    const getCurrentDate = () => {
      const d = new Date();

      var date = new Date().getDate();
      var month = new Date().getMonth() + 1;
      var year = new Date().getFullYear();

      //Alert.alert(date + '-' + month + '-' + year);
      // You can turn it in to your desired format
      return (
        d.getDate() +
        "." +
        month +
        "." +
        year +
        ", " +
        getFormatted(d.getHours()) +
        ":" +
        getFormatted(d.getMinutes()) +
        ":" +
        getFormatted(d.getSeconds())
      );
    };

    const getFormatted = (n: number) => {
      return n.toLocaleString("de-DE", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    };

    this._accessTime = getCurrentDate();
    //   this.homeRef.setState({
    //     _reload: false
    //   })
    AsyncStorage.setItem("access_time", this._accessTime);
  }

  async setOldAccessTime() {
    let time = await AsyncStorage.getItem("access_time");
    if (time !== null) {
      this._accessTime = time;
    }
  }

  addToPath(p: string) {
    let newP = p.replace(new RegExp("<p>", "g"), "");
    newP = newP.replace(new RegExp("</p>", "g"), "");
    let path: string[] = this.homeRef!.state.currentPath;

    path.push(newP);
    this.homeRef!.setState({
      currentPath: path,
    });
  }

  removeLastFromPath() {
    let path = this.homeRef!.state.currentPath;

    path.pop();
    this.homeRef!.setState({
      currentPath: path,
    });
  }

  getPathFromID(id: string) {
    let returnPaths: any[] = [];
    if (this.currentSubject === "Anästhesiologie") {
      returnPaths.push(this.checkArray("Kinder", content_kinder.content, id));
      returnPaths.push(
        this.checkArray("Herz-& Nierenkranke", content_hl.content, id)
      );
      returnPaths.push(this.checkArray("Diabetes", content_d.content, id));
      returnPaths.push(
        this.checkArray("Alte & Adipöse", content_old.content, id)
      );
    } else if (this.currentSubject === "Perioperative Komplikationen") {
      returnPaths.push(
        this.checkArray("Allgemein", nodes_complications_general.content, id)
      );
      returnPaths.push(
        this.checkArray("Spezifisch", nodes_complications_specific.content, id)
      );
    } else {
      returnPaths.push(
        this.checkArray("EKG - Tool", nodes_cardio_ekg.content, id)
      );
    }

    returnPaths = returnPaths.filter((f) => f !== undefined);

    if (returnPaths.length === 0) {
      return null;
    }
    let p = returnPaths[0].toString();

    if (p === undefined) {
      return null;
    }
    p = p.replace(new RegExp("\n", "g"), " ");
    return { pathString: p, path: returnPaths[0].split(" | ") };
  }

  getNodePageToID(id: string) {
    let nps = ContentHandler.getInstance().allNodePages;
    let fabs = ContentHandler.getInstance().allFurtherActionBoxes;
    let r = "";
    fabs.forEach((f) => {
      if (f.fields.resultOrNodeId === id) r = f.sys.id;
    });

    if (r === "") return null;

    let bx = "";

    nps.forEach((n) => {
      n.fields.boxes.forEach((b) => {
        if (b.sys.id === r) bx = n.fields.title;
      });
    });

    if (bx === "") return null;

    return { title: bx };
  }

  checkArray(type: string, content: any[], id: string) {
    for (const i of content) {
      for (const key of Object.entries(i)) {
        for (const key_2 of key[1]) {
          if (key_2.result === id) {
            let path = this.recursePath(key_2, key[1], []);
            path.push(key[0]);
            path.push(type);
            path.shift();
            path = [...new Set(path)];
            let pathString = path.reverse().join(" | ");
            return pathString;
          }
        }
      }
    }

    let p = this.getNodePageToID(id);

    if (p !== null) {
      let path = [p.title];
      path.push(type);
      path.shift();
      path = [...new Set(path)];
      let pathString = path.reverse().join(" | ");
      return pathString;
    }

    return undefined;
  }

  recursePath(object: {}, c: any[], path: string[]): string[] {
    if (object.node_title?.length !== 0) {
      path.push(object.node_title);
    } else {
      path.push(object.title);
    }

    let id = object.id;
    let children = c.filter((f) => f.children.includes(id));
    if (children.length !== 0) {
      return this.recursePath(children[0], children, path);
    } else {
      return path;
    }
  }

  resetPathToSubject() {
    let path = this.homeRef!.state.currentPath;

    path = [path[0], path[1]];
    this.homeRef!.setState({
      currentPath: path,
    });
  }

  getPath() {
    let p = this.homeRef!.state.currentPath;

    p = p.slice(0, -1);
    return p;
  }

  getFullPath() {
    let p = this.homeRef!.state.currentPath;
    return p;
  }

  getCurrentTitle(title: string) {
    let p = this.homeRef!.state.currentPath;
    if (this.currentTarget !== "Alte & Adipöse") {
      return title;
    }
    if (this.isCustomResult) {
      return title;
    }
    if (title.includes("GFR") || title.includes("Dialyse")) {
      return title;
    }
    return p[p.length - 1];
  }

  getCurrentTitleForced() {
    let p = this.homeRef!.state.currentPath;
    return p[p.length - 1];
  }

  getJsonFromServer = async (url: string) => {
    var header = new Headers();
    header.append("pragma", "no-cache");
    header.append("cache-control", "no-cache");

    var init = {
      method: "GET",
      headers: header,
    };

    var req = new Request(url);

    return fetch(req, init)
      .then((response) => response.json())
      .then((json) => {
        return json;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getCurrentNodes(id: number | undefined): Node[] {
    if (id === undefined) return [];
    const node = this.getNodeToID(id);
    if (node === undefined) {
      return [];
    }
    let ra: Node[] = [];
    if (node.children != undefined) {
      for (let child of node.children) {
        ra.push(this.getNodeToID(child));
      }
      if (ra !== undefined) {
        return ra;
      }
    } else {
      this.should_show_result = true;
      return [];
    }
    return [];
  }

  getCurrentTargetRef() {
    if (this.isPH) {
      return this.phRef;
    }
    return this.homeRef;
  }

  getResult(isPH: boolean) {
    var result: Result;
    if (isPH) {
      return this.all_results.filter(
        (res) => res.id == this.currentPHResult
      )[0];
    }
    if (this.isCustomResult) {
      //this.customResult = [...new Set(this.customResult)];
      result = {
        id: "CUSTOM",
        title: "Eregbnis der Auswahl",
        boxes: [],
        sources: "",
      };
      let res = this.getPagesFromMulitpleFilters();
      for (const r of res) {
        const b = this.getBoxesToInlineResult(r);

        result.boxes = result.boxes.concat(b.boxes);
        if (!result.sources.includes(b.sources))
          result.sources = result.sources + "<br>" + b.sources;
      }

      return result;
    } else {
      if (!isPH) {
        return this.all_results.filter(
          (res) => res.id == this.currentResultID
        )[0];
      }
    }
  }

  navigateToNextPage(resId: string | undefined) {
    if (this.nextMultiplePages.length !== 0) {
      resId = this.nextMultiplePages[0];
      this.nextMultiplePages.shift();
    }
    console.log("filters", this.currentFilters);
    console.log("resId 1", resId);
    if (resId === undefined) {
      resId = this.getPageFromFilters();
      if (resId === undefined) resId = "final_multiple";
    }

    if (resId === "final_selection") {
      resId = this.getPageFromFilters();
      if (resId === undefined) resId = "final_multiple";
    }

    console.log("resId 2", resId);
    if (resId === "final_multiple") {
      this.isCustomResult = true;
      ContentHandler.getInstance()._isResult = true;
    }

    let prevRes = this.getCurrentTargetRef()!.state.previousResults;
    if (prevRes === undefined) prevRes = [];
    prevRes.push(this.isPH ? this.currentPHResult : this.getCurrentResultID());

    console.log("prev res", prevRes);

    if (this.isPH) {
      this.setCurrentPHResult(resId);
    } else {
      this.setCurrentResultID(resId);
    }

    this.getCurrentTargetRef()!.setState({
      resID: this.getCurrentTargetRef()!.state.resID + 1,
      currentLevel: this.getCurrentTargetRef()!.state.currentLevel + 1,
      previousResults: prevRes,
    });
  }

  getPageFromFilters() {
    const filters = this.currentFilters;
    const ar = ContentHandler.getInstance().allResultPages;

    let res = ar.filter(
      (r) => r.fields.filters?.sort().toString() === filters.sort().toString()
    );

    console.log("res", res);
    if (res.length === 0) {
      return undefined;
    }

    return res[0].sys.id;
  }

  getPagesFromMulitpleFilters(): string[] {
    const filters = this.currentFilters;
    const ar = ContentHandler.getInstance().allResultPages;
    let ra = [];

    for (const filter of filters) {
      let res = ar.filter((r) => r.fields.filters?.includes(filter));
      if (res.length !== 0) {
        ra.push(res[0].sys.id);
      }
    }

    if (ra.length === 0) ra.push("1SBoxs91T7geDwls2R8jFs");

    return ra;
  }

  async getResultBoxes(isPH: boolean) {
    ContentHandler.getInstance()._currentUnit = "";
    if (this.isCustomResult) {
      this.customResult = [...new Set(this.customResult)];
      var empty: Element[] = [];
      var res = {
        pageId: "CUSTOM",
        title: "Ergebnis der Auswahl",
        boxes: empty,
        sources: "",
        nextPage: "",
        filers: "",
        filters: [],
      };
      this.setCurrentResultID("CUSTOM");
      let results = this.getPagesFromMulitpleFilters();
      for (const r of results) {
        //let result = await ContentHandler.getInstance().request(r);
        const b = await ContentHandler.getInstance().request(r);

        res.boxes = res.boxes.concat(b.boxes);
        if (!res.sources.includes(b.sources))
          res.sources = res.sources + "<br>" + b.sources;
      }
    } else {
      if (isPH) {
        var res = await ContentHandler.getInstance().request(
          this.currentPHResult
        );
      } else {
        var res = await ContentHandler.getInstance().request(
          this.currentResultID
        );
      }
    }

    return {
      pageId: res.pageId,
      title: res.title,
      boxes: res.boxes,
      sources: res.sources,
      id: isPH ? this.currentPHResult : this.currentResultID,
      nextPage: res.nextPage,
      filters: res.filters,
    };
  }

  getNodeToID(id: number) {
    return this.all_nodes.filter((node) => node.id == id)[0];
  }

  async getBoxesToInlineResult(res: string) {
    const m = this.inline_content.filter((t) => t.id == res);
    if (m !== undefined) {
      if (m[0] !== undefined) {
        const pageId = m[0].pageId;
        if (pageId !== "") {
          const res = await ContentHandler.getInstance().request(m[0].pageId);

          return res;
        } else {
          return [];
        }
      } else {
      }
    }
  }
  /**
   * @returns {NavigationHandler}
   */
  static getInstance() {
    if (NavigationHandler.instance == null) {
      NavigationHandler.instance = new NavigationHandler();
    }

    return this.instance;
  }

  private getSteps() {
    return get_tree_size(this.content, this.currrentSubTarget);
  }

  private mapChildren(c_count: number, parentNode: Node) {
    let rC = c_count;
    parentNode.children.map((child) => {
      rC += this.mapChildren(rC, this.getNodeToID(child));
    });
    return rC;
  }

  content = {};

  openPracticalHintsResult(id: string) {
    this.currentPHResult = id;
  }

  setCurrentTarget(ct: string) {
    AnalyticsHandler.getInstance().logSelectCategory(ct);
    this.addToPath(ct);
    if (this.currentSubject === "Anästhesiologie") {
      if (ct === "Kinder") {
        this.currentTopics = this.topicsAnaestesy.children.topics;
        this.content = content_kinder;
        this.inline_content = inline_content_kinder.content;
      } else if (ct === "Herz- & Nierenkranke") {
        this.currentTopics = this.topicsAnaestesy.hearth_liver.topics;
        this.content = content_hl;
        this.inline_content = inline_content_hl.content;
      } else if (ct === "Diabetiker") {
        this.currentTopics = this.topicsAnaestesy.diabetic.topics;
        this.content = content_d;
        //this.inline_content = this.inline_content_d.content;
      } else {
        this.currentTopics = this.topicsAnaestesy.old.topics;
        this.content = content_old;
        this.inline_content = inline_content_old.content;
      }
    } else if (this.currentSubject === "Perioperative Komplikationen") {
      if (ct === "Allgemein") {
        this.currentTopics = this.topicsComplications.general.topics;
        this.content = nodes_complications_general;
      } else {
        this.currentTopics = this.topicsComplications.specific.topics;
        this.content = nodes_complications_specific;
      }
    } else {
      if (ct === "EKG - Tool") {
        this.currentTopics = this.topicsCardio.ekg.topics;
        this.content = nodes_cardio_ekg;
      } else {
        this.currentTopics = this.topicsCardio.gerinnung.topics;
        this.content = nodes_cardio_gerinnung;
      }
    }
    this.currentTarget = ct;
  }

  setFirstPracticalHintID() {
    var res = "";

    if (NavigationHandler.getInstance().currentSubject === "Anästhesiologie") {
      res = "1yhp0ywYMnJuVkIBIEOZFb";
    } else if (
      NavigationHandler.getInstance().currentSubject ===
      "Perioperative Komplikationen"
    ) {
      res = "5wfoggcdA80IKHXnWthYwT";
    } else if (
      NavigationHandler.getInstance().currentSubject === "Kardiologie"
    ) {
      res = "5wfoggcdA80IKHXnWthYwT"; //"2HHiHbACVSttxC9jIEyFPL"
    }

    this.currentPHResult = res;
  }

  getCurrentTarget() {
    return this.currentTarget;
  }

  setCurrentSubTarget(ct: string) {
    AnalyticsHandler.getInstance().logDecisionTreeComponent(ct);
    this.isCustomResult = false;
    this.all_nodes = this.content.content[0][ct];
    if (this.all_nodes[0].isCustom) {
      this.isCustomResult = true;
    }
    if (this.all_nodes[0].result != undefined) {
      this.currentResultID = this.all_nodes[0].result;
      this.setShouldShowResult(true);
    }
    this.currrentSubTarget = ct;
  }

  getCurrentSubTarget() {
    if (this.all_nodes[0].isCustom) {
      this.isCustomResult = true;
    }

    return this.currrentSubTarget;
  }

  shouldShowResult() {
    return this.should_show_result;
  }

  setShouldShowResult(s: boolean) {
    this.should_show_result = s;
  }

  setCurrentResultID(id: string) {
    this.currentResultID = id;
  }

  setCurrentPHResult(id: string) {
    this.currentPHResult = id;
  }

  getCurrentResultID() {
    return this.currentResultID;
  }

  getCurrentMaxSteps() {
    return this.getSteps();
  }

  reset() {
    this.currentFilters = [];
    this.nextMultiplePages = [];
    if (this.shouldShowResult()) {
      if (
        this.openedResultPages === 10 ||
        this.openedResultPages === 17 ||
        this.openedResultPages % 20 === 0
      ) {
        (async () => {
          if (
            UserHandler.getInstance().getCurrentUser().has_rated !== undefined
          ) {
            if (UserHandler.getInstance().getCurrentUser().has_rated) return;
          }
          // if (await StoreReview.hasAction()) {
          //   Alert.alert(
          //     "MEDICEO bewerten",
          //     "\nWie würden Sie MEDICEO bewerten?\n",
          //     [
          //       {
          //         text: "1 bis 3 Sterne",
          //         onPress: () => console.log("Cancel Pressed"),
          //       },
          //       {
          //         text: "4 oder 5 Sterne",
          //         onPress: () => {
          //           StoreReview.requestReview();
          //           UserHandler.getInstance().getCurrentUser().has_rated = true;
          //           UserHandler.getInstance().update();
          //           UserHandler.getInstance().updateOfflineUser();
          //         },
          //         style: "cancel",
          //       },
          //     ]
          //   );
          // }
        })();
      }
    }
    if (this.all_nodes[0] === undefined) {
      this.setShouldShowResult(false);
    } else if (this.all_nodes[0].result === undefined) {
      this.setShouldShowResult(false);
    } else {
      this.setCurrentResultID(this.all_nodes[0].result);
    }

    this.calculationVar = 0;
    this.isCustomResult = false;
    this.customResult = [];
  }

  openedResultPages = 0;

  async getResPages() {
    let p = await AsyncStorage.getItem("openedPages");
    if (p !== null && p !== undefined) {
      this.openedResultPages = parseInt(p);
    }

    return await ContentHandler.getInstance().loadAllData();
  }

  setResPages() {
    this.openedResultPages = this.openedResultPages + 1;
    AsyncStorage.setItem("openedPages", this.openedResultPages + "");
  }
}
